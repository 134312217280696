import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Tabs } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'

import { getPurchasedAddons } from '../../graphql/custom-queries'
import { track } from '../../services/analytics/analytics'
import { roleAdmin } from '../../store/auth-user-slice'
import { notificationStore } from '../../context/notificationsContext/store'

import { getAddonsEvents } from '../Add-ons/common/addons'

import CircularProgress from '../../components/circular-progress'
import AddonFeedback from '../../components/addon-feedback'
import MyAddons from '../../components/my-addons'
import ChoiceAddons from '../../components/choice-addons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'

import { IGetPurchasedAddonsData } from '../../types/custom-queries'
import { IData } from '../../types/data'
import { AddonsStatusEnum, IAddon } from '@vacationtracker/shared/types/addons'
import { AddOnsActiveTabEnum } from '@vacationtracker/shared/types/automations'
import { ProductService } from '@vacationtracker/shared/data/stripe/product-service'
import { ProductName } from '@vacationtracker/shared/data/stripe/prices'

const { TabPane } = Tabs

const AddonsPage = () => {
  const { formatMessage } = useIntl()
  const amIAdmin = useSelector(roleAdmin)
  const { actionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [subscribedAddons, setSubscribedAddons] = useState<IAddon[]>([])
  const [activeTab, setActiveTab] = useState<AddOnsActiveTabEnum>(AddOnsActiveTabEnum.myAddons)
  const [userCount, setUserCount] = useState(0)

  const env = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test'
  const productService = new ProductService(env)
  const addonsPrice = productService.getPriceUnitByProductName(ProductName.ADDONS)

  useEffect(() => {
    if (isLoading) {
      track('AUTOMATIONS_ACTIVE_VIEWED', {})
    }
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [actionNotifications])

  const fetchData = async () => {
    const response = await API.graphql(graphqlOperation(getPurchasedAddons)) as IData<IGetPurchasedAddonsData>

    const subscribedAddons = [
      ...(response.data?.getPurchasedAddons?.addons || []),
      ...(response.data?.getPurchasedAddons?.automations || []),
      ...(response.data?.getPurchasedAddons?.integrations || []),
    ]
    setSubscribedAddons(subscribedAddons as IAddon[])

    setUserCount(response.data.getUserCount.usersActive)
    setIsLoading(false)
  }

  useEffect(() => {
    if (subscribedAddons.length === 0 && !isLoading) {
      setActiveTab(AddOnsActiveTabEnum.discoveryMoreAddons)
    }
  }, [subscribedAddons, isLoading])

  const addonsEvent = getAddonsEvents(formatMessage)

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <div className='main-content'>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <IntlMessages id="app.addOns" />
            </div>
          </div>
          <div className="main-content-body">
            <div>
              <Tabs
                tabBarExtraContent={<AddonFeedback buttonStyle={{ float: 'right' }} page="automation-page-list" />}
                activeKey={activeTab}
                onTabClick={(key) => setActiveTab(key as AddOnsActiveTabEnum)}
              >
                <TabPane tab={<IntlMessages id="automations.myAddons" />} key={AddOnsActiveTabEnum.myAddons}>
                  <MyAddons
                    subscribedAddons={subscribedAddons} 
                    addonsEvent={addonsEvent}
                    changeTab={(tab: AddOnsActiveTabEnum) => {
                      setActiveTab(tab)
                    }}
                    addonsPrice={addonsPrice}
                  />
                </TabPane>
                <TabPane tab={<IntlMessages id="automations.discoveryMoreAddons" />} key={AddOnsActiveTabEnum.discoveryMoreAddons}>
                  <ChoiceAddons
                    addonsEvent={addonsEvent}
                    subscribedAddons={subscribedAddons.filter((addon) => addon.status !== AddonsStatusEnum.CANCELED)}
                    formatMessage={formatMessage}
                    amIAdmin={amIAdmin}
                    userCount={userCount}
                    addonsPrice={addonsPrice}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AddonsPage
