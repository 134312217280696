import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, Divider, Form, Input, List, Radio, Typography, Spin, Select, Tooltip } from 'antd'
import { DeleteOutlined, WarningOutlined, SearchOutlined , BulbOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { find } from 'lodash'

import useDebounce from '../../../../util/use-debounce'
import { useAppSelector } from '../../../../store/hooks'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../../../store/auth-user-slice'
import { getIntegrationPlatformName } from '@vacationtracker/shared/functions/get-integration-platform-name'
import { track } from '../../../../services/analytics/analytics'

import { ImportUsersNavigation } from '../components'
import IntlMessages from '../../../../util/IntlMessages'

import { IAutocompleteOption, IImportUsersPageFormProps, ImportUsersStepEnum } from '../types'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

const { Paragraph, Text } = Typography


const ImportUsersPageForm = ({
  totalNumberOfUsers,
  showAdminConsentWarning,
  isMsAdminConsentRequired,
  adminConsentUrl,
  platform,
  selectedUsers,
  setSelectedUsers,
  onSearchUsers,
  setSteps,
  activeStep,
  importAllUsers,
  onSetImportAllUsers,
  noOfUsersToImport,
  notImportedUsers,
  onSetImportUsersAutomatically,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  totalNumberOfUsersAfterImport,
  shouldEnableImportAll,
  shouldImportUsersAutomatically,
  submitBilling,
  numberOfActiveUsers,
}: IImportUsersPageFormProps): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)

  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [autocompleteUsers, setAutocompleteUsers] = useState<IAutocompleteOption[]>([])
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [notUsersFound, setNotUsersFound] = useState(false)
  const [listOfUsersForSearch, setListOfUsersForSearch] = useState(notImportedUsers)

  const defaultValue = {
    newUsers: [],
    importAllUsers,
    importUsersAutomatically: typeof authCompany?.importUsersAutomatically === 'boolean' ? authCompany.importUsersAutomatically : true,
  }

  const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  }

  const debouncedSearchTerm = useDebounce(autocompleteValue, 500)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingUsers(true)
      const autocompleteUsersResponse = await onSearchUsers(debouncedSearchTerm)
      setAutocompleteUsers(autocompleteUsersResponse)
    }

    fetchData()
  }, [debouncedSearchTerm])

  useEffect(() => {
    setListOfUsersForSearch(notImportedUsers.filter(u => {
      const user = JSON.parse(u.value as string)
      const foundUser = find(selectedUsers, (selectedUser) => selectedUser.id === user.id)
      if (!foundUser) {
        return user
      }
    }))

  }, [notImportedUsers])

  useEffect(() => {
    setLoadingUsers(false)
    if (autocompleteUsers.length === 0 && autocompleteValue !== '') {
      setNotUsersFound(true)
    }
  }, [autocompleteUsers])

  useEffect(() => {
    if (autocompleteValue === '') {
      setNotUsersFound(false)
    }
  }, [autocompleteValue])

  useEffect(() => {
    handleImportUsersAutomatically(shouldImportUsersAutomatically)
  }, [shouldImportUsersAutomatically])

  const radioOptions = [{
    value: true, label: formatMessage({ id: 'app.yes' }),
  }, {
    value: false, label: formatMessage({ id: 'app.no' }),
  }]

  const onAllUsersSelect = (option) => {
    setListOfUsersForSearch(notImportedUsers.filter(u => {
      const user = JSON.parse(u.value as string)
      const foundUser = find(selectedUsers, (selectedUser) => selectedUser.id === user.id)
      if (!foundUser) {
        return user
      }
    }))
    onSetImportAllUsers(option.target.value as boolean)
    form.setFieldsValue({
      importAllUsers: option.target.value,
    })
    track('IMPORT_USERS_TOGGLE_IMPORT_ALL_USERS', {
      platform,
      selectedValue: option.target.value,
      totalNumberOfUsers,
    })
  }

  const onImportUsersAutomatically = (option) => {
    onSetImportUsersAutomatically(option.target.value as boolean)
    handleImportUsersAutomatically(option.target.value)
  }

  const handleImportUsersAutomatically = (importUsersAutomatically) => {
    form.setFieldsValue({ importUsersAutomatically })
    track('IMPORT_USERS_AUTOMATICALLY', {
      platform,
      selectedValue: importUsersAutomatically,
      source: 'import page',
    })
  }

  const onSelectUser = (value: string) => {

    const parsedUser = JSON.parse(value)

    if (platform === 'slack' || platform === 'google') {
      setListOfUsersForSearch((listOfUsersForSearch) => {
        return listOfUsersForSearch.filter(u => {
          return JSON.parse(u.value).name !== parsedUser.name
        })
      })
    }

    setSelectedUsers([ ...selectedUsers, parsedUser ])
    setAutocompleteValue('')
    setAutocompleteUsers([])
  }

  const onRemoveSelectedUser = (id: string) => {
    setSelectedUsers(selectedUsers.filter(user => user.id !== id))
  }

  return <Form
    key="importUsersForm"
    form={form}
    name="importUsersForm"
    initialValues={defaultValue}
    colon={false}
    labelWrap
  >
    <Form.Item
      {...formTailLayout}
      className='wrap-label'
      extra={!shouldEnableImportAll &&
        <IntlMessages id="components.importUsersForm.importAllUsersExceedingLimt" />
      }
      label={<>
        <IntlMessages id="components.importUsersForm.importAllUsers" />
        {shouldEnableImportAll &&
          <Tooltip
            className='info-tooltip'
            title={<IntlMessages
              id="components.importUsersForm.importAllUsersTooltip"
              values={{
                noOfUsersToImport,
                platform: getIntegrationPlatformName(authCompany?.platform as Platform),
              }}
            />}>
            <InfoCircleOutlined />
          </Tooltip>
        }
      </>}
    >
      <Radio.Group
        optionType="button"
        value={importAllUsers}
        onChange={onAllUsersSelect}
      >
        {
          radioOptions.map(option => (
            <Radio.Button
              disabled={!shouldEnableImportAll}
              value={option.value}
              key={option.label}
              data-testid={option.label.toLowerCase()}
            >{option.label}
            </Radio.Button>
          ))
        }
      </Radio.Group>
    </Form.Item>
    { !importAllUsers &&
        <Form.Item {...formTailLayout}
          className='wrap-label'
          name="newUsers"
          label={<IntlMessages id="components.importUsersForm.selectUsers" />}
        >
          { platform === 'microsoft' &&
            <>
              { /* For some reason, the AutoComplete value is not cleared if we do not have the "autocompleteValue" in the DOM 🤷‍♂️ */ }
              <div style={{ display: 'none' }}>{autocompleteValue}</div>
              <AutoComplete
                options={autocompleteUsers}
                // onSearch={onSearch}
                onSelect={onSelectUser}
                onChange={(text: string) => setAutocompleteValue(text)}
                value={autocompleteValue}
                notFoundContent={(notUsersFound && !loadingUsers) ? <IntlMessages id="importUsers.form.searchUsersNotFound" /> : ''}
              >
                <Input
                  placeholder={formatMessage({ id: 'importUsers.form.searchMicrosoftUsersPlaceholder' })}
                  suffix={loadingUsers ? <Spin size="small" /> : <SearchOutlined />}
                />
              </AutoComplete>
            </>
          }
          {platform == 'slack' &&
            <>
              <Select
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="importUsers.form.searchSlackUsersPlaceholder" />}
                onSelect={onSelectUser}
                onChange={(text: string) => setAutocompleteValue(text)}
                value={autocompleteValue}
                showSearch
              >
                {listOfUsersForSearch
                  .sort((userA, userB) => JSON.parse(userA.value).realName.toLowerCase() < JSON.parse(userB.value).realName.toLowerCase() ? -1 : 1)
                  .map((user) => (
                    <Select.Option value={user.value} key={JSON.parse(user.value).id}>{JSON.parse(user.value).realName}</Select.Option>
                  ))
                }
              </Select>
            </>
          }
          {platform == 'google' &&
            <>
              <Select
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="importUsers.form.searchSlackUsersPlaceholder" />}
                onSelect={onSelectUser}
                onChange={(text: string) => setAutocompleteValue(text)}
                value={autocompleteValue}
                showSearch
              >
                {listOfUsersForSearch
                  .sort((userA, userB) => JSON.parse(userA.value).realName.toLowerCase() < JSON.parse(userB.value).realName.toLowerCase() ? -1 : 1)
                  .map((user) => (
                    <Select.Option value={user.value} key={JSON.parse(user.value).id}>{JSON.parse(user.value).realName}</Select.Option>
                  ))
                }
              </Select>
            </>
          }
        </Form.Item>
    }
    { importAllUsers &&
      <Paragraph type='warning'>
        <BulbOutlined /> {
          formatMessage({ id: 'components.importUsersForm.warningAllUsersImport' }, {
            platform: getIntegrationPlatformName(authCompany?.platform as Platform),
            // link: (...chunks) => <a href='/'>{chunks}</a>, //TODO: need a link here
          })
        }</Paragraph>
    }
    { importAllUsers &&
      <Form.Item
        {...formTailLayout}
        className='wrap-label'
        name="importUsersAutomatically"
        label={<>
          <IntlMessages
            id="components.importUsersForm.button.importUsersAutmaticallyFromPlatform" values={{ platform: getIntegrationPlatformName(authCompany?.platform as Platform) }}
          />
          <Tooltip className='info-tooltip'
            title={<IntlMessages
              id="components.importUsersForm.importUsersAutomaticallyTooltip"
              values={{ platform: getIntegrationPlatformName(authCompany?.platform as Platform) }}
            />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}
      >
        <Radio.Group
          optionType="button"
          onChange={onImportUsersAutomatically}
        >
          {radioOptions.map(option => <Radio.Button value={option.value} key={option.label} data-testid={option.label.toLowerCase()}>{option.label}</Radio.Button>)}
        </Radio.Group>
      </Form.Item>
    }
    {
      platform === 'microsoft' &&  importAllUsers && isMsAdminConsentRequired && adminConsentUrl &&
      <>
        <Paragraph>
          <IntlMessages id="components.importUsersForm.consent.line1" />
        </Paragraph>
        <Paragraph copyable={{ text: adminConsentUrl }}>
          <a href={adminConsentUrl}>{adminConsentUrl}</a>
        </Paragraph>
        <Paragraph>
          <IntlMessages id="components.importUsersForm.consent.line2" />
        </Paragraph>
      </>
    }
    { showAdminConsentWarning &&
      <Text type="danger">
        <WarningOutlined /> <IntlMessages id="components.importUsersForm.consent.warning" />
      </Text>
    }
    { selectedUsers.length === 0 &&
      <>
        {!importAllUsers && <Divider plain>
          <IntlMessages id="importUsers.form.selectedUsersLabel" values={{ selectedUsers: selectedUsers.length }} />
        </Divider>}
        <div style={{ height: '10vh' }}></div>
        <Divider style={{ marginBottom: 24 }} />
      </>
    }
    { selectedUsers.length > 0 &&
      <>
        <Divider plain><IntlMessages id="importUsers.form.selectedUsersLabel" values={{ selectedUsers: selectedUsers.length }} /></Divider>
        <List
          size='small'
          dataSource={selectedUsers}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={platform === 'slack' ? item.realName : item.name}
                description={item.mail}
              />
              <div>
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onRemoveSelectedUser(item.id)
                    const platforms = ['google', 'slack']
                    platforms.includes(platform) && setListOfUsersForSearch(users => [
                      ...users,
                      {
                        label: `${item.realName} (${item.name})`,
                        value: JSON.stringify({
                          id: item.id,
                          name: item.name,
                          realName: item.realName,
                        }),
                      },
                    ])
                  }} danger />
              </div>
            </List.Item>
          )}
        />
        <Divider style={{ marginBottom: 24 }} />
      </>
    }
    <ImportUsersNavigation
      platform={platform}
      importAllUsers={importAllUsers}
      noOfUsersToImport={noOfUsersToImport}
      totalNumberOfUsersAfterImport={totalNumberOfUsersAfterImport}
      submitInProgress={false}
      activeStep={activeStep}
      upcomingInvoiceInfo={upcomingInvoiceInfo}
      isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
      isAnnualBilling={authCompany?.billing?.subscriptionPeriod === 'annual'}
      locale={authUser?.locale as LocaleEnum}
      showAdditionalBillingInfo={false}
      handleNext={() => {!showAdminConsentWarning && setSteps(ImportUsersStepEnum.configure, 1)}}
      nextButtonTitle={formatMessage({ id: 'components.importUsersForm.selectUsersNext' })}
      submitBilling={submitBilling}
      numberOfActiveUsers={numberOfActiveUsers}
      isTrial={authCompany?.subscriptionStatus === 'trialing' && !authCompany?.billing?.paymentMethodAdded}
      stripeCurrentPeriodEnd={authCompany?.billing?.stripeCurrentPeriodEnd as string}
    />
  </Form>
}

export default ImportUsersPageForm