import React from 'react'
import { useIntl } from 'react-intl'
import { Col, Form, FormInstance, Input, notification, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import SetPassword from '../set-password'

import IntlMessages from '../../util/IntlMessages'
import { RcFile } from 'antd/lib/upload'

export const UserSignUpForm = ({
  addAvatar = false,
  form,
  col = {
    xxl: { span: 14, offset: 5 },
    xl: { span: 16, offset: 4 },
    lg: { span: 20, offset: 2 },
    md: { span: 22, offset: 1 },
    xs: { span: 24, offset: 0},
  },
}: {
  addAvatar: boolean
  form: FormInstance<any>
  col: any
}) => {
  const { formatMessage } = useIntl()


  const beforePictureUpload = (file: RcFile) => {
    notification.close('INVALID_FILE_TYPE')
    const allowedFileFormats = ['image/png', 'image/jpeg', 'image/gif']
    if (!allowedFileFormats.includes(file.type)) {
      notification.error({
        message: formatMessage({ id: 'error.invalidFileType' }),
        key: 'INVALID_FILE_TYPE',
        description: formatMessage({ id: 'error.invalidFileType.description' }),
        duration: 0,
      })
      return
    }
    form.setFieldsValue({ profilePicture: file})

    return false
  }

  const handlePreview = (file) => {
    const newTab = window.open()
    newTab?.document.write(`<img src="${file.thumbUrl}" alt="preview" />`)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  return <>
    <Col xxl={col.xxl} xl={col.xl} lg={col.lg} md={col.md} xs={col.xs}>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="app.firstAndLastName" />}
        name="userName"
        rules={[{
          required: true,
          message: <IntlMessages id="error.fieldIsRequired" />,
        }]}
      >
        <Input type="text" data-qa='first-and-last-name-input' />
      </Form.Item>
      <SetPassword />
    </Col>
    {addAvatar && <Col xxl={col.xxl} xl={col.xl} lg={col.lg} md={col.md} xs={col.xs}>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="app.profilePicture" />}
        name="profilePicture"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <ImgCrop rotationSlider>
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={beforePictureUpload}
            onPreview={handlePreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}><IntlMessages id="app.upload" /></div>
            </div>

          </Upload>
        </ImgCrop>
      </Form.Item>
    </Col>
    }
  </>
}