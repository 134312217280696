import React, { useEffect, useState } from 'react'
import { List, Input, notification, Button } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import { API, graphqlOperation } from 'aws-amplify'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'

import { getAiAssistantConversationInfoData } from '../../graphql/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectFeatureFlagsSlice } from '../../store/feature-flags-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'


import { IConversation } from '@vacationtracker/shared/types/conversation'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FrontendUrls } from '../../types/urls'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IData } from '../../types/data'


type IConverssationInfo = Pick<IConversation, 'timestamp' | 'topic' | 'id' | 'isDone' | 'updatedAt'>


const AiAssistedOnboarding = ({ history }) => {
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const [ topics, setTopics ] = useState<IConverssationInfo[] | null>(null)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const shouldEnableAIAssistedOnboarding = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.aiAssistedOnboarding, true)
  const { isLoadingFeatureFlags, featureFlags } = useAppSelector(selectFeatureFlagsSlice)


  useEffect(() => {
    if (!isLoadingFeatureFlags && authUser?.initialUser && featureFlags.includes(FeatureFlagEnum.aiAssistedOnboarding)) {
      fetchData()
    } else {
      history.push(FrontendUrls.dashboard)
    }
  }, [authCompany?.trialPeriod,  authUser?.initialUser, isLoadingFeatureFlags, featureFlags])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const conversationResponse = await API.graphql(
        graphqlOperation(getAiAssistantConversationInfoData, { companyId: authCompany?.id })
      )  as IData<{ getAiAssistedConversationInfo: { topic: string; timestamp: string; id: string }[]}>
      const conversationData = conversationResponse.data?.getAiAssistedConversationInfo
      setTopics(conversationData)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching messages', error)
      Sentry.captureException(JSON.stringify(error))
    } finally {
      setIsLoading(false)
    }
  }

  const sendTopic = async () => {
    if (!input || input.trim() === '' || input.length < 1) {
      return
    }
    try {
      // TODO: THIS IS FOR THE FIRST VERSION, LATER WE SHOULD ADD NEW API ENDPOINT, ONCE WE HAVE MORE CONVERSATIONS WITH AI
      const response = await API.post('CoreEvent', '/core/ai-assisted-onboarding', {
        body: {
          isInitial: false,
          userLang: authUser?.locale,
          message: 'bla bla bla',
        },
      })
      setInput('')
    } catch (error) {
      Sentry.captureException(JSON.stringify(error))
      const errorDescription = error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'ai.assisted.onboarding.sendMessageError' }),
        description: errorDescription,
        duration: 0,
      })
      setInput('')
    }
  }

  const handleUserInput = (e) => {
    setInput(e.target.value)
  }

  const getHelloMessage = () => {
    const getHours = new Date().getHours()
    let message = ''
    if (getHours < 12) {
      message = `${formatMessage({id: 'app.goodMorning'})}, ${authUser?.name}`
    } else if (getHours < 18) {
      message = `${formatMessage({id: 'app.goodAfternoon'})}, ${authUser?.name}`
    } else {
      message = `${formatMessage({id: 'app.goodEvening'})}, ${authUser?.name}`
    }
    return <h1>{message}</h1>
  }

  function handleOnOnboardingTopicClick(id: string) {
    history.push(`${FrontendUrls.aiAssistant}/${id}`)
  }

  return (
    <div className="main-content vt-callendar-sync-page">
      <div className="main-content-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
        {getHelloMessage()}
      </div>
      <div className="main-content-body">
        {shouldEnableAIAssistedOnboarding &&
          <Input
            maxLength={1000}
            placeholder={formatMessage({id: 'ai.assistant.defaultMessage'})}
            value={input}
            onChange={handleUserInput}
            onPressEnter={sendTopic}
            disabled // TODO: THIS IS FOR THE FIRST VERSION, LATER WE"LL ENABLE MORE CONVERSATIONS WIHR AI
            addonAfter={<Button
              icon={<SendOutlined />}
              // disabled={isLoading || !input || input?.trim() === '' || input?.length < 1}
              disabled // TODO: THIS IS FOR THE FIRST VERSION, LATER WE"LL ENABLE MORE CONVERSATIONS WIHR AI
              onClick={sendTopic}
            />}
            size='large'
            style={{ fontFamily: 'monospace' }}
          />}
        <br />
        <br />
        <br />
        {shouldEnableAIAssistedOnboarding && <List
          size='large'
          loading={isLoading}
          bordered
          style={{
            width: '90%',
            margin: 'auto',
          }}
          dataSource={topics as IConverssationInfo[] || []}
          renderItem={item => {
            if (item.topic === 'Onboarding') {
              return <List.Item
                onClick={() => handleOnOnboardingTopicClick(item.id)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: item.isDone ? '#e9fbe0' : '#ffd1d2',
                }}>
                <pre style={{whiteSpace: 'pre-wrap', color: '#545454', fontSize: '18px'}}>
                  {item.topic} - {item.isDone ? formatMessage({ id: 'app.done' }) : formatMessage({ id: 'app.notDone' })}
                </pre>
                <pre style={{whiteSpace: 'pre-wrap', color: '#545454', fontSize: '18px'}}>
                  {format(new Date(item.timestamp as string), 'MMMM d, HH:mm')}
                </pre>
              </List.Item>
            }
          }}
        />}
      </div>
    </div>
  )
}

export default AiAssistedOnboarding