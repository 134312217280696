import React, { ReactElement, useEffect, useState } from 'react'
import { Card, Drawer, Typography } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { useIntl } from 'react-intl'

import { getAiAssistedOnboardingSetupCoreEventsData, getLeaveTypesListAndLocations } from '../../../graphql/custom-queries'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { useAppSelector } from '../../../store/hooks'
import { generateEventMessage, matchEventsToEntities, translateEventGroup, getLeaveTypeAndLocationNames } from './utils'

import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import EmptyData from '../../../components/empty-data'

import { IData } from '../../../types/data'
import { AIAssistedOnboardingCoreEventStatusEnum, IAIAssistedOnboardingSetupItem, IBasicEntitiesAIAssistentResponse } from '@vacationtracker/shared/types/ai-assisted-onboarding'
import { IGetLeaveTypesListAndLocationsData } from '../../../types/leave-types'
import { IGetLeaveTypeList, IGetLocationsShort } from '../../../types/locations'


interface IAiAssistedOnboardingSetupCoreEventsData {
  getAiAssistedOnboardingSetupCoreEvents: IAIAssistedOnboardingSetupItem[]
}

interface IOnboardingDoneNotifications {
  visible: boolean
  handleIsOnboardingNotificationVisible: () => void
}

export interface IMatchedEventsToEntities {
  locations: IAIAssistedOnboardingSetupItem[]
  leaveTypes: IAIAssistedOnboardingSetupItem[]
  departments: IAIAssistedOnboardingSetupItem[]
  leavePolicies: IAIAssistedOnboardingSetupItem[]
}

const { Paragraph, Title } = Typography

const OnboardingDoneNotifications = ({
  visible,
  handleIsOnboardingNotificationVisible,
}: IOnboardingDoneNotifications): ReactElement => {
  const {authCompany} = useAppSelector(selectAuthCompanySlice)
  const {formatMessage} = useIntl()
  const [matchedEvents, setMatchedEvents] = useState<IMatchedEventsToEntities | {}>({})
  const [onboardingSetupEvent, setOnboardingSetupEvent] = useState<IBasicEntitiesAIAssistentResponse | null>(null)
  const [leaveTypes, setLeaveTypes] = useState<IGetLeaveTypeList[] | null>(null)
  const [locations, setLocations] = useState<IGetLocationsShort[] | null>(null)
  const [isLoading, setIsloaading] = useState(false)
  const [allCompleted, setAllCompleted] = useState(false)
  const [isPolling, setIsPolling] = useState(false)

  useEffect(() => {
    if (!allCompleted && isPolling && visible) {
      const interval = setInterval(fetchSetupData, 2000)
      return () => clearInterval(interval)
    }
  }, [allCompleted, isPolling, visible])

  useEffect(() => {
    if (visible) {
      fetchData()
    }
  }, [visible])


  const fetchSetupData = async () => {
    let parsedSetupJSON: IBasicEntitiesAIAssistentResponse = {
      leavePolicies: [],
      locations: [],
      departments: [],
      leaveTypes: [],
    }
    try {
      const setupData = await API.graphql(graphqlOperation(getAiAssistedOnboardingSetupCoreEventsData, {
        companyId: authCompany?.id as string,
      })) as IData<IAiAssistedOnboardingSetupCoreEventsData>
      try {
        const setup = setupData.data.getAiAssistedOnboardingSetupCoreEvents.find(item => item.setupJSON)
        if (!setup || typeof setup.setupJSON !== 'string') {
          setIsPolling(true)
          // start the polling mechanism if no setup data is found
          return
        }
        parsedSetupJSON = JSON.parse(setup?.setupJSON as string) as IBasicEntitiesAIAssistentResponse
        setOnboardingSetupEvent(parsedSetupJSON)
      } catch (error) {
        setOnboardingSetupEvent(null)
      }
      const eventsWithoutSetupJson = setupData.data.getAiAssistedOnboardingSetupCoreEvents.filter(item => !item.setupJSON)
      setAllCompleted(eventsWithoutSetupJson.every(item => item.status === AIAssistedOnboardingCoreEventStatusEnum.completed))
      setMatchedEvents(matchEventsToEntities(parsedSetupJSON, eventsWithoutSetupJson))
      setIsloaading(false)
    } catch (error) {
      console.error(error)
      setOnboardingSetupEvent(null)
    }
  }

  const fetchData = async () => {
    setIsloaading(true)
    try {
      const locationsLeaveTypesResponse = await API.graphql(graphqlOperation(getLeaveTypesListAndLocations)) as IGetLeaveTypesListAndLocationsData
      setLeaveTypes(locationsLeaveTypesResponse.data.getLeaveTypeList)
      setLocations(locationsLeaveTypesResponse.data.getLocationList)
      await fetchSetupData()
    } catch (error) {
      console.error(error)
      setIsloaading(false)
    }
  }

  if (!onboardingSetupEvent && !isLoading && !isPolling) {
    return <Drawer
      open={visible}
      title={<IntlMessages id='ai.assisted.onboarding.openSetupNotificationTitle' />}
      onClose={() => handleIsOnboardingNotificationVisible()}
    >
      <EmptyData
        title={<IntlMessages id="ai.assisted.onboarding.openSetupNotificationNoData" />}
        subTitle={<IntlMessages id="ai.assisted.onboarding.openSetupNotificationNoDataSubtitle" />}
      />
    </Drawer>
  }
  return <Drawer
    open={visible}
    title={<IntlMessages id='ai.assisted.onboarding.openSetupNotificationTitle' />}
    onClose={() => handleIsOnboardingNotificationVisible()}
  >
    {isLoading && <div style={{ textAlign: 'center' }}>
      <CircularProgress />
      <div><IntlMessages id='ai.assisted.onboarding.openSetupNotificationFetchingSetup' /></div>
    </div>}
    {!isLoading && <>{
      Object.keys(matchedEvents).map((key) => {
        const title = translateEventGroup(key, formatMessage)
        return <div key={key}>
          <Card className='help-drawer-card'>
            <Title level={5} style={{ textAlign: 'center' }}>{title}</Title>
            {matchedEvents[key].map((event: IAIAssistedOnboardingSetupItem) => {
              const { leaveTypeName, locationName } = getLeaveTypeAndLocationNames(
                event,
                leaveTypes as IGetLeaveTypeList[],
                locations as IGetLocationsShort[],
                (matchedEvents as IMatchedEventsToEntities)?.leaveTypes,
                (matchedEvents as IMatchedEventsToEntities)?.locations
              )
              return <div key={event.id}>
                <Paragraph style={{ whiteSpace: 'break-spaces' }}>
                  {generateEventMessage(
                    event.eventType,
                    event.status,
                    formatMessage,
                    {
                      leaveTypeName,
                      locationName,
                    },
                    event.name
                  )}
                </Paragraph>
              </div>
            })}
          </Card>
        </div>
      })
    }</>}
  </Drawer>
}

export default OnboardingDoneNotifications