import React from 'react'
import { Col, Row, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'

import { IGetLocationGeneral, ILocationUser } from '../../types/locations'
import timezone from '@vacationtracker/shared/data/timezone'

import IntlMessages from '../../util/IntlMessages'
import WorkWeek from '../work-week'
import ResetQuotas from '../reset-quotas'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

interface ILocationGeneral {
  location: IGetLocationGeneral
  locale: LocaleEnum
  workdayEnabled: boolean
}

const LocationGeneral = ({ location, locale, workdayEnabled }: ILocationGeneral): React.ReactElement => {
  const locationTimezone = timezone.find(l => l.id === location.timezone)

  return (
    <div className="view-form vt-location-general">
      <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'><IntlMessages id="app.name" /></label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          {location.name}
        </div>
      </div>
      <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'><IntlMessages id="app.workweek" /></label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          <div className='vt-location-work-week-list'>
            <WorkWeek days={location.workWeek} firstDayOfWeek={location.firstDayOfWeek} />
          </div>
        </div>
      </div>
      {workdayEnabled && <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'><IntlMessages id="app.workday" /></label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          {/* TODO workday - when we add UI for custom work hours per day per location, change this: */}
          <div>{location.workHours ? location?.workHours[0][1] : WORKING_HOURS_IN_DAY} <IntlMessages id="app.hours" /></div>
        </div>
      </div>}
      <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'><IntlMessages id="location.timezone" /></label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          {locationTimezone ? locationTimezone.text : <IntlMessages id="location.timezoneDefault" />}
        </div>
      </div>
      <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'>
            <IntlMessages id="locations.resetQuotas" />
            <Tooltip className="info-tooltip" title={<IntlMessages id="location.resetQuotasInfo" />} ><InfoCircleOutlined /></Tooltip>
          </label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          <ResetQuotas
            status={location.resetQuotas}
            yearStartDay={location.yearStartDay}
            yearStartMonth={location.yearStartMonth}
            locale={locale}
            locationTimezone={location?.timezone}
          />
        </div>
      </div>
      <div className="ant-row ant-form-item wrap-label">
        <div className="ant-col ant-col-6 ant-form-item-label vt-col-sm-24">
          <label className='vt-md-mr-10'><IntlMessages id="app.users" /></label>
        </div>
        <div className="ant-col ant-col-16 ant-form-item-control vt-col-sm-24">
          {location.users.length > 0 ?
            <Row gutter={10}>
              {location.users.map((user: ILocationUser) => {
                return (
                  <Col key={user.id} xs={24} sm={12} md={12} lg={8} xl={8} className="user-avatar">
                    <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                  </Col>
                )
              })}
            </Row> :
            <IntlMessages id="location.hasNoUsers" />
          }
        </div>
      </div>
    </div>
  )
}

export default LocationGeneral
