import React from 'react'
import { 
  CalendarDaysIcon,
  DocumentPlusIcon,
  ClipboardDocumentListIcon,
  GlobeAltIcon,
  ScaleIcon,
  ClockIcon,
  EyeSlashIcon
} from '@heroicons/react/24/solid'
import { AddonsParent, IAddonsData } from '@vacationtracker/shared/types/automations'


export const getAddonsEvents = (formatMessage: Function) => {
  // every new addon, integration or automation should be added here
  // example:
  // {
  //   type: 'OPEN_API',
  //   parent: AddonsParent.addon,
  //   parentLabel: formatMessage({ id: 'app.addOns' }),
  //   roles: ['Admin', 'Approver'],
  //   url: 'open-api',
  //   icon: <CalendarDaysIcon />,
  //   description: formatMessage({ id: 'choiceAutomations.blackoutPeriodInfo' }),
  // },
  // {
  //   type: 'BAMBOO_INTEGRATION',
  //   parent: AddonsParent.integration,
  //   parentLabel: formatMessage({ id: 'app.integrations' }),
  //   roles: ['Admin', 'Approver'],
  //   url: 'bamboo-integration',
  //   icon: <CalendarDaysIcon />,
  //   description: formatMessage({ id: 'choiceAutomations.blackoutPeriodInfo' }),
  // },

  const events: IAddonsData[] = [
    {
      type: 'BLACKOUT_PERIOD',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: ['Admin', 'Approver'],
      url: 'blackout-period',
      icon: <CalendarDaysIcon />,
      description: formatMessage({ id: 'choiceAutomations.blackoutPeriodInfo' }),
    },
    {
      type: 'SENIORITY_ENTITLEMENT',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: ['Admin'],
      url: 'seniority-entitlement',
      icon: <DocumentPlusIcon />,
      description: formatMessage({ id: 'choiceAutomations.seniorityEntitlementShortInfo' }),
    },
    {
      type: 'ENTITLEMENT_BY_ROLE',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: ['Admin'],
      url: 'entitlement-by-role',
      icon: <ClipboardDocumentListIcon />,
      description: formatMessage({ id: 'choiceAutomations.enitlementByRoleShortInfo' }),
    },
    {
      type: 'VISIBILITY',
      parent: AddonsParent.addons,
      parentLabel: formatMessage({ id: 'app.addOns' }),
      roles: ['Admin'],
      url: 'visibility',
      icon: <EyeSlashIcon />,
      description: formatMessage({ id: 'choiceAutomations.visibilityInfo' }),
    },
    {
      type: 'PROBATION_PERIOD_LEAVE_RESTRICTION',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: [],
      url: 'coming-soon',
      icon: <GlobeAltIcon />,
      description: formatMessage({ id: 'choiceAutomations.employmentLeaveRestrictionInfo' }),
    },
    {
      type: 'OPEN_API',
      parent: AddonsParent.addons,
      parentLabel: formatMessage({ id: 'app.addOns' }),
      roles: ['Admin'],
      url: 'coming-soon',
      icon: <CalendarDaysIcon />,
      description: formatMessage({ id: 'choiceAutomations.openApiInfo' }),
    },
    {
      type: 'TEAM_LEAVE_BALANCING',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: [],
      url: 'coming-soon',
      icon: <ScaleIcon />,
      description: formatMessage({ id: 'choiceAutomations.teamLeaveBalancingInfo' }),
    },
    {
      type: 'LEAVE_DURATION_LIMIT',
      parent: AddonsParent.automations,
      parentLabel: formatMessage({ id: 'app.automations' }),
      roles: [],
      url: 'coming-soon',
      icon : <ClockIcon />,
      description: formatMessage({ id: 'choiceAutomations.leaveDurationLimitInfo' }),
    },
  ]

  return events
}