import { IEventBridgeMessage } from './event-bridge'
import { EventBridgeEvent } from 'aws-lambda/trigger/eventbridge'

export type Platform = 'microsoft' | 'google' | 'slack' | 'email' | 'system-internal'
// eslint-disable-next-line max-len
export type Source = 'dashboard' | 'microsoft-bot' | 'microsoft-tabs' | 'slack-bot' | 'system-internal' | 'slack-event' | 'create-company' | 'microsoft-event' | 'google-event' | 'ai-onboarding-assistant'

export interface ICoreEvent {
  eventType: EventType
  eventGroup: EventGroup
  groupKey: string
  validityStart: string
  timestamp: string
  creator: string
  companyId: string
  correlationId: string
  platform: Platform
  source: Source
  version?: number
  isMigration?: boolean
  error?: boolean
  errorCode?: string
  SK?: string
  muteNotifications?: boolean
}

export interface IDynamoDbKeys {
  PK?: string
  SK?: string
  LSI1?: string
  LSI2?: string
}

export interface IEventRepository {
  putEvent: (event: ICoreEvent) => Promise<void>
  putEvents: (events: ICoreEvent[]) => Promise<void>
  putEventsBatch: (events: ICoreEvent[], batchSize: number) => Promise<void>
  putEventsTransaction: (events: ICoreEvent[]) => Promise<void>
  getLatestEventInGroup: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string, now: Date) => Promise<T | null>
  getLatestEventInEventType: <T extends ICoreEvent>(companyId: string, eventType: EventType, now: Date) => Promise<T | null>
  getLatestEventInEventTypeForUser: <T extends ICoreEvent>(companyId: string, userId: string, eventGroup: EventGroup, eventType: EventType, now: Date) => Promise<T | null>
  getLatestEventInGroupAndEventType: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string, eventType: EventType, now: Date) => Promise<T | null>
  getFirstEventInGroupAndEventType: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string, eventType: EventType) => Promise<T | null>
  getRangeOfEventsInGroup: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string, start: Date, end: Date) => Promise<T[]>
  getRangeOfEventsInEventType: <T extends ICoreEvent>(comapnyId: string, eventType: EventType, start: Date, end: Date) => Promise<T[]>
  getRangeOfEventsInGroupAndEventType: <T extends ICoreEvent>(companyId: string,
    eventGroup: EventGroup, groupKey: string, eventType: EventType, start: Date, end: Date) => Promise<T[]>
  getRangeOfEventsForCompany: <T extends ICoreEvent>(companyId: string, start: Date, end: Date) => Promise<T[]>
  getAllEventsInGroup: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup) => Promise<T[]>
  getAllEventsInGroupWithGroupKey: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string) => Promise<T[]>
  getAllEventsInGroupAndEventType: <T extends ICoreEvent>(companyId: string, eventGroup: EventGroup, groupKey: string, eventType: EventType) => Promise<T[]>
}

export interface ICoreEventMeta {
  eventType: EventType
  eventGroup: EventGroup
  groupKey: string
  timestamp: string
  creator: string
  platform: string
}

export type ICoreEventBridgeEvent<T extends ICoreEvent> = EventBridgeEvent<string, IEventBridgeMessage<ICoreEventMeta, T>>

export type EventGroup =
    'COMPANY'
    | 'LEAVE_TYPE'
    | 'LEAVE_POLICY'
    | 'USER'
    | 'TEAM'
    | 'TEAM_POLICY'
    | 'LOCATION'
    | 'NOTIFICATION'
    | 'USER_LEAVE_REQUEST'
    | 'USER_TOIL_REQUEST'
    | 'LOCATION_YEAR_ROLLOVER'
    | 'BILLING'
    | 'BULK_ACTIONS'
    | 'FEEDBACK'
    | 'REPORT'
    | 'LABEL'
    | 'INTEGRATION' //this is old google usage
    | 'AUTOMATION'
    | 'ADDON'
    | 'INTEGRATION_ADDON'
    | 'SUBSTITUTE_APPROVER'

export enum EventGroupEnum {
  COMPANY = 'COMPANY',
  LEAVE_TYPE = 'LEAVE_TYPE',
  LEAVE_POLICY = 'LEAVE_POLICY',
  USER = 'USER',
  TEAM = 'TEAM',
  TEAM_POLICY = 'TEAM_POLICY',
  LOCATION = 'LOCATION',
  NOTIFICATION = 'NOTIFICATION',
  USER_LEAVE_REQUEST = 'USER_LEAVE_REQUEST',
  USER_TOIL_REQUEST = 'USER_TOIL_REQUEST',
  LOCATION_YEAR_ROLLOVER = 'LOCATION_YEAR_ROLLOVER',
  BILLING = 'BILLING',
  BULK_ACTIONS = 'BULK_ACTIONS',
  FEEDBACK = 'FEEDBACK',
  REPORT = 'REPORT',
  LABEL = 'LABEL',
  INTEGRATION = 'INTEGRATION',
  AUTOMATION = 'AUTOMATION',
  INTEGRATION_ADDON = 'INTEGRATION_ADDON',
  ADDON = 'ADDON',
}

export type EventType =
  'APP_BANNER_SET'
  | 'COMPANY_CREATED'
  | 'COMPANY_DELETED'
  | 'COMPANY_SETTINGS_UPDATED'
  | 'COMPANY_STATUS_CHANGED'

  | 'USER_STATUS_CHANGED'
  | 'USER_UPDATED'
  | 'USER_LEAVE_TYPES_UPDATED'
  | 'USER_LEAVE_TYPES_DELETED'
  | 'USER_LEAVE_TYPES_ROLLOVER_UPDATED'
  | 'USER_LEAVE_TYPES_ROLLOVER_DELETED'
  | 'USER_WORK_WEEK_UPDATED'
  | 'USER_WORK_WEEK_DELETED'
  | 'TEAM_USER_MOVED'
  | 'LOCATION_USER_MOVED'
  | 'USER_IMAGE_REFRESH'
  | 'USERS_INVITED'
  | 'USER_INVITATION_ACCEPTED'
  | 'USER_INVITATION_DELETED'
  | 'USER_LEAVE_TYPES_PRORATING_UPDATED'
  | 'USER_OUT_OF_OFFICE_SETTINGS_UPDATED'
  | 'USER_SENIORITY_ENTITLEMENT'
  | 'USER_SENIORITY_ENTITLEMENT_OVERRIDE'
  | 'USER_ENTITLEMENT_BY_ROLE'
  | 'USER_ENTITLEMENT_BY_ROLE_OVERRIDE'
  | 'USER_ENTITLEMENT_BY_ROLE_DELETED'

  | 'TEAM_CREATED'
  | 'TEAM_UPDATED'
  | 'TEAM_DELETED'

  | 'TEAM_APPROVER_STATUS_CHANGED'
  | 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_CREATED'
  | 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_UPDATED'
  | 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_DELETED'
  | 'TEAM_POLICY_CREATED'
  | 'TEAM_POLICY_UPDATED'
  | 'TEAM_POLICY_DELETED'

  | 'LOCATION_CREATED'
  | 'LOCATION_UPDATED'
  | 'LOCATION_DELETED'
  | 'LOCATION_WORK_WEEK_UPDATED'

  | 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED'
  | 'LOCATION_YEAR_HOLIDAYS_CHANGED'
  | 'LOCATION_YEAR_ROLLOVER'

  | 'NOTIFICATION_CREATED'
  | 'NOTIFICATION_UPDATED'
  | 'NOTIFICATION_DELETED'
  | 'NOTIFICATION_ERROR'

  | 'LEAVE_TYPE_CREATED'
  | 'LEAVE_TYPE_UPDATED'
  | 'LEAVE_TYPE_DELETED'
  | 'LEAVE_TYPE_ORDER'

  | 'LEAVE_POLICY_ENABLED'
  | 'LEAVE_POLICY_DISABLED'

  | 'LEAVE_REQUEST_CREATED'
  | 'LEAVE_REQUEST_UPDATED'
  | 'LEAVE_REQUEST_APPROVED'
  | 'LEAVE_REQUEST_DENIED'
  | 'LEAVE_REQUEST_EXPIRED'
  | 'LEAVE_REQUEST_CANCELLED'
  | 'LEAVE_REQUEST_ADDED'
  | 'LEAVE_REQUEST_DELETED'

  | 'TOIL_REQUEST_CREATED'
  | 'TOIL_REQUEST_UPDATED'
  | 'TOIL_REQUEST_APPROVED'
  | 'TOIL_REQUEST_DENIED'
  | 'TOIL_REQUEST_EXPIRED'
  | 'TOIL_REQUEST_CANCELLED'
  | 'TOIL_REQUEST_ADDED'
  | 'TOIL_REQUEST_DELETED'

  | 'IMPORT_LEAVES'

  | 'SUBSCRIPTION_CHANGED'
  | 'SUBSCRIPTION_CANCELED'
  | 'BILLING_UPDATED'
  | 'BILLING_CANCELLED'
  | 'PAYMENT_PROCESSOR_UPDATED'
  | 'ADDONS_UPDATED'

  | 'BULK_USER_STATUS'
  | 'SYNC_EXISTING_USERS'
  | 'ANNOUNCE_VT_COMPANY'
  | 'IMPORT_USERS'
  | 'ASSIGN_LICENSES'

  | 'USER_FEEDBACK'
  | 'AUTOMATION_FEEDBACK'
  | 'REQUEST_AUTOMATION_FEEDBACK'
  | 'SCHEDULE_A_CALL_AUTOMATION_FEEDBACK'

  | 'SCHEDULED_REPORT_CREATED'
  | 'SCHEDULED_REPORT_UPDATED'
  | 'SCHEDULED_REPORT_DELETED'

  | 'LABEL_CREATED'
  | 'LABEL_UPDATED'
  | 'LABEL_DELETED'
  | 'LABEL_ASSIGNED_TO_USER'
  | 'LABEL_REMOVED_FROM_USER'

  | 'GOOGLE_INTEGRATION'

  | 'BLACKOUT_PERIOD_CREATED'
  | 'BLACKOUT_PERIOD_UPDATED'
  | 'BLACKOUT_PERIOD_DELETED'

  | 'SENIORITY_ENTITLEMENT_CREATED'
  | 'SENIORITY_ENTITLEMENT_UPDATED'
  | 'SENIORITY_ENTITLEMENT_DELETED'

  | 'ENTITLEMENT_BY_ROLE_CREATED'
  | 'ENTITLEMENT_BY_ROLE_UPDATED'
  | 'ENTITLEMENT_BY_ROLE_DELETED'

  | 'VISIBILITY_ADDON_CREATED'
  | 'VISIBILITY_ADDON_UPDATED'
  
  | 'SUBSTITUTE_APPROVER_CREATED'
  | 'SUBSTITUTE_APPROVER_UPDATED'
  | 'SUBSTITUTE_APPROVER_DELETED'

export enum EventTypeEnum {
  APP_BANNER_SET = 'APP_BANNER_SET',
  COMPANY_CREATED = 'COMPANY_CREATED',
  COMPANY_DELETED = 'COMPANY_DELETED',
  COMPANY_SETTINGS_UPDATED = 'COMPANY_SETTINGS_UPDATED',
  COMPANY_STATUS_CHANGED = 'COMPANY_STATUS_CHANGED',
  USER_STATUS_CHANGED = 'USER_STATUS_CHANGED',
  USER_UPDATED = 'USER_UPDATED',
  USER_LEAVE_TYPES_UPDATED = 'USER_LEAVE_TYPES_UPDATED',
  USER_LEAVE_TYPES_DELETED = 'USER_LEAVE_TYPES_DELETED',
  USER_LEAVE_TYPES_ROLLOVER_UPDATED = 'USER_LEAVE_TYPES_ROLLOVER_UPDATED',
  USER_LEAVE_TYPES_ROLLOVER_DELETED = 'USER_LEAVE_TYPES_ROLLOVER_DELETED',
  USER_WORK_WEEK_UPDATED = 'USER_WORK_WEEK_UPDATED',
  USER_WORK_WEEK_DELETED = 'USER_WORK_WEEK_DELETED',
  TEAM_USER_MOVED = 'TEAM_USER_MOVED',
  LOCATION_USER_MOVED = 'LOCATION_USER_MOVED',
  USER_IMAGE_REFRESH = 'USER_IMAGE_REFRESH',
  USERS_INVITED = 'USERS_INVITED',
  USER_INVITATION_ACCEPTED = 'USER_INVITATION_ACCEPTED',
  USER_INVITATION_DELETED = 'USER_INVITATION_DELETED',
  USER_OUT_OF_OFFICE_SETTINGS_UPDATED = 'USER_OUT_OF_OFFICE_SETTINGS_UPDATED',
  USER_SENIORITY_ENTITLEMENT = 'USER_SENIORITY_ENTITLEMENT',
  USER_SENIORITY_ENTITLEMENT_OVERRIDE = 'USER_SENIORITY_ENTITLEMENT_OVERRIDE',
  USER_ENTITLEMENT_BY_ROLE = 'USER_ENTITLEMENT_BY_ROLE',
  USER_ENTITLEMENT_BY_ROLE_OVERRIDE = 'USER_ENTITLEMENT_BY_ROLE_OVERRIDE',
  USER_ENTITLEMENT_BY_ROLE_DELETED = 'USER_ENTITLEMENT_BY_ROLE_DELETED',
  TEAM_CREATED = 'TEAM_CREATED',
  TEAM_UPDATED = 'TEAM_UPDATED',
  TEAM_DELETED = 'TEAM_DELETED',
  TEAM_APPROVER_STATUS_CHANGED = 'TEAM_APPROVER_STATUS_CHANGED',
  TEAM_LEAVE_TYPE_APPROVAL_FLOW_CREATED = 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_CREATED',
  TEAM_LEAVE_TYPE_APPROVAL_FLOW_UPDATED = 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_UPDATED',
  TEAM_LEAVE_TYPE_APPROVAL_FLOW_DELETED = 'TEAM_LEAVE_TYPE_APPROVAL_FLOW_DELETED',
  TEAM_POLICY_CREATED = 'TEAM_POLICY_CREATED',
  TEAM_POLICY_UPDATED = 'TEAM_POLICY_UPDATED',
  TEAM_POLICY_DELETED = 'TEAM_POLICY_DELETED',
  LOCATION_CREATED = 'LOCATION_CREATED',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
  LOCATION_DELETED = 'LOCATION_DELETED',
  LOCATION_WORK_WEEK_UPDATED = 'LOCATION_WORK_WEEK_UPDATED',
  LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED = 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED',
  LOCATION_YEAR_HOLIDAYS_CHANGED = 'LOCATION_YEAR_HOLIDAYS_CHANGED',
  LOCATION_YEAR_ROLLOVER = 'LOCATION_YEAR_ROLLOVER',
  NOTIFICATION_CREATED = 'NOTIFICATION_CREATED',
  NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED',
  NOTIFICATION_DELETED = 'NOTIFICATION_DELETED',
  NOTIFICATION_ERROR = 'NOTIFICATION_ERROR',
  LEAVE_TYPE_CREATED = 'LEAVE_TYPE_CREATED',
  LEAVE_TYPE_UPDATED = 'LEAVE_TYPE_UPDATED',
  LEAVE_TYPE_DELETED = 'LEAVE_TYPE_DELETED',
  LEAVE_TYPE_ORDER = 'LEAVE_TYPE_ORDER',
  LEAVE_POLICY_ENABLED = 'LEAVE_POLICY_ENABLED',
  LEAVE_POLICY_DISABLED = 'LEAVE_POLICY_DISABLED',
  LEAVE_REQUEST_CREATED = 'LEAVE_REQUEST_CREATED',
  LEAVE_REQUEST_UPDATED = 'LEAVE_REQUEST_UPDATED',
  LEAVE_REQUEST_APPROVED = 'LEAVE_REQUEST_APPROVED',
  LEAVE_REQUEST_DENIED = 'LEAVE_REQUEST_DENIED',
  LEAVE_REQUEST_EXPIRED = 'LEAVE_REQUEST_EXPIRED',
  LEAVE_REQUEST_CANCELLED = 'LEAVE_REQUEST_CANCELLED',
  LEAVE_REQUEST_ADDED = 'LEAVE_REQUEST_ADDED',
  LEAVE_REQUEST_DELETED = 'LEAVE_REQUEST_DELETED',
  TOIL_REQUEST_CREATED = 'TOIL_REQUEST_CREATED',
  TOIL_REQUEST_UPDATED = 'TOIL_REQUEST_UPDATED',
  TOIL_REQUEST_APPROVED = 'TOIL_REQUEST_APPROVED',
  TOIL_REQUEST_DENIED = 'TOIL_REQUEST_DENIED',
  TOIL_REQUEST_EXPIRED = 'TOIL_REQUEST_EXPIRED',
  TOIL_REQUEST_CANCELLED = 'TOIL_REQUEST_CANCELLED',
  TOIL_REQUEST_ADDED = 'TOIL_REQUEST_ADDED',
  TOIL_REQUEST_DELETED = 'TOIL_REQUEST_DELETED',
  SUBSCRIPTION_CHANGED = 'SUBSCRIPTION_CHANGED',
  SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
  BILLING_UPDATED = 'BILLING_UPDATED',
  BILLING_CANCELLED = 'BILLING_CANCELLED',
  PAYMENT_PROCESSOR_UPDATED = 'PAYMENT_PROCESSOR_UPDATED',
  ADDONS_UPDATED = 'ADDONS_UPDATED',
  BULK_USER_STATUS = 'BULK_USER_STATUS',
  SYNC_EXISTING_USERS = 'SYNC_EXISTING_USERS',
  ANNOUNCE_VT_COMPANY = 'ANNOUNCE_VT_COMPANY',
  IMPORT_LEAVES = 'IMPORT_LEAVES',
  IMPORT_USERS = 'IMPORT_USERS',
  ASSIGN_LICENSES = 'ASSIGN_LICENSES',
  USER_FEEDBACK = 'USER_FEEDBACK',
  AUTOMATION_FEEDBACK = 'AUTOMATION_FEEDBACK',
  REQUEST_AUTOMATION_FEEDBACK = 'REQUEST_AUTOMATION_FEEDBACK',
  SCHEDULED_REPORT_CREATED = 'SCHEDULED_REPORT_CREATED',
  SCHEDULED_REPORT_UPDATED = 'SCHEDULED_REPORT_UPDATED',
  SCHEDULED_REPORT_DELETED = 'SCHEDULED_REPORT_DELETED',
  LABEL_CREATED = 'LABEL_CREATED',
  LABEL_UPDATED = 'LABEL_UPDATED',
  LABEL_DELETED = 'LABEL_DELETED',
  LABEL_ASSIGNED_TO_USER = 'LABEL_ASSIGNED_TO_USER',
  LABEL_REMOVED_FROM_USER = 'LABEL_REMOVED_FROM_USER',
  GOOGLE_INTEGRATION = 'GOOGLE_INTEGRATION',
  BLACKOUT_PERIOD_CREATED = 'BLACKOUT_PERIOD_CREATED',
  BLACKOUT_PERIOD_UPDATED = 'BLACKOUT_PERIOD_UPDATED',
  BLACKOUT_PERIOD_DELETED = 'BLACKOUT_PERIOD_DELETED',
  SENIORITY_ENTITLEMENT_CREATED = 'SENIORITY_ENTITLEMENT_CREATED',
  SENIORITY_ENTITLEMENT_UPDATED = 'SENIORITY_ENTITLEMENT_UPDATED',
  SENIORITY_ENTITLEMENT_DELETED = 'SENIORITY_ENTITLEMENT_DELETED',
  ENTITLEMENT_BY_ROLE_CREATED = 'ENTITLEMENT_BY_ROLE_CREATED',
  ENTITLEMENT_BY_ROLE_UPDATED = 'ENTITLEMENT_BY_ROLE_UPDATED',
  ENTITLEMENT_BY_ROLE_DELETED = 'ENTITLEMENT_BY_ROLE_DELETED',
  SUBSTITUTE_APPROVER_CREATED = 'SUBSTITUTE_APPROVER_CREATED',
  SUBSTITUTE_APPROVER_UPDATED = 'SUBSTITUTE_APPROVER_UPDATED',
  SUBSTITUTE_APPROVER_DELETED = 'SUBSTITUTE_APPROVER_DELETED',
  VISIBILITY_ADDON_CREATED = 'VISIBILITY_ADDON_CREATED',
  VISIBILITY_ADDON_UPDATED = 'VISIBILITY_ADDON_UPDATED',
}
