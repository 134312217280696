import { GetTeamsForApproverData } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { getTeamsForApprover, getSuggestedSubstituteApprovers, getAutomations } from '../../graphql/custom-queries'
import { areIntervalsOverlapping, isBefore, isSameDay } from 'date-fns'
import { IGetAutomations, IGetUsersForAdminAddLeaveData, IUserIdAndName } from '../../types/custom-queries'
import { IData } from '../../types/data'
import dayjs from 'dayjs'
import { useAppSelector } from '../../store/hooks'
import { selectFeatureFlagsSlice } from '../../store/feature-flags-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'


export const useSubstituteApprover = (userId?: string, startDate?: string, endDate?: string) => {
  const [checkIfSubstituteNeeded, setCheckIfSubstituteNeeded] = useState(false) 
  const [isSubstituteListLoading, setIsSubstituteListLoading] = useState(false) 
  const [shouldShowSubstituteApprovers, setShouldShowSubstituteApprovers] = useState(false)
  const [suggestedSubstituteApprovers, setSuggestedSubstituteApprovers] = useState<IUserIdAndName[]>([])
  const [isLimitedVisibility, setIsLimitedVisibility] = useState(false)
  const [teamIds, setTeamIds] = useState<string[]>([])  
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)

  useEffect(() => {
    if (checkIfSubstituteNeeded && userId) {
      checkForSubstitutes(userId)
    }
  }, [checkIfSubstituteNeeded, startDate, endDate, userId])

  useEffect(() => {
    if (checkIfSubstituteNeeded && startDate && endDate) {
      getUsersList(startDate, endDate)
    }
  }, [checkIfSubstituteNeeded, userId, teamIds])

  useEffect(() => {
    checkLimitedVisibility(featureFlags)
  }, [isLimitedVisibility])

  const checkForSubstitutes = async (userId?: string) => {
    // if no start or end date, or end date is in the past
    if (!startDate || !endDate || (isBefore(new Date(endDate), new Date()) && !isSameDay(new Date(endDate), new Date())) || !userId) {
      setShouldShowSubstituteApprovers(false)
      setCheckIfSubstituteNeeded(false)
      return
    }
    //first check if user requesting leave is approver
    const teamsForApprover = (await API.graphql(
      graphqlOperation(getTeamsForApprover, {id: userId, date: new Date()})
    )) as GetTeamsForApproverData
    const teams = teamsForApprover.data.getUser.approverToTeams
    const userTeamId = teamsForApprover.data.getUser.teamId

    //create a new array of team ids [string] where user is approver and team where he is user (filter duplicates)
    const userTeamIds = Array.from(new Set([...teams.map((team) => team.id), userTeamId]))

    setTeamIds(userTeamIds)
  
    // check if there are teams where user is only approver
    const teamsWithUserAsOnlyApprover = teams.filter(
      (team) => team.approvers.length === 1 && team.approvers[0].id === userId
    )
    const overlappingApproversData: {
      teamId: string
      teamName: string
      approverId: string
      name: string
    }[] = []
  
    if (startDate && endDate) {
      teams.forEach((team) => {
        team.approvers.forEach((approver) => {
          const hasOverlap = approver.upcomingLeaves.some(
            (leave) =>
              leave.status === 'APPROVED' &&
              areIntervalsOverlapping(
                {start: new Date(leave.startDate), end: new Date(leave.endDate)},
                {start: new Date(startDate), end: new Date(endDate)},
                {inclusive: true}
              )
          )
  
          if (hasOverlap) {
            overlappingApproversData.push({
              teamId: team.id,
              teamName: team.name,
              approverId: approver.id,
              name: approver.name,
            })
          }
        })
      })
    }
    if (overlappingApproversData.length || teamsWithUserAsOnlyApprover.length) {
      setShouldShowSubstituteApprovers(true)
    }
    setCheckIfSubstituteNeeded(false)
  }

  //temporary function to check if visibility addon is enabled
  const checkLimitedVisibility = async (featureFlags: string[]) => {
    const response = await API.graphql(graphqlOperation(getAutomations)) as IData<IGetAutomations>
    const limitedVisibilityAddon = response.data?.getAutomations?.find((addon) => addon.automationType === 'VISIBILITY')
  
    if (limitedVisibilityAddon?.isActive) {
      setIsLimitedVisibility(true)
    }
  }

  const getUsersList = async (startDate: string, endDate: string) => {
    try {
      setIsSubstituteListLoading(true)
      const users = await getUsersWithPagination(100, dayjs(startDate).format('YYYY-MM-DD'))

      const sortedAndFilteredUsersAll: any[] = users
        .sort((a: any, b: any) =>
          a.name < b.name ? -1 : 1
        )
        .filter(  
          // filter out users that are on leave
          (user: any) => {
            const hasOverlap = user.upcomingLeaves?.some(
              (leave) =>
                areIntervalsOverlapping(
                  {start: new Date(leave.startDate), end: new Date(leave.endDate)},
                  {start: new Date(startDate), end: new Date(endDate)},
                  {inclusive: true}
                )
            )
            return !hasOverlap
          }
        )
        .filter((user) => user.id !== userId)
      const shouldLimit = isLimitedVisibility && authUser.role !== 'Admin'
      const sortedAndFilteredUsers = shouldLimit ? sortedAndFilteredUsersAll.filter((user) => teamIds.includes(user?.teamId as string)) : sortedAndFilteredUsersAll

      // sort and filter out the requesting user
      setSuggestedSubstituteApprovers(sortedAndFilteredUsers)
      setIsSubstituteListLoading(false)
    } catch (err) { 
      setIsSubstituteListLoading(false)
      setSuggestedSubstituteApprovers([])
      console.log('error fetching users list', err) 
    }
  }


  return {
    isSubstituteListLoading,
    setCheckIfSubstituteNeeded,
    shouldShowSubstituteApprovers,
    setShouldShowSubstituteApprovers,
    suggestedSubstituteApprovers,
  }
}

const getUsersWithPagination = async (
  limit: number,
  date?: string,
  nextToken?: string,
  accumulatedResults: IUserIdAndName[] = []
) => {
  const response = (await API.graphql(
    graphqlOperation(getSuggestedSubstituteApprovers, {limit, nextToken, date})
  )) as IData<IGetUsersForAdminAddLeaveData>

  const {users, nextToken: newNextToken} =
    response.data.getActiveUsersWithPagination

  const updatedResults = [...accumulatedResults, ...users]

  if (newNextToken) {
    return getUsersWithPagination(limit, date, newNextToken, updatedResults)
  } else {
    return updatedResults
  }
}
