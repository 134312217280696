import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { ISlackPostInstallationProps } from './types'
import { SeoTags } from '../../components/seo-tags'
import { Button, Col, Row, Skeleton, Typography, notification } from 'antd'
import { SlackOutlined } from '@ant-design/icons'
import VtLogo from '../../assets/images/logo-purple.svg'
import IntlMessages from '../../util/IntlMessages'
import { getUserId as getSlackUserId } from '../../services/api/slack'
import { SlackAuth } from '../../services/auth/slack/slack'
import { track } from '../../services/analytics/analytics'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router'
import { updateSlackToken } from '../../services/api/slack'
import { FrontendUrls } from '../../types/urls'
import { getCompanyAndUser } from '../../services/api/companies'
import { useAppDispatch } from '../../store/hooks'
import { setUserId } from '../../store/user-id-slice'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'
import { useIntl } from 'react-intl'
import OtherPlatformLoginModal from '../../components/other-platform-login-modal'
import { IContactAdminData } from '../../types/auth'
import CompanyExistsModal from '../../components/modal-company-exists'

const { Paragraph, Title } = Typography
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)

const SlackInstallBot = (props: ISlackPostInstallationProps): React.ReactElement => {
  const { onStateChange, match } = props

  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [userName, setUserName] = useState<string | null>(null)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [slackTeamId, setSlackTeamId] = useState<string | null>(match.params.teamId)
  const [slackTeamName, setSlackTeamName] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoadingStatus] = useState(false)
  const [otherPlatformLogin, setOtherPlatformLogin] = useState(false)
  const [existsOnAnotherPlatform, setExistsOnAnotherPlatform] = useState('')
  const [existingOrganizationName, setExistingOrganizationName] = useState('')
  const [contactAdminModal, setContactAdminModal] = useState<IContactAdminData | null>(null)
  const [companyExists, setCompanyExists] = useState(false)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  useEffect(() => {
    if(queryParams.realName && queryParams.realName !== '') {
      setUserName(queryParams.realName as string)
    }
  }, [queryParams])
  
  const installSlackApp = async () => {
    setError(null)
    setLoadingStatus(true)
    let userData
    try {
      const slackInstallResponse = await slackAuth.signin(
        SLACK_REQUIRED_BOT_SCOPES,
        SLACK_REQUIRED_USER_SCOPES,
        slackTeamId
      )
      
      const slackUserId = slackInstallResponse.authed_user.id
      const userToken = slackInstallResponse.authed_user.access_token
      const botToken = slackInstallResponse.access_token
      const teamId = slackInstallResponse.team.id
      const teamName = slackInstallResponse.team.name

      slackAuth.setBotToken(botToken)
      slackAuth.setUserToken(userToken)

      setSlackTeamName(teamName)

      userData = await getSlackUserId(slackUserId, userToken)
      const user = await slackAuth.getUser(slackUserId, botToken)

      const emailOfAuthedUser: string = userData.mail || slackInstallResponse.authed_user?.email || ''

      setUserEmail(emailOfAuthedUser)

      setUserName(user.real_name as string || user.name as string)

      if (userData.type === 'COMPANY_NOT_FOUND') {
        localStorage.setItem('vtCreateUser', JSON.stringify({
          name: userData.name,
          // TODO:@filip remove prefix
          id: `slack-${userData.id}`,
          mail: emailOfAuthedUser,
          userEmail: emailOfAuthedUser,
          platform: 'slack',
          slackBotToken: botToken,
          slackUserToken: userToken,
          slackUserId: slackUserId,
          teamName: teamName,
          slackTeamId: teamId,
          slackOrgTeamId: slackInstallResponse.enterprise?.id,
          slackBotId: slackInstallResponse.bot_user_id,
          imageUrl: userData?.slackUser?.imageUrl,
          isOwner: userData?.slackUser?.isOwner,
          isAdmin: userData?.slackUser?.isAdmin,
          timezone: userData?.slackUser?.timezone,
        }))

        if (userData.existsOnAnotherPlatform && userData.existingOrganizationName) {
          track('ORG_EXISTS_ON_ANOTHER_PLATFORM_OPEN_MODAL', { platform: 'slack', existingOrganizationName: userData.existingOrganizationName })
          setExistsOnAnotherPlatform(userData.existsOnAnotherPlatform)
          setExistingOrganizationName(userData.existingOrganizationName)
          setOtherPlatformLogin(true)
          return
        }

        setLoadingStatus(false)
        onStateChange('signUp')
        history.push(FrontendUrls.createCompanyStep1)
        return
      }

      if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
        notification.error({
          message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
          description: formatMessage({ id: 'error.subscriptionExpired' }),
          duration: 0,
        })
        return
      }

      if(userData.type === 'USER_NOT_FOUND') {
        setContactAdminModal({
          platform: 'slack',
          organizationName: userData.organizationName || '',
          contactEmail: userData.contactEmail || '',
          adminContacts: userData.adminContacts || [],
        })
        track('ORG_ALREADY_EXISTS_PAGE_VIEWED', {
          platform: 'slack',
          organizationName: userData.organizationName,
        })
        setCompanyExists(true)
        setLoadingStatus(false)
        return
      }

      const signInResponse = await Auth.signIn(userData.vacationTrackerId)
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
        const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, userToken, { loginType: 'slack' })
        const cognitoUsername = cognitoResponse.username as string
        localStorage.setItem('userId', cognitoUsername)

        try {
          await updateSlackToken(userToken, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setLoadingStatus(false)
            setError('bot_token_not_valid')
            return
          }
        }
        dispatch(setUserId(cognitoUsername))
        await getCompanyAndUser(cognitoUsername, dispatch, onStateChange, history)
        onStateChange('signedIn')
        history.push(FrontendUrls.dashboard)
      }

      setLoadingStatus(false)
    } catch (error) {
      console.log('error', error)
      setError(typeof error === 'string' ? error : error.message as string)
      setLoadingStatus(false)
    }
  }

  const connectWithPlatform = (platform: string) => {
    history.push(FrontendUrls.signin + `?platform=${platform}`)
  }

  const closeOtherPlatformLoginModal = () => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CLOSE_MODAL', {})
    history.push(FrontendUrls.signup)
  }

  useEffect(() => {
    if (match.params.teamId) {
      setSlackTeamId(match.params.teamId)
    }
  }, [match.params.teamId])

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signup'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48 },{ xs: 16, lg: 48 }]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 24 }}>
            {
              error === null ? (
                <Skeleton loading={loading} paragraph={{ rows: 2, width: 320 }} title>
                  <Title level={3} style={{ textAlign: 'center' }}>{
                    <IntlMessages id='connect.welcome' values={{
                      name: userName,
                    }} />
                  }</Title>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation1' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation2' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation3' />
                  </Paragraph>
                </Skeleton>
              ) : (
                <>
                  <Title level={3} style={{ textAlign: 'center' }}>
                    Install Vacation Tracker bot
                  </Title>
                  {
                    error === 'invalid_code' ? (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.invalidCode' />
                      </Paragraph>
                    ) : (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.generic' />
                      </Paragraph>
                    )
                  }
                  <Paragraph style={{ textAlign: 'center' }}>
                    Click the button below to install the Vacation Tracker bot to your Slack workspace.
                  </Paragraph>
                </>
              )
            }
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 12 }}>
            { loading ? (
              <Skeleton.Button />
            ) : (
              <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => installSlackApp()}>
                <IntlMessages id="connect.signInWithSlackInstallBot" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      {companyExists && contactAdminModal && userEmail &&
        <CompanyExistsModal
          companyExists={companyExists}
          setCompanyExists={setCompanyExists}
          contactAdminModal={contactAdminModal}
          userEmail={userEmail}
          userWorkspaceName={slackTeamName ?? undefined}
        />
      }
      {
        otherPlatformLogin &&
          <OtherPlatformLoginModal
            otherPlatformLogin={otherPlatformLogin}
            connectWithPlatform={connectWithPlatform}
            closeOtherPlatformLoginModal={closeOtherPlatformLoginModal}
            existingOrganizationName={existingOrganizationName}
            existsOnAnotherPlatform={existsOnAnotherPlatform}
            selectedPlatform={'slack'}
          />
      }
    </div>
  )
}

export default SlackInstallBot
