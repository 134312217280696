import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, DatePicker, Form, notification, Radio, Select, Space, Typography, Spin, Modal } from 'antd'
import { WarningOutlined, CloseCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { getLocationsTeamsAndLabelsShort } from '../../../graphql/custom-queries'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { useAppSelector } from '../../../store/hooks'

import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import CircularProgress from '../../../components/circular-progress'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import IntlMessages from '../../../util/IntlMessages'

import { ITeamShort } from '@vacationtracker/shared/types/team'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { IGetLocationsTeamsAndLabelsShort } from '../../../types/custom-queries'
import { IGetLabelsShort } from '../../../types/labels'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { NotificationType } from '@vacationtracker/shared/types/notification'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { track } from '../../../services/analytics/analytics'

const { Option } = Select
const { RangePicker } = DatePicker
const { Text } = Typography
const EXPORT_TIME_WAIT_MINUTES = 3
const EXPORT_TIME_POLL_INTERVAL_SECONDS = 10
const EXPORT_LS_KEY = 'waiting_for_export'

const ExportPage: React.FC = () => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [isLoading, setIsLoading] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [exportLink, setExportLink] = useState<string|undefined>(undefined)
  const [reportType, setReportType] = useState(NotificationType.leaveHistoryReport)
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [showDateRange, setShowDateRange] = useState(false)
  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)

  const defaultValue = {
    type: NotificationType.leaveHistoryReport,
    locations: [],
    teams: [],
    labels: [],
    period: 'CURRENT_YEAR',
    leaveStatus: ['APPROVED', 'DENIED', 'EXPIRED', 'CANCELLED'],
    exportFormat: 'csv',
  }

  useEffect(() => {
    pollUrl()
  }, [])

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  const fetchData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getLocationsTeamsAndLabelsShort)) as IGetLocationsTeamsAndLabelsShort

      setTeams(response.data.getTeamListV2)
      setLocations(response.data.getLocationList)
      setLabels(response.data.getLabels)
      setIsLoading(false)
    } catch (err) {
      console.log('error fetching user by id', err)
    }
  }

  const handleChangeReportType = (value) => {
    setShowDateRange(false)
    setReportType(value)
  }

  const downloadFile = (url: string)  => {
    setExportLink(undefined)
    localStorage.setItem(EXPORT_LS_KEY, `${(Date.now() + EXPORT_TIME_WAIT_MINUTES * 60 * 1000).toString()}|${url}`)
    setTimeout(() => { pollUrl() }, 2000)
  }

  const pollUrl = async (): Promise<void> => {
    setSubmitLoader(true)
    const time = localStorage.getItem(EXPORT_LS_KEY)?.substring(0, 13)
    const url = localStorage.getItem(EXPORT_LS_KEY)?.substring(14)

    if (!time || time < Date.now().toString()) {
      localStorage.removeItem(EXPORT_LS_KEY)
      setSubmitLoader(false)
      return
    }
    const startTime = Date.now()
  
    const checkUrlValidity = async (): Promise<boolean> => {
      try {
        const response = await fetch(`https://${url}`)
        return response.ok
      } catch (error) {
        return false
      }
    }
  
    while (Date.now() - startTime < EXPORT_TIME_WAIT_MINUTES * 60 * 1000) {
      const isValid = await checkUrlValidity()
      if (isValid) {
        setExportLink(`https://${url}`)
        setSubmitLoader(false)
        localStorage.removeItem(EXPORT_LS_KEY)
        return
      }
      await new Promise(resolve => setTimeout(resolve, EXPORT_TIME_POLL_INTERVAL_SECONDS * 1000))
    }
  
    setSubmitLoader(false)
    localStorage.removeItem(EXPORT_LS_KEY)
    console.log(`URL is still not valid after ${EXPORT_TIME_WAIT_MINUTES * 60} seconds.`)
    notification.error({message: formatMessage({ id: 'error.generic' }), duration: 0})
  }

  const handleSelectLeaveBalanceType = (type) => {
    setShowDateRange(type === 'CUSTOM_DAYS')
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields()
      const exportFormat = values.exportFormat
      setSubmitLoader(true)
      let body

      if (reportType === NotificationType.leaveBalanceReport) {
        const startDate = values.rangeDate ? values.rangeDate[0].format('YYYY-MM-DD') : null
        const endDate = values.rangeDate ? values.rangeDate[1].format('YYYY-MM-DD') : null
        body = {
          creator: authUser.id,
          teams: filter.teamIds,
          locations: filter.locationIds,
          labels: filter.labelIds,
          reportType,
          period: values.period,
          exportFormat,
          ...(values.period === 'CUSTOM_DAYS' && { startDate, endDate }),
        }
      }

      if (reportType === NotificationType.leaveHistoryReport) {
        const startDate = values.rangeDate ? values.rangeDate[0].format('YYYY-MM-DD') : null
        const endDate = values.rangeDate ? values.rangeDate[1].format('YYYY-MM-DD') : null
        body = {
          creator: authUser.id,
          reportType,
          teams: filter.teamIds,
          locations: filter.locationIds,
          labels: filter.labelIds,
          leaveStatus: values.leaveStatus,
          startDate,
          endDate,
          exportFormat,
        }
      }
      if (reportType === NotificationType.monthlyLeaveBalanceReport) {
        body = {
          creator: authUser.id,
          reportType,
          teams: filter.teamIds,
          locations: filter.locationIds,
          labels: filter.labelIds,
          exportFormat,
        }
      }
      const session = await Auth.currentSession()
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/core/export`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: exportFormat === 'excel' ? 'application/vnd.ms-excel' : 'text/csv',
          Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        },
      })

      downloadFile(result.data)
      
      track('REPORT_DOWNLOADED', {
        platform: authUser.platform,
        userId: authUser.id,
        reportType,
      })
    } catch (error) {
      console.log('ERROR HANDLE SUBMIT', error)
      setSubmitLoader(false)
      const description = error.response?.data?.error ? error.response?.data?.error : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description,
        duration: 0,
      })
    }
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  }

  const tailLayout = {
    wrapperCol: {xs: { offset: 0, span: 18 }, md: { offset: 6, span: 18 }}, 
  }

  return (
    <div className='main-content'>
      {isLoading ? <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span><IntlMessages id="app.export" /></span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="sidebar.reports" /></Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="app.export" /></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="main-content-body">
            <div className="export-body">
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFinish}
                initialValues={defaultValue}
                {...layout}
              >
                <Form.Item
                  label={<IntlMessages id="app.reportType" />}
                  name="type"
                  rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
                >
                  <Select onChange={handleChangeReportType}>
                    <Option value="leaveHistoryReport" key="leaveHistoryReport" ><IntlMessages id="app.leaveHistoryReport" /></Option>
                    <Option value="leaveBalanceReport" key="leaveBalanceReport" ><IntlMessages id="app.leaveBalanceReport" /></Option>
                    <Option value="monthlyLeaveBalanceReport" key="monthlyLeaveBalanceReport" ><IntlMessages id="app.monthlyLeaveBalanceReport" /></Option>
                  </Select>
                </Form.Item>

                {reportType === NotificationType.leaveBalanceReport &&
                  <Form.Item
                    label={<IntlMessages id="export.exportPeriod" />}
                    name="period"
                    rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
                  >
                    <Select onSelect={handleSelectLeaveBalanceType}>
                      <Option value="CURRENT_YEAR" key="CURRENT_YEAR"><IntlMessages id="app.currentYear" /></Option>
                      <Option value="LAST_MONTH" key="LAST_MONTH"><IntlMessages id="app.lastMonth" /></Option>
                      <Option value="PREVIOUS_YEAR" key="PREVIOUS_YEAR"><IntlMessages id="export.previousYear" /></Option>
                      <Option value="NEXT_YEAR" key="NEXT_YEAR"><IntlMessages id="app.nextYear" /></Option>
                      <Option value="CUSTOM_DAYS" key="CUSTOM_DAYS"><IntlMessages id="app.customDays" /></Option>
                    </Select>
                  </Form.Item>
                }

                {(reportType === NotificationType.leaveHistoryReport || showDateRange) && 
                  <Form.Item name="rangeDate"
                    label={<IntlMessages id="app.dateRange" />}
                    rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
                    extra={
                      reportType === NotificationType.leaveHistoryReport ?
                        <span><WarningOutlined /> <IntlMessages id="export.leaveBalanceReportCustomDaysInfo" /></span> :
                        null
                    }>
                    <RangePicker />
                  </Form.Item>
                }

                {reportType === NotificationType.leaveHistoryReport &&
                  <Form.Item
                    label={<IntlMessages id="app.status" />}
                    name="leaveStatus"
                    rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
                  >
                    <Select style={{ width: '270px' }} mode="multiple">
                      <Option value="APPROVED" key="APPROVED"><IntlMessages id="app.approved" /></Option>
                      <Option value="DENIED" key="DENIED"><IntlMessages id="app.denied" /></Option>
                      <Option value="EXPIRED" key="EXPIRED"><IntlMessages id="app.expired" /></Option>
                      <Option value="CANCELLED" key="CANCELLED"><IntlMessages id="app.cancelled" /></Option>
                    </Select>
                  </Form.Item>
                }

                <FilterAdvanced
                  isForm={true}
                  data={{
                    Locations: locations,
                    Departments: teams,
                    Labels: labels,
                  }}
                  onChangeFilter={(data) => setFilter(data)}
                  showLabels={shouldEnableLabels}
                  toolTipInfo="components.filterSimple.tooltipInfo"
                />

                <Form.Item
                  label={<IntlMessages id="export.fileType" />}
                  name="exportFormat"
                >
                  <Radio.Group>
                    <Radio.Button value="csv" defaultChecked>{<IntlMessages id="export.fileTypeCsv" />}</Radio.Button>
                    <Radio.Button value="excel">{<IntlMessages id="export.fileTypeExcelXlsx" />}</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" loading={submitLoader} htmlType="submit">
                    <IntlMessages id="app.export" />
                  </Button>
                  {submitLoader && <div style={{ marginTop: '10px' }}><Spin size="large" tip={<IntlMessages id="app.downloadProgressReport"/>} /></div>}
                </Form.Item>
                
              </Form>

              <Modal
                title={<IntlMessages id={`app.${reportType}`} />}
                visible={exportLink !== undefined}
                onCancel={() => setExportLink(undefined)}
                closeIcon={<CloseCircleOutlined />}
                footer={null}
              >
                <div><a href={exportLink} target="_blank" rel="noopener noreferrer">
                  <Button onClick={() => setExportLink(undefined)}><IntlMessages id="app.downloadReady" /></Button>
                </a></div>
              </Modal>


              <Space direction="vertical" style={{ marginTop: 20 }}>
                <Text type="secondary" strong><IntlMessages id="app.leaveHistoryReport" /></Text>
                <Text type="secondary"><IntlMessages id="export.leaveHistoryReportDescription" /></Text>
                <Text type="secondary" strong><IntlMessages id="app.leaveBalanceReport" /></Text>
                <Text type="secondary"><IntlMessages id="export.leaveBalanceReportDescription" /></Text>
                <Text type="secondary" strong><IntlMessages id="app.monthlyLeaveBalanceReport" /></Text>
                <Text type="secondary"><IntlMessages id="export.monthlyLeaveBalanceReportDescription" /></Text>
              </Space>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default ExportPage
