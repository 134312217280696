import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import moment, { Moment } from 'moment'
import { Button, Card, Col, DatePicker, Drawer, Row, Space, Typography, Switch, notification } from 'antd'
import { LeftOutlined, RightOutlined, CloseOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'
import { API } from 'aws-amplify'

import useGetLeaves from './hooks/useGetLeaves'
import useGetCalendarInfo from './hooks/useGetCalendarInfo'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectLocaleSlice } from '../../store/locale-slice'
import { selectLeaveRequestActionEventSlice } from '../../store/leave-request-action-event-slice'
import { notificationStore } from '../../context/notificationsContext/store'

import IntlMessages from '../../util/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import CircularProgress from '../../components/circular-progress'
import { Wallchart } from '../../components/wallchart'
import { WallchartLeaveDrawer } from '../../components/wallchart-leave-drawer'
import { openSupportChat } from '../../util/open-support-chat'

import { IGetCalendarInfo } from '../../types/custom-queries'
import { IHolidayExtend } from '@vacationtracker/shared/types/holidays'
import { IGetLeaveRequestsByUsers, IWallchartUserData, IWallchartUserLeavesData } from '../../types/wallchart'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

const { Paragraph, Text } = Typography
const { RangePicker } = DatePicker

const WALLCHART_MAX_DAYS_RANGE = 35
const WALLCHART_MIN_DAYS_RANGE = 14
const WALLCHART_USERS_LIMIT = 20
const datesCacheKey = 'wallchartDates'

type RangeValue = [Moment | null, Moment | null] | null
type Direction = 'left' | 'right'

interface IDates {
  dateFrom: Date
  dateTo: Date
}

const WallchartPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)

  const [dates, setDates] = useState<[Moment, Moment]>([moment().startOf('month'), moment().endOf('month')])

  const [calendarDates, setCalendarDates] = useState<RangeValue>(null)
  const [value, setValue] = useState<RangeValue>(null)

  const [focusedLeaves, setFocusedLeaves] = useState<IWallchartUserLeavesData[]>([])
  const [focusedUser, setFocusedUser] = useState<IWallchartUserData>()
  const [focusedHoliday, setFocusedHoliday] = useState<IHolidayExtend>()
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [filters, setFilters] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [selectedLeaveType, setSelectedLeaveType] = useState<string | null>(null)
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false)
  const [isInitialLoad, setIsInitialLoad] =  useState(true)
  const [showPendingRequests, setShowPendingRequests] =  useState<boolean>(true)
  const [manipulateLeaveRequestAction, setManipulateLeaveRequestAction] =  useState<boolean>(false)

  const {calendarInfo, getCalendarInfoData} = useGetCalendarInfo()
  const {leaves, loading, noMoreLeaves, getLeaves, setLeaves, setLoading, hideLeaves} = useGetLeaves({
    key: 'wallchart-loading-error',
    message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
    description: (<>
      <Paragraph>{formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {link: (...chunks) => <a onClick={() => openSupportChat()}>{chunks}</a>})}</Paragraph>
    </>),
    btn: (<Button onClick={() => {window.location.reload()}}>{ formatMessage({ id: 'app.reload'} )}</Button>),
    duration: 0,
  })

  useEffect(() => {
    if (isInitialLoad) {
      const [ locationIds = [], teamIds = [], labelIds = [] ] = ['locations', 'departments', 'labels'].map(name => new URLSearchParams(location.search).get(name)?.split(','))
      setFilters(prevValues => {
        return {
          ...prevValues,
          locationIds,
          teamIds,
          labelIds,
        }
      })

      setInitialFilterValues(() => {
        const data: ISelected[] = []

        if (locationIds?.length) {
          data.push({ type: 'Locations', values: locationIds as string[] })
        }
        if (teamIds?.length) {
          data.push({ type: 'Departments', values: teamIds as string[] })
        }
        if (labelIds?.length) {
          data.push({ type: 'Labels', values: labelIds as string[]})
        }

        if(data.length === 0) {
          return [{}]
        }
        return data
      })

      let dateFrom = dates[0].toDate()
      let dateTo = dates[1].toDate()
      const cachedDates = getDatesFromCache()
      if (cachedDates) {
        dateFrom = moment(cachedDates.dateFrom).toDate()
        dateTo = moment(cachedDates.dateTo).toDate()
        setDates([moment(cachedDates.dateFrom), moment(cachedDates.dateTo)])
      }
      getUserLeavesAndCalendarInfo(dateFrom, dateTo, locationIds, teamIds, labelIds)
    }
  }, [])

  useEffect(() => {
    if (leaveRequestActionEvent) {
      getLeavesFromApi(dates[0].toDate() as Date, dates[1].toDate() as Date)
    }
  }, [leaveRequestActionEvent])

  const safeLocale = locale?.locale || availableLanguages.en

  const getUserLeavesAndCalendarInfo = async (dateFrom: Date, dateTo: Date,locationIds: string[], teamIds: string[], labelIds: string[]) => {
    await getCalendarInfoData(authUser)
    getLeaves({dateFrom,dateTo,locationIds,teamIds,labelIds,limit: WALLCHART_USERS_LIMIT})
    setInitialDataLoaded(true)
    setIsInitialLoad(false)
  }

  const filterChanged = (data: IFilter) => {
    if(!isEqual(data, filters)) {
      let reject = true
      for (const [key] of Object.entries(data)) {
        if (!isEqual(filters[key], data[key]) && !isInitialLoad) {
          reject = false
        }
      }
      if (reject) return
      setLoading(true)

      const { locationIds, teamIds, labelIds } = data
      let url = location.pathname
      let searchParams = ''
      if (locationIds?.length) {
        searchParams += `&locations=${locationIds.join(',')}`
      }
      if (teamIds?.length) {
        searchParams += `&departments=${teamIds.join(',')}`
      }
      if (labelIds?.length) {
        searchParams += `&labels=${labelIds.join(',')}`
      }
      url += searchParams.replace('&', '?')
      window.history.pushState({}, '', url)

      setFilters(prevState => {
        return {
          ...prevState,
          ...data,
        }
      })

      // This setTimeout is required if the URL have query filters
      setTimeout(() => {
        getLeaves({
          dateFrom: dates[0].toDate(),
          dateTo: dates[1].toDate() as Date,
          locationIds: data.locationIds,
          teamIds: data.teamIds,
          labelIds: data.labelIds,
          limit: WALLCHART_USERS_LIMIT,
          leaveTypeIdFilter: selectedLeaveType as string,
          leaveStatusFilter: !showPendingRequests ? 'APPROVED' : undefined,
        })
      }, 500)
    }
  }

  const toggleLeaveType = (leaveTypeId: string) => {
    let leaveTypeIdFilter
    if (leaveTypeId !== selectedLeaveType) {
      leaveTypeIdFilter = leaveTypeId
      setSelectedLeaveType(leaveTypeId)
    } else {
      setSelectedLeaveType(null)
    }
    const filteredLeaves = hideLeaves(leaves, !showPendingRequests ? 'APPROVED' : undefined, leaveTypeIdFilter as string)
    setLeaves(filteredLeaves)
  }

  const togglePendingLeaves = () => {
    const showPending = !showPendingRequests
    const filteredLeaves = hideLeaves(leaves, !showPending ? 'APPROVED' : undefined, selectedLeaveType ?? undefined)
    setLeaves(filteredLeaves)
    setShowPendingRequests(showPending)
  }

  const disabledDate = (current: Moment) => {
    if (!calendarDates) {
      return false
    }
    const tooLate =
      calendarDates[0] && current.diff(calendarDates[0], 'days') > WALLCHART_MAX_DAYS_RANGE ||
      calendarDates[0] && current.diff(calendarDates[0], 'days') < WALLCHART_MIN_DAYS_RANGE
    const tooEarly =
      calendarDates[1] && calendarDates[1].diff(current, 'days') > WALLCHART_MAX_DAYS_RANGE ||
      calendarDates[1] && calendarDates[1].diff(current, 'days') < WALLCHART_MIN_DAYS_RANGE
    return !!tooEarly || !!tooLate
  }

  const onOpenChange = (open: boolean) => {
    open ? setCalendarDates([null, null]) : setCalendarDates(null)
  }

  const getLeavesFromApi = (dateFrom: Date, dateTo: Date, lastId?: string) => {
    getLeaves({
      dateFrom,
      dateTo,
      locationIds: filters.locationIds,
      teamIds: filters.teamIds,
      labelIds: filters.labelIds,
      limit: WALLCHART_USERS_LIMIT,
      lastId,
      leaveTypeIdFilter: selectedLeaveType as string,
      leaveStatusFilter: !showPendingRequests ? 'APPROVED' : undefined,
    })
  }

  const onCalendarChange = (dates) => {
    setCalendarDates(dates as RangeValue)
    if (!dates || dates.length < 2 || dates[0] === null || dates[1] === null) return
    setDates(dates as [Moment, Moment])
    addDatesToCache({dateFrom: dates[0].toDate(), dateTo: dates[1].toDate()})
    getLeavesFromApi(dates[0].toDate() as Date, dates[1].toDate() as Date)
  }

  const loadMore = () => {
    if (noMoreLeaves) return
    const lastId = leaves[leaves.length - 1].user.id
    getLeavesFromApi(dates[0].toDate() as Date, dates[1].toDate() as Date, lastId)
  }

  const addDatesToCache = (dates: IDates): void => {
    localStorage.setItem(datesCacheKey, JSON.stringify(dates))
  }

  const getDatesFromCache = (): IDates | null => {
    return JSON.parse(localStorage.getItem(datesCacheKey) as string)
  }

  const offsetWallchart = (direction: Direction): void => {
    const weeks = 1
    const newDateFrom = direction === 'left' ? moment(dates[0]).subtract(weeks, 'week') : moment(dates[0]).add(weeks, 'week')
    const newDateTo = direction === 'left' ? moment(dates[1]).subtract(weeks, 'week') : moment(dates[1]).add(weeks, 'week')
    setDates([newDateFrom, newDateTo])
    setCalendarDates([newDateFrom, newDateTo])
    setValue([newDateFrom, newDateTo])
    getLeavesFromApi(newDateFrom.toDate() as Date, newDateTo.toDate() as Date)
  }

  const approveLeaveRequest = (leaveIndex: number): void => {
    sendEditLeaveRequestEvent('LEAVE_REQUEST_APPROVED', focusedLeaves[leaveIndex])
  }

  const denyLeaveRequest = async(leaveIndex: number, reason?: string): Promise<void> => {
    await sendEditLeaveRequestEvent('LEAVE_REQUEST_DENIED', focusedLeaves[leaveIndex], reason)
  }

  const sendEditLeaveRequestEvent = async (eventType: string, leave: IWallchartUserLeavesData, reason?: string): Promise<void> => {
    setManipulateLeaveRequestAction(true)
    const response = await API.post('CoreEvent', '/core/event', {
      body: {
        eventType: eventType,
        eventGroup: 'USER_LEAVE_REQUEST',
        userId: focusedUser?.id,
        leaveRequestId: leave.id,
        statusReason: reason,
      },
    })
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'app.updatedInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([
      ...actionNotifications,
      response.correlationId,
    ])
    setFocusedLeaves([])
    setFocusedUser(undefined)
    setManipulateLeaveRequestAction(false)
  }

  return (
    <div className="main-content" id="wallchart">
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.wallchart" /></span>
        </div>
      </div>
      <div className="main-content-body">
        {loading && !initialDataLoaded ?
          <CircularProgress /> :
          <div className="calendar-header">
            <div className='wallchart-rangepicker-wrapper'>
              <RangePicker
                allowClear={false}
                value={calendarDates || value || dates}
                inputReadOnly={true}
                ranges={{
                  [formatMessage({ id: 'wallchart.range.thisMonth'})]: [moment().startOf('month'), moment().endOf('month')],
                  [formatMessage({ id: 'wallchart.range.nextMonth'})]: [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')],
                  [formatMessage({ id: 'wallchart.range.previousMonth'})]: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                  [formatMessage({ id: 'wallchart.range.next4Weeks'})]: [moment(), moment().add(4, 'weeks')],
                  [formatMessage({ id: 'wallchart.range.next5Weeks'})]: [moment(), moment().add(5, 'weeks')],
                }}
                disabledDate={disabledDate}
                onCalendarChange={onCalendarChange}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
              />
              <div className='wallchart-date-navigation'>
                <span onClick={() => offsetWallchart('left')}><LeftOutlined /></span>
                <span onClick={() => offsetWallchart('right')}><RightOutlined /></span>
              </div>
              <div className='wallchart-pending-radio'>
                <span><IntlMessages id="leaveRequests.pending"/>&nbsp;</span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={showPendingRequests}
                  onChange={togglePendingLeaves}
                />
              </div>
              <div className="filters">
                <div>
                  {!isInitialLoad &&
                  <FilterAdvanced
                    page='wallchart'
                    data={{
                      Locations: calendarInfo?.getLocationList || [],
                      Departments: calendarInfo?.getTeamListV2 || [],
                      Labels: calendarInfo?.getLabels || [],
                    }}
                    onChangeFilter={filterChanged}
                    initialValues={initialFilterValues}
                    showLabels={shouldEnableFeatures}
                    currentUserId={authUser.id}
                  />
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {loading && !initialDataLoaded ? <CircularProgress /> :
          <Wallchart
            dateFrom={dates[0]?.toDate()}
            dateTo={dates[1]?.toDate()}
            leaves={leaves as IGetLeaveRequestsByUsers[]}
            calendarInfo={calendarInfo as IGetCalendarInfo}
            toggleLeaveType={toggleLeaveType}
            selectedLeaveType={selectedLeaveType}
            setFocusedLeaves={setFocusedLeaves}
            setFocusedUser={setFocusedUser}
            setFocusedHoliday={setFocusedHoliday}
            loadMore={loadMore}
            locale={safeLocale}
          />}
      </div>
      {focusedLeaves && <WallchartLeaveDrawer
        showDrawer={focusedLeaves.length > 0}
        locationList={calendarInfo?.getLocationList}
        teamList={calendarInfo?.getTeamListV2}
        focusedLeaves={focusedLeaves}
        focusedUser={focusedUser}
        locale={safeLocale}
        onClose={() => {
          setFocusedLeaves([])
          setFocusedUser(undefined)
        }}
        approveLeaveRequest={approveLeaveRequest}
        denyLeaveRequest={denyLeaveRequest}
        loading={manipulateLeaveRequestAction}
        hourFormat={authUser.hourFormat || HourFormatEnum.twentyFour}
      />}
      <Drawer
        title={<IntlMessages id="components.heatmap.holiday"/>}
        placement="right"
        onClose={() => setFocusedHoliday(undefined)}
        visible={focusedHoliday !== undefined}
        width={Math.min(400, window.innerWidth)}
      >
        {focusedHoliday &&
          <Card>
            <Row justify="start" gutter={[0,15]}>
              <Col span={24}>
                <Space direction="vertical" size={[1, -15]} wrap>
                  <Text strong><IntlMessages id="holidays.holidaysName"/>:</Text>
                  <Text data-testid="holiday-name">{focusedHoliday?.name}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={[1, -15]} wrap>
                  <Text strong><IntlMessages id="components.leavesColumns.dates"/>:</Text>
                  <Text data-testid="holiday-dates">
                    <FormattedDate value={focusedHoliday.date} format='YYYY-MM-DD' />
                    {' '}{focusedHoliday.isHalfDay && <IntlMessages id="components.leavesColumns.halfDayHoliday" />}{' '}
                    {focusedHoliday?.multiDayId && focusedHoliday?.endDate && (
                      <>
                        - <FormattedDate value={focusedHoliday?.endDate} format='YYYY-MM-DD' />
                      </>
                    )}
                  </Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={[1, -15]} wrap>
                  <Text strong><IntlMessages id="dashboard.days" />:</Text>
                  <Text data-testid="holiday-dates">
                    <FormattedDate value={focusedHoliday.date} format="dddd" />
                    {focusedHoliday.multiDayId && <> - <FormattedDate value={focusedHoliday.endDate as string} format="dddd" /></> }
                  </Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={[1, -15]} wrap>
                  <Text strong><IntlMessages id="app.location"/>:</Text>
                  <Text data-testid="holiday-name">{focusedHoliday?.locationName}</Text>
                </Space>
              </Col>
            </Row>
          </Card>
        }
      </Drawer>
    </div>
  )
}

export default WallchartPage
