import React from 'react'
import { Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import format from 'date-fns/format'

import getToAccrueDaysAndHours from '../../functions/get-to-accrue-days-and-hours'
import { getPayoutDateForBiWeeklyPeriod, getPayoutDateForSemiMonthlyPeriod, roundTo2Decimals, roundTo4Decimals } from '@vacationtracker/shared/functions/calculation-shared'
import { getPayoutDateForMonthlyPeriod } from '@vacationtracker/shared/functions/calculation-shared'
import { getDatesForAccrualsMonthly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-monthly'
import { getDatesForAccrualsBiWeekly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-bi-weekly'
import { getDateFnsLocale } from '../../i18n'
import { getDatesForAccrualsSemiMonthly } from '../../functions/get-dates-for-accruals/get-dates-for-accruals-semi-monthly'
import { getDaysOrHours } from '../../functions/get-days-or-hours'
import { addTimezoneOffsetToDate } from '../utils/add-offset-to-date'

import { AccrualTypeEnum } from '@vacationtracker/shared/types/leave-policy'
import { IAccruedTooltipProps } from './types'
import { getDatesForAccrualsWeekly } from '../../functions/get-dates-for-accruals/get-dates-for-accruals-weekly'

export const AccruedTooltip = ({
  selectedLeaveType,
  userName,
  policyFirstEarningDate,
  handleGoToUserLogPage,
  formatMessage,
  IntlMessages,
  yearStartDay,
  accrualPeriodStart,
  capHit,
  isCurrentYearSelected,
  hourlyLeaveAccounting,
  userLocale,
  userStatus = 'ACTIVE',
}: IAccruedTooltipProps) => {
  const startPeriod = accrualPeriodStart
  const today: Date = new Date()
  const toilDays = Number(selectedLeaveType.toilDays - selectedLeaveType.toilDaysLapsed)
  const earnedDays = selectedLeaveType.earnedDaysWithoutRollover - toilDays
  const earnedDaysInHours = roundTo2Decimals(selectedLeaveType.earnedDaysWithoutRollover * 8) - roundTo2Decimals(toilDays * 8)
  let policyNextEarningDate
  const {
    toAccrueDays,
    toAccrueHours,
  } = getToAccrueDaysAndHours(selectedLeaveType.accrualType, selectedLeaveType.quota - toilDays)
  // here we have to see if monthly, weekly or bi-weekly
  // TODO: check for the last month of period for rounding
  // show info message when/how much should a user accrue
  // only when current year is selected
  if (isCurrentYearSelected) {
    switch (selectedLeaveType.accrualType) {
      case AccrualTypeEnum.monthly: {
        const { accrualPeriodEnd } = getDatesForAccrualsMonthly({today, yearStartDay})
        policyNextEarningDate =  getPayoutDateForMonthlyPeriod(
          addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
          addTimezoneOffsetToDate(new Date(policyFirstEarningDate)),
          accrualPeriodStart ? addTimezoneOffsetToDate(new Date(accrualPeriodStart)) : undefined
        )
        break
      }
      case AccrualTypeEnum.semiMonthly: {
        const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsSemiMonthly({today, yearStartDay})
        policyNextEarningDate =  getPayoutDateForSemiMonthlyPeriod(
          addTimezoneOffsetToDate(new Date(accrualPeriodStart)), 
          addTimezoneOffsetToDate(new Date(accrualPeriodEnd)), 
          addTimezoneOffsetToDate(new Date(policyFirstEarningDate)),
          accrualPeriodStart ? addTimezoneOffsetToDate(new Date(accrualPeriodStart)) : undefined
        )
        break
      }
      case AccrualTypeEnum.biWeekly: {
        const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsBiWeekly({ today, accrualPeriodStart: startPeriod })
        policyNextEarningDate =  getPayoutDateForBiWeeklyPeriod(
          addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
          addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
          addTimezoneOffsetToDate(new Date(policyFirstEarningDate)),
          accrualPeriodStart ? addTimezoneOffsetToDate(new Date(accrualPeriodStart)) : undefined
        )
        break
      }
      case AccrualTypeEnum.weekly: {
        const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsWeekly({ today, accrualPeriodStart: startPeriod })
        policyNextEarningDate =  getPayoutDateForBiWeeklyPeriod(
          addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
          addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
          addTimezoneOffsetToDate(new Date(policyFirstEarningDate)),
          accrualPeriodStart ? addTimezoneOffsetToDate(new Date(accrualPeriodStart)) : undefined
        )
        break
      }
      default:
        break
    }
  }

  return (<>
    {capHit && 
      <p style={{ fontSize: '16px' }}>
        <ExclamationCircleOutlined style={{ color: 'red' }} />&nbsp;
        <IntlMessages id="components.leavePolicyForm.accrualCapWarning" values={{ leaveTypeName: selectedLeaveType.leaveTypeName }}/>
      </p>
    }
    <p><IntlMessages id="components.userLeaveQuotas.accrualsTooltipTitle" /></p>
    <p>
      <IntlMessages 
        id={`components.userLeaveQuotas.accrualsTooltipTitle-${selectedLeaveType.accrualType.toLowerCase()}`} 
        values={{ leaveType: selectedLeaveType.leaveTypeName}} 
      />
    </p>
    <p>
      {formatMessage({ id: hourlyLeaveAccounting ? 'components.userLeaveQuotas.accruedInfo1Hours' : 'components.userLeaveQuotas.accruedInfo1Days'}, {
        userName,
        earnedDays,
        earnedDaysInHours,
        defaultDays: selectedLeaveType.quota - toilDays,
        defaultDaysInHours: selectedLeaveType.quota * 8 - toilDays * 8,
      })}
    </p>
    {isCurrentYearSelected && userStatus === 'ACTIVE' && <p>
      {formatMessage({ id: hourlyLeaveAccounting ? 'components.userLeaveQuotas.accruedInfo2Hours' : 'components.userLeaveQuotas.accruedInfo2Days'}, {
        userName,
        toAccrueDays: roundTo4Decimals(toAccrueDays),
        toAccrueHours: roundTo2Decimals(toAccrueHours),
        earnDate: format(new Date(policyNextEarningDate as Date), 'MMM dd, yyyy', {locale: getDateFnsLocale(userLocale)}),
      })}
    </p>}
    {userStatus !== 'ACTIVE' && <p><IntlMessages id="components.userLeaveQuotas.userInactiveInfo" /></p>}
    {toilDays > 0 && <p>
      <IntlMessages id="components.toil.earned"/> <IntlMessages id={hourlyLeaveAccounting ? 'app.hours': 'app.days'}/> :&nbsp;
      {getDaysOrHours(roundTo2Decimals(toilDays), hourlyLeaveAccounting)}
      {selectedLeaveType.toilDaysLapsed > 0 &&
      <>&nbsp;({getDaysOrHours(roundTo2Decimals(selectedLeaveType.toilDaysLapsed), hourlyLeaveAccounting)}&nbsp;<IntlMessages id="components.toil.lapsed"/>)</>}
    </p>}
    {toilDays > 0 && <p>
      <IntlMessages 
        id="components.toil.accrualsToilsTotal"
        values={{hours:hourlyLeaveAccounting ? 1 : 2, total: getDaysOrHours(roundTo2Decimals(selectedLeaveType.earnedDays), hourlyLeaveAccounting)}}
      />
    </p>}
    <p>
      <IntlMessages id="components.userLeaveQuotas.forMoreDetails" /> <Button type='link' onClick={handleGoToUserLogPage}>
        <IntlMessages id="components.userLeaveQuotas.logsTab" />
      </Button>
    </p>
  </>)
}
