import React, { memo, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import {IntlProvider} from 'react-intl'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import qs from 'qs'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { getCompanyAndUserInfo, getUserActionFlow, getUserHelpInfo } from '../../graphql/custom-queries'

import { identify, page } from '../../services/analytics/analytics'
import { logout } from '../../services/auth/logout-handler'

import { OnboardingProvider } from '../../context/onboardingContext'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setFeatureFlags } from '../../store/feature-flags-slice'
import { isMicrosoftPayment, setAuthCompany } from '../../store/auth-company-slice'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { selectLocaleSlice, setLocale } from '../../store/locale-slice'
import { selectAuthUserSlice, setAuthUser } from '../../store/auth-user-slice'
import { setOnboardingActions, hideOnboardingFlow } from '../../store/onboarding-slice'

import MainApp from './MainApp'
import Notifications from '../Notifications'
import CompanyUpdatesNotifications from '../CompanyUpdatesNotifications'
import Signin from '../signin'
import Signup from '../signup'
import SlackPostInstallation from '../slack-post-installation'
import ExternalConnect from '../external-connect'
import CreateCompany from '../create-company'
import MicrosoftSaaSCreateCompany from '../microsoft-saas-create-company'
import SignInAsUser from '../sign-in-as-user'
import UserSignup from '../user-signup'
import AppLocale from '../../lngProvider'

import SubscriptionPage from '../../routes/SubscriptionPage'
import MicrosoftSubscriptionPage from '../../routes/MicrosoftSubscriptionPage'
import { SeoTags } from '../../components/seo-tags'

import { IData } from '../../types/data'
import { IGetCompanyAndUserInfo, IGetUserActionFlowData, IGetUserHelpInfoData } from '../../types/custom-queries'
import { IUserInfo } from '../../types/users'
import { wait } from '@vacationtracker/shared/functions/wait'
import { ICompanyInfo } from '@vacationtracker/shared/types/company'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { FrontendUrls } from '../../types/urls'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { setHelpInfoActions } from '../../store/help-info-slice'
import WhatsNewNotifications from '../WhatsNewNotifications'
import GooglePermissions from '../google-permissions'
import { UserRole } from '@vacationtracker/shared/types/user'
import SlackInstallBot from '../slack-install-bot'

interface IApp {
  location: {
    pathname: string
    search: string
  }
  authState: string
  onStateChange: (e) => void
  children?: React.ReactElement[]
}
interface IRestrictedRoute {
  component: Function
  path: string
}

const RestrictedRoute = ({component: Component, path}: IRestrictedRoute) =>
  <Route
    path={path}
    render={props => <Component {...props} />}
  />

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const UDID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i

const App = (props: IApp): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const { userId } = useAppSelector(selectUserIdSlice)
  const { isSigningUp } = useAppSelector(selectAuthUserSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useAppDispatch()
  const [user, setUser] = useState<IUserInfo>()
  const [currentAppLocale, setCurrentApplocale] = useState(AppLocale[locale.locale])
  const [goToDashboardSection, setGoToDashboardSection] = useState<boolean>(false)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    page(location.pathname.replace(UDID_REGEX, 'ID'))
  }, [location.pathname])

  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = []
    window.$crisp.push(['safe', true])
    window.CRISP_WEBSITE_ID = 'b0a287f7-571c-45e0-92bc-3c30d8abc2ea'
    const script = document.createElement('script')
    script.src = 'https://client.crisp.chat/l.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (userId && userId !== '') {
      if (queryParams?.tour) {
        refreshToken()
      }
      getCompanyAndUser(userId)
    }
  }, [userId])

  useEffect(() => {
    setCurrentApplocale(AppLocale[locale.locale])
  }, [locale.locale])

  useEffect(() => {
    if (queryParams?.lang) {
      dispatch(setLocale(availableLanguages[queryParams.lang as LocaleEnum]))
    }
    const locationPathName = props?.location?.pathname
    if (queryParams?.forceLogin === 'true') {
      delete queryParams.forceLogin

      logout({
        onStateChange: props.onStateChange,
        history: history,
        redirectRoute: `${FrontendUrls.connect}?${qs.stringify(queryParams)}`,
        reduxDispatch: dispatch,
        userId: userId,
      })
    }

    if (locationPathName === FrontendUrls.createCompanyStep3 && isSigningUp) {
      return
    }
    if (locationPathName === FrontendUrls.googlePermissions) {
      return
    }

    if (locationPathName.includes('/app/') && !userId && !localStorage.getItem('userId')) {
      localStorage.clear()
      sessionStorage.clear()
      props.onStateChange('signIn')
    }

    if (props.authState === 'signIn') {
      if (locationPathName.includes(FrontendUrls.externalConnect)) {
        return
      }
      if ([FrontendUrls.kats].includes(locationPathName as FrontendUrls)) {
        history.push(locationPathName)
        return
      }
      if ([FrontendUrls.googlePermissions].includes(locationPathName as FrontendUrls)) {
        history.push(locationPathName)
        return
      }
      if(locationPathName.includes(FrontendUrls.signin)) {
        history.push(FrontendUrls.signin + location.search)
        return
      }
      if(
        !locationPathName.includes(FrontendUrls.signup) &&
        !locationPathName.includes(FrontendUrls.slackPostInstall) &&
        !locationPathName.includes(FrontendUrls.installSlackBot)
      ) {
        history.push(FrontendUrls.signup)
        return
      }
    }

    if (props.authState === 'signedIn') {
      if (locationPathName === FrontendUrls.createCompanyStep3) {
        history.push(`${FrontendUrls.dashboard}?tour=true`)
        return
      }
      if (['/', FrontendUrls.kats, FrontendUrls.googlePermissions].includes(locationPathName)) {
        history.push(FrontendUrls.dashboard)
        return
      }
    }

    if (props.authState === 'loading' && locationPathName === FrontendUrls.signup && localStorage.getItem('userId')) {
      setGoToDashboardSection(true)
      history.push(FrontendUrls.dashboard)
      return
    }

    if (props.authState === 'signUp') {
      if (localStorage.getItem('userId') && goToDashboardSection) {
        history.push(FrontendUrls.dashboard)
        return
      }
      if (locationPathName === FrontendUrls.createCompany) {
        history.push(FrontendUrls.createCompanyStep1)
        return
      }
      if (locationPathName === FrontendUrls.microsoftSaasCreateCompany) {
        history.push(FrontendUrls.signup)
        return
      }
      if (
        !locationPathName.includes(FrontendUrls.createCompany) &&
        !locationPathName.includes(FrontendUrls.signup) &&
        !locationPathName.includes(FrontendUrls.slackPostInstall) &&
        !locationPathName.includes(FrontendUrls.installSlackBot) &&
        !locationPathName.includes(FrontendUrls.signin) // For redirects
      ) {
        history.push(FrontendUrls.signup)
        return
      }
    }

  }, [props.authState, props.location.pathname])

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {})
    } catch (error) {
      console.log('ERROR', error)
      logout({
        onStateChange: props.onStateChange,
        history: history,
        reduxDispatch: dispatch,
      })
    }
  }

  const getFeatureFlags = async (role: UserRole, pathname) => {
    try {
      const response = await API.get('CoreEvent', `/core/status?role=${role.toLowerCase()}&url=${pathname}`, { withCredentials: true })
      const userIdInLocalStorage = localStorage.getItem('userId')
      if (userIdInLocalStorage && userIdInLocalStorage !== response.userId ) {
        logout({
          onStateChange: props.onStateChange,
          history: history,
          reduxDispatch: dispatch,
        })
      }
      dispatch(setFeatureFlags(response.enabledFeatures))
    } catch(error) {
      console.log('ERROR', error)
    }
  }

  const getOnboardingChecklist = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUserActionFlow, {type: 'dashboard'})) as IData<IGetUserActionFlowData>
      if(!response.data.getUserActionFlow) {
        dispatch(hideOnboardingFlow())
        return
      }
      const actions = pickBy(response.data.getUserActionFlow.actions || {}, identity)
      response.data.getUserActionFlow.actions = actions
      dispatch(setOnboardingActions(response.data.getUserActionFlow || {}))
    } catch(error) {
      console.log('ERROR', error)
    }
  }

  const getHelpInfoForUser = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUserHelpInfo)) as IData<IGetUserHelpInfoData>
      dispatch(setHelpInfoActions(response.data.getUserHelpInfo || {}))
    } catch(error) {
      console.log('ERROR', error)
    }
  }

  let retryCrispSessionUpdate = 0
  const setCrispSessionInfoForLoggedInUsers = (companyInfo: ICompanyInfo, userInfo: IUserInfo): void => {
    try {
      if (!window.$crisp) {
        if(retryCrispSessionUpdate < 5) {
          retryCrispSessionUpdate++
          setTimeout(() => {
            setCrispSessionInfoForLoggedInUsers(companyInfo, userInfo)
          }, 20000)
        }
        return
      }

      // Set Crisp segments, i.e., "chat," "support," "dashboard," "slack," and "core-plan"
      const segments = ['chat', 'support', 'dashboard']
      segments.push(companyInfo.platform === 'microsoft' ? 'teams' : companyInfo.platform)
      if (companyInfo.plan) {
        segments.push(companyInfo.plan.toLowerCase() + '-plan')
      }
      window.$crisp.push(['set', 'session:segments', [segments, true]])

      // Set user name and company (user data)
      window.$crisp.push(['set', 'user:nickname', userInfo.name])
      window.$crisp.push(['set', 'user:company', companyInfo.name])

      // Set session data (company Id, user Id, user role and if the user is approver)
      window.$crisp.push(['set', 'session:data', [[
        ['company_id', companyInfo.id],
        ['user_id', userInfo.id],
        ['role', userInfo.role],
        ['locale', userInfo.locale],
        ['is_approver', userInfo.approverTo.length > 0],
        ['subscription_status', companyInfo.subscriptionStatus],
        ['plan', companyInfo.plan],
        ['platform', companyInfo.platform],
        ['payment_processor', companyInfo.billing?.paymentProcessor ?? ''],
      ]]])

    } catch(err) {
      // Swallow errors
      window.$crisp.push(['set', 'session:data', ['error', err.message]])
      console.log(err)
    }
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo

      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        if (response.data.getUser.status !== 'ACTIVE') {
          logout({
            onStateChange: props.onStateChange,
            history,
            reduxDispatch: dispatch,
          })
        }
        const companyData = response.data.getCompany
        dispatch(setAuthCompany(companyData))
        const userData = response.data.getUser
        dispatch(setAuthUser(userData))
        const role: UserRole = userData.role === 'Admin' ? 'Admin' : userData.approverTo.length > 0 ? 'Approver' : 'User'

        if (queryParams?.lang && LocaleEnum[queryParams?.lang as string]) {
          dispatch(setLocale(availableLanguages[queryParams.lang as LocaleEnum]))
          try {
            let body = {}
            if (userData.role === 'Admin') {
              body = {
                eventType: 'USER_UPDATED',
                eventGroup: 'USER',
                userId: userData.id,
                name: userData.name,
                locale: queryParams?.lang,
                email: userData.email,
                platform: userData.platform,
                startDate: userData.startDate,
                isAdmin: userData.role === 'Admin',
                isNameLocked: userData?.isNameLocked,
                status: userData.status,
              }
            } else {
              body = {
                eventType: 'USER_UPDATED',
                eventGroup: 'USER',
                userId: userData.id,
                name: userData.name,
                locale: queryParams?.lang,
              }
            }
            delete queryParams.lang
            history.replace({
              search: qs.stringify(queryParams),
            })
            await API.post('CoreEvent', '/core/event', {
              body,
            })
          } catch (error) {
            Sentry.captureException(error)
          }
        } else if (userData.locale) {
          dispatch(setLocale(availableLanguages[userData.locale]))
        }

        getOnboardingChecklist()
        getFeatureFlags(role, history.location.pathname)
        getHelpInfoForUser()

        setUser(userData)
        Sentry.setUser({
          id: userData.id,
          companyId: companyData.id,
        })
        if (userData?.id) {
          identify(userData.id, {
            name: userData.name,
            role: userData.role,
            subscriptionStatus: companyData?.subscriptionStatus || '',
            trialPeriod: companyData?.trialPeriod || '',
            companyId: companyData?.id,
          })
        }
        setCrispSessionInfoForLoggedInUsers(companyData, userData)
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error, numberOfRetry)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange: props.onStateChange,
          history: history,
          reduxDispatch: dispatch,
        })
      } else if (location.pathname !== FrontendUrls.connect) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const RedirectWithParams = ({ to, from }) => {
    return (
      <Route
        path={from}
        render={(props) => <Redirect to={`${to}${props.location.search}`} />}
      />
    )
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        key={currentAppLocale.locale}
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <OnboardingProvider history={history} user={user}>
          <SeoTags
            title='app.meta.title'
            description='app.meta.description'
          />
          <Switch>
            <RedirectWithParams from={FrontendUrls.connect} to={FrontendUrls.signup} />
            <Route exact path={FrontendUrls.signin} render={(routerProps) => <Signin {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.signup} render={(routerProps) => <Signup {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.slackPostInstall} render={(routerProps) => <SlackPostInstallation {...props} {...routerProps} />} />
            <Route exact path={`${FrontendUrls.installSlackBot}/:teamId`} render={(routerProps) => <SlackInstallBot {...props} {...routerProps} />} />
            <Route exact path={`${FrontendUrls.signup}/user/:code`} render={(routerProps) => <UserSignup {...props} {...routerProps} />} />
            <Route exact path={`${FrontendUrls.connect}/campaign`} render={(routerProps) => <Signin {...props} {...routerProps} />} />
            <Route exact path={`${FrontendUrls.externalConnect}/:code`} render={(routerProps) => <ExternalConnect {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.createCompanyStep1} render={(routerProps) => <CreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.createCompanyStep2} render={(routerProps) => <CreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.createCompanyStep3} render={(routerProps) => <CreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyWelcome} render={(routerProps) => <MicrosoftSaaSCreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyDetails} render={(routerProps) => <MicrosoftSaaSCreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyAssignLicenses} render={(routerProps) => <MicrosoftSaaSCreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanySetupBot} render={(routerProps) => <MicrosoftSaaSCreateCompany {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.kats} render={(routerProps) => <SignInAsUser {...props} {...routerProps} />} />
            <Route exact path={FrontendUrls.googlePermissions} render={(routerProps) => <GooglePermissions {...props} {...routerProps} />} />
            {isMicrosoftBillingPayment ?
              <Route path={FrontendUrls.subscription} component={() => <MicrosoftSubscriptionPage onStateChange={props.onStateChange} />} /> :
              <Route path={FrontendUrls.subscription} component={() => <SubscriptionPage onStateChange={props.onStateChange} />} />
            }
            {user &&
              <RestrictedRoute
                path={`${match.url}`}
                component={() => <MainApp {...props} history={history} user={user} />}
              />
            }
          </Switch>
          <Notifications />
          <CompanyUpdatesNotifications />
          <WhatsNewNotifications />
        </OnboardingProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
