import React from 'react'
import { Button, Tag } from 'antd'

import IntlMessages from '../../util/IntlMessages'

import { IAddonsData } from '@vacationtracker/shared/types/automations'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

interface IAddonsCard extends IAddonsData {
  viewMode: boolean
  status: AddonsStatus
  onSelectAddon: (url: string, parent: string, type: string) => void
  addonsPrice: number
  authUserRole?: string
}

const AddonsCard = ({
  viewMode,
  status,
  type,
  parent,
  parentLabel,
  roles,
  url,
  icon,
  description,
  onSelectAddon,
  addonsPrice,
  authUserRole,
}: IAddonsCard) => {
  // authUserRole property is passed to the component only on the MyAddons page
  // ChoiceAutomations page doesn't have this property as user should be able to request any addon
  const canSelectAddon = authUserRole ? roles.includes(authUserRole) : true
  const addonCardExtra = (url: string, parent: string, type: string) => {
    if (url === 'coming-soon') {
      return <Tag style={{ margin: 0 }}><IntlMessages id="app.comingSoon" /></Tag>
    }
    return (
      <Button size="small" type="primary" onClick={() => { onSelectAddon(url, parent, type) }}>
        <IntlMessages id="choiceAutomations.reviewAndBuy" />
      </Button>
    )
  }

  const getAddonStatus = (status: AddonsStatus) => {
    if (status === AddonsStatusEnum.ACTIVE) {
      return <span className="active"><IntlMessages id="addonsPaymentStatus.paymentActive" /></span>
    }
    if (status === AddonsStatusEnum.CANCEL_AT_PERIOD_END) {
      return <span className="cancel-at-period-end"><IntlMessages id="addonsPaymentStatus.activeUntilBillingPeriodEnds" /></span>
    }
    if (status === AddonsStatusEnum.GRANT_PERIOD) {
      return <span className="grant-period"><IntlMessages id="addonsPaymentStatus.grantPeriod" /></span>
    }
    if (status === AddonsStatusEnum.CANCELED) {
      return <span className="canceled"><IntlMessages id="addonsPaymentStatus.canceled" /></span>
    }
    return ''
  }

  return (
    <div
      className={`addons-card ${canSelectAddon ? 'hoverable' : 'disabled'} ${parent} ${status}`}
      onClick={() => canSelectAddon && onSelectAddon(url, parent, type)}
      key={type}
    >
      <div className="addons-card-top-header"><Tag>{parentLabel}</Tag></div>
      <div className="addons-card-header">
        <div className="addons-card-header-icon">
          {icon}
        </div>
        <div className="addons-card-header-extra">
          {canSelectAddon && (viewMode ? 
            <Button size="small" type="primary" onClick={() => { onSelectAddon(url, parent, type) }}>
              <IntlMessages id="app.view" />
            </Button> :
            addonCardExtra(url, parent, type)
          )}
          {viewMode ? 
            getAddonStatus(status) :
            <span>
              <IntlMessages
                id="choiceAutomations.automationPrice"
                values={{
                  price: addonsPrice,
                }}
              />
            </span>
          }
          {roles.length > 0 &&
            <IntlMessages
              id="choiceAutomations.accessibleTo"
              values={{
                approver: (...chunks) => roles.includes('Approver') ? chunks : '',
                admin: (...chunks) => roles.includes('Admin') ? chunks : '',
                and: (...chunks) => roles.length === 2 ? chunks : '',
                only: (...chunks) => roles.length === 1 ? chunks : '',
              }}
            />
          }
        </div>
      </div>
      <div className="addons-card-title"><IntlMessages id={`automations.${type}`} /></div>
      <div className="addons-card-description">
        {description}
      </div>
    </div>
  )
}

export default AddonsCard
