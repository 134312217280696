import { IHoliday } from '@vacationtracker/shared/types/holidays'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { LeaveRequestStatus } from '@vacationtracker/shared/types/leave-request'
import { IGetUserIdsForApproverTo } from '../../types/custom-queries'
import { IHeatMapLeaves } from '../../types/dashboard'

export interface IHeatmapChartProps {
  month: number
  year: number
  onPreviousMonth: () => void
  onNextMonth: () => void
  workingDays: number[]
  holidays: IHeatmapHoliday[]
  leaves: IHeatMapLeaves[]
  isHeatmapLoading: boolean
  hourlyLeaveAccounting: boolean
  role: string
  onCancelLeave: (e) => void
  approverToUsers: IGetUserIdsForApproverTo[]
  locale?: LocaleEnum
}

export interface IHeatmapChartMonthProps {
  month: number
  year: number
  workingDays: number[]
  data: HeatmapEntry[][]
  onSelect: (day: number) => void
}

export interface IHeatmapData {
  data: number[]
  label: string
  backgroundColor: string
}

export enum HeatmapEventType {
  holiday = 'holiday',
  leave = 'leave'
}

export interface IHeatmapHoliday extends IHoliday {
  type: HeatmapEventType.holiday
  locationName: string
  locationId: string
}

export interface IHeatmapLeave {
  type: HeatmapEventType.leave
  name: string
  color: string
  userName: string
  userId: string
  userImage: string
  approverName: string
  autoApproved: boolean
  partDayStartHour?: number
  partDayEndHour?: number
  reason: string
  id: string
  startDate: string
  endDate: string
  isPartDay: boolean
  status: LeaveRequestStatus
  workingDays: number
  hideLeaveType?: boolean
  approvedBySubstituteApprover?:boolean
  substituteApproverId?: boolean
}

export type HeatmapEntry = IHeatmapHoliday | IHeatmapLeave

export interface IDaysData {
  [day: number]: HeatmapEntry[]
}
