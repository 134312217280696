import React from 'react'
import { Button, Form, Typography, Row, Col, Divider, Descriptions } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { PaymentMethodTypeEnum } from '@vacationtracker/shared/types/payment-information'

const { Title, Text } = Typography

interface IPaymentInformation {
  customerPortalLink?: string
  card: string
  expirationDate: string
  type?: PaymentMethodTypeEnum
}

const PaymentInformation = ({
  customerPortalLink,
  card,
  expirationDate,
  type = PaymentMethodTypeEnum.card,
}: IPaymentInformation): React.ReactElement => {

  return (
    <>
      <Divider />
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="billing.paymentInformation" />
          </Title>
        </Col>

        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          {type === PaymentMethodTypeEnum.card &&
            <Descriptions className="info-box" layout='horizontal' column={1} bordered>
              <Descriptions.Item label={<IntlMessages id="app.creditCard" />}>
                <Text strong>**** **** **** {card}</Text>
              </Descriptions.Item>

              <Descriptions.Item label={<IntlMessages id="components.paymentInformation.expiryDate" />}>
                <Text strong>{expirationDate}</Text>
              </Descriptions.Item>
            </Descriptions>
          }

          {type === PaymentMethodTypeEnum.link && <Form.Item label={<IntlMessages id="billing.paymentMethod" />}>
            <Text strong italic>
              <IntlMessages
                id="billing.linkPaymentMethod"
              />
            </Text>
          </Form.Item>}
          <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
            {customerPortalLink && (<Button className="button-right" size="large" type="ghost" href={customerPortalLink} target="_blank" rel="noopener noreferrer">
              <IntlMessages id="app.edit" />
            </Button>)}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default PaymentInformation
