import { IError } from './types'

export const UserExistsException: IError = {
  code: 'UserExistsException',
  message: 'Selected user already exists in Vacation Tracker',
}

export const UserNotFound: IError = {
  code: 'UserNotFound',
  message: 'User not found',
}

export const UserNotActive: IError = {
  code: 'UserNotActive',
  message: 'User no longer active',
}

export const EmailNotValid: IError = {
  code: 'EmailNotValid',
  message: 'Email not valid',
}

export const EmailPlatformUserInactive: IError = {
  code: 'EmailPlatformUserInactive',
  message: 'It seems that your status is set to inactive. Please, contact administrator to activate your status.',
}

export const AccessForbidden: IError = {
  code: 'AccessForbidden',
  message: 'Insufficient privilege - You shall not pass!',
}
