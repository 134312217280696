// TODO This is not notification any more - rename everything, and remove v1 types along with the dynamo table
import { IEventBridgeMessage } from '@vacationtracker/shared/types/event-bridge'
import { ICoreEvent } from './core-event'
import { LeaveRequestErrorNotificationTypeEnum } from './leave-request'
import { ToilRequestErrorTypeEnum } from './toil-request'
import { SubscriptionPlan } from './company'
import { SubscriptionPeriod } from './billing'

export type Frequency = 'DAILY' | 'WEEKLY'
export type NotificationBelongsTo = 'COMPANY' | 'TEAM' | 'LOCATION'

export enum NotificationType {
  announceCompany = 'announce-company',
  announceUser = 'announce-user',
  personalLeaveRequestAdded = 'personal#leave-request-added',
  personalAdminAddedAsApprover = 'personal#admin-added-as-approver',
  personalSubstituteSelected = 'personal#substitute-selected',
  personalSubstituteUpdated = 'personal#substitute-updated',
  personalSubstituteDeleted = 'personal#substitute-deleted',
  personalLeaveRequestApproved = 'personal#leave-request-approved',
  personalLeaveRequestCancelled = 'personal#leave-request-cancelled',
  personalLeaveRequestCancelledByApprover = 'personal#leave-request-cancelled-by-approver',
  personalLeaveRequestCreated = 'personal#leave-request-created',
  personalLeaveRequestDenied = 'personal#leave-request-denied',
  personalLeaveRequestEdited = 'personal#leave-request-edited',
  personalLeaveRequestEditedByApprover = 'personal#leave-request-edited-by-approver',
  personalLeaveRequestReminder = 'personal#leave-request-reminder',
  personalLeaveRequestExpiration = 'personal#leave-request-expiration',
  personalOutOfOfficeReminder = 'personal#out-of-office-reminder-email',
  personalLeaveRequestError = 'personal#leave-request-error',
  personalLeaveRequestMoreThanFullDay = 'personal#leave-request-more-than-full-day',
  personalLeaveRequestNegativeBalanceError = 'personal#leave-request-negative-balance-error',
  personalBlackoutPeriodError = 'personal#blackout-period-error',
  personalLeaveRequestOverlapError = 'personal#leave-request-overlap-error',
  personalLeaveRequestZeroDaysError = 'personal#leave-request-zero-days-error',
  personalLeaveRequestAddedFr = 'personal#leave-request-added#fr',
  personalAdminAddedAsApproverFr = 'personal#admin-added-as-approver#fr',
  personalSubstituteSelectedFr = 'personal#substitute-selected#fr',
  personalSubstituteUpdatedFr = 'personal#substitute-updated#fr',
  personalSubstituteDeletedFr = 'personal#substitute-deleted#fr',
  personalLeaveRequestApprovedFr = 'personal#leave-request-approved#fr',
  personalLeaveRequestCancelledFr = 'personal#leave-request-cancelled#fr',
  personalLeaveRequestCancelledByApproverFr = 'personal#leave-request-cancelled-by-approver#fr',
  personalLeaveRequestCreatedFr = 'personal#leave-request-created#fr',
  personalLeaveRequestDeniedFr = 'personal#leave-request-denied#fr',
  personalLeaveRequestEditedFr = 'personal#leave-request-edited#fr',
  personalLeaveRequestEditedByApproverFr = 'personal#leave-request-edited-by-approver#fr',
  personalLeaveRequestReminderFr = 'personal#leave-request-reminder#fr',
  personalLeaveRequestExpirationFr = 'personal#leave-request-expiration#fr',
  personalOutOfOfficeReminderFr = 'personal#out-of-office-reminder-email#fr',
  personalToilRequestAdded = 'personal#toil-request-added',
  personalToilRequestApproved = 'personal#toil-request-approved',
  personalToilRequestCancelled = 'personal#toil-request-cancelled',
  personalToilRequestCancelledByApprover = 'personal#toil-request-cancelled-by-approver',
  personalToilRequestCreated = 'personal#toil-request-created',
  personalToilRequestDenied = 'personal#toil-request-denied',
  personalToilRequestEdited = 'personal#toil-request-edited',
  personalToilRequestEditedByApprover = 'personal#toil-request-edited-by-approver',
  personalToilRequestError = 'personal#toil-request-error',
  personalToilRequestReminder = 'personal#toil-request-reminder',
  personalToilRequestExpiration = 'personal#toil-request-expiration',
  personalToilRequestAddedFr = 'personal#toil-request-added#fr',
  personalToilRequestApprovedFr = 'personal#toil-request-approved#fr',
  personalToilRequestCancelledFr = 'personal#toil-request-cancelled#fr',
  personalToilRequestCancelledByApproverFr = 'personal#toil-request-cancelled-by-approver#fr',
  personalToilRequestCreatedFr = 'personal#toil-request-created#fr',
  personalToilRequestDeniedFr = 'personal#toil-request-denied#fr',
  personalToilRequestEditedFr = 'personal#toil-request-edited#fr',
  personalToilRequestEditedByApproverFr = 'personal#toil-request-edited-by-approver#fr',
  personalToilRequestReminderFr = 'personal#toil-request-reminder#fr',
  personalToilRequestExpirationFr = 'personal#toil-request-expiration#fr',
  leaveBalanceReport = 'leaveBalanceReport',
  leaveHistoryReport = 'leaveHistoryReport',
  monthlyLeaveBalanceReport = 'monthlyLeaveBalanceReport',
  receipt = 'receipt',
  notificationV2 = 'notification-v2',
  notificationV2Fr = 'notification-v2#fr',
  invitation = 'invitation',
  invitationFr = 'invitation#fr',
  reconnectGoogleCalendar = 'google-calendar-reconnect',
  reconnectGoogleCalendarFr = 'google-calendar-reconnect#fr',
  reconnectSharedGoogleCalendar = 'google-shared-calendar-reconnect',
  reconnectSharedGoogleCalendarFr = 'google-shared-calendar-reconnect#fr',
  stripePriceChange = 'stripe-price-change',
  stripePriceChangeFr = 'stripe-price-change#fr',
  'announce-company' = announceCompany,
  'announce-user' = announceUser,
  'personal#leave-request-added' = personalLeaveRequestAdded,
  'personal#admin-added-as-approver' = personalAdminAddedAsApprover,
  'personal#substitute-selected' = personalSubstituteSelected,
  'personal#substitute-updated' = personalSubstituteUpdated,
  'personal#substitute-deleted' = personalSubstituteDeleted,
  'personal#leave-request-approved' = personalLeaveRequestApproved,
  'personal#leave-request-cancelled' = personalLeaveRequestCancelled,
  'personal#leave-request-cancelled-by-approver' = personalLeaveRequestCancelledByApprover,
  'personal#leave-request-created' = personalLeaveRequestCreated,
  'personal#leave-request-reminder' = personalLeaveRequestReminder,
  'personal#leave-request-expiration' = personalLeaveRequestExpiration,
  'personal#leave-request-denied' = personalLeaveRequestDenied,
  'personal#leave-request-edited' = personalLeaveRequestEdited,
  'personal#leave-request-edited-by-approver' = personalLeaveRequestEditedByApprover,
  'personal#leave-request-error' = personalLeaveRequestError,
  'personal#leave-request-more-than-full-day' = personalLeaveRequestMoreThanFullDay,
  'personal#leave-request-negative-balance-error' = personalLeaveRequestNegativeBalanceError,
  'personal#blackout-period-error' = personalBlackoutPeriodError,
  'personal#leave-request-overlap-error' = personalLeaveRequestOverlapError,
  'personal#leave-request-zero-days-error' = personalLeaveRequestZeroDaysError,
  'personal#leave-request-added#fr' = personalLeaveRequestAddedFr,
  'personal#admin-added-as-approver#fr' = personalAdminAddedAsApproverFr,
  'personal#substitute-selected#fr' = personalSubstituteSelectedFr,
  'personal#substitute-updated#fr' = personalSubstituteUpdatedFr,
  'personal#substitute-deleted#fr' = personalSubstituteDeletedFr,
  'personal#leave-request-approved#fr' = personalLeaveRequestApprovedFr,
  'personal#leave-request-cancelled#fr' = personalLeaveRequestCancelledFr,
  'personal#leave-request-cancelled-by-approver#fr' = personalLeaveRequestCancelledByApproverFr,
  'personal#leave-request-created#fr' = personalLeaveRequestCreatedFr,
  'personal#leave-request-reminder#fr' = personalLeaveRequestReminderFr,
  'personal#leave-request-expiration#fr' = personalLeaveRequestExpirationFr,
  'personal#leave-request-denied#fr' = personalLeaveRequestDeniedFr,
  'personal#leave-request-edited#fr' = personalLeaveRequestEditedFr,
  'personal#leave-request-edited-by-approver#fr' = personalLeaveRequestEditedByApproverFr,
  'personal#toil-request-added' = personalToilRequestAdded,
  'personal#toil-request-approved' = personalToilRequestApproved,
  'personal#toil-request-cancelled' = personalToilRequestCancelled,
  'personal#toi-request-cancelled-by-approver' = personalToilRequestCancelledByApprover,
  'personal#toil-request-created' = personalToilRequestCreated,
  'personal#toil-request-denied' = personalToilRequestDenied,
  'personal#toil-request-edited' = personalToilRequestEdited,
  'personal#toil-request-edited-by-approver' = personalToilRequestEditedByApprover,
  'personal#toil-request-error' = personalToilRequestError,
  'personal#toil-request-reminder' = personalToilRequestReminder,
  'personal#toil-request-expiration' = personalToilRequestExpiration,
  'personal#toil-request-added#fr' = personalToilRequestAddedFr,
  'personal#toil-request-approved#fr' = personalToilRequestApprovedFr,
  'personal#toil-request-cancelled#fr' = personalToilRequestCancelledFr,
  'personal#toil-request-cancelled-by-approver#fr' = personalToilRequestCancelledByApproverFr,
  'personal#toil-request-created#fr' = personalToilRequestCreatedFr,
  'personal#toil-request-denied#fr' = personalToilRequestDeniedFr,
  'personal#toil-request-edited#fr' = personalToilRequestEditedFr,
  'personal#toil-request-edited-by-approver#fr' = personalToilRequestEditedByApproverFr,
  'personal#toil-request-reminder#fr' = personalToilRequestReminderFr,
  'personal#toil-request-expiration#fr' = personalToilRequestExpirationFr,
  'notification-v2' = notificationV2,
  'notification-v2#fr' = notificationV2Fr,
  'invitation#fr' = invitationFr,
  'personal#out-of-office-reminder-email' = 'personal#out-of-office-reminder-email',
  'personal#out-of-office-reminder-email#fr' = 'personal#out-of-office-reminder-email#fr',
  'google-calendar-reconnect' = reconnectGoogleCalendar,
  'google-calendar-reconnect#fr' = reconnectGoogleCalendarFr,
  'google-shared-calendar-reconnect' = reconnectSharedGoogleCalendar,
  'google-shared-calendar-reconnect#fr' = reconnectSharedGoogleCalendarFr,
  'stripe-price-change' = stripePriceChange,
  'stripe-price-change#fr' = stripePriceChangeFr,
}

export enum OOONotificationType {
  oufOfOfficeReminderSlack = 'out-of-office-reminder-slack',
  oufOfOfficeReminderMicrosoft = 'out-of-office-reminder-microsoft',
}
export type OufOfOfficeReminderEmail = 'personal#out-of-office-reminder-email'

export enum ReconnectNotificationType {
  reconnectCalendarForSlack = 'reconnect-calendar-for-slack',
  reconnectOutlookCalendar = 'reconnect-outlook-calendar',
  reconnectSharedOutlookCalendar = 'reconnect-shared-outlook-calendar',
  reconnectSharedCalendarForSlack = 'reconnect-shared-calendar-for-slack',
}
export type ReconnectGoogleNotificationType = 'reconnect-google-calendar'
export type ReconnectSharedGoogleNotificationType = 'reconnect-shared-google-calendar'


export type BaseLeaveRequestPersonalNotification = {
  notificationType: NotificationType
  companyId: string
  userId: string
  teamId: string
  leaveRequestId: string
  leaveTypeId: string
  source: string
  creator: string
}

type BaseToilRequestPersonalNotification = {
  notificationType: NotificationType
  companyId: string
  userId: string
  teamId: string
  toilRequestId: string
  leaveTypeId: string
  source: string
  creator: string
}

export type ILeaveRequestCreatedPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCreated
  reason?: string
}

export type ILeaveRequestCreatedPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCreatedFr
  reason?: string
}

export type ILeaveRequestZeroDaysErrorNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestZeroDaysError
}

export type ILeaveRequestOverlapErrorNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestOverlapError
}

export type ILeaveRequestMoreThanFullDayErrorNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestMoreThanFullDay
}

export type ILeaveRequestNegativeBalanceErrorNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestNegativeBalanceError
}

export type ILeaveRequestCancelledPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCancelled
  previousStatus: string
}

export type ILeaveRequestCancelledPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCancelledFr
  previousStatus: string
}

export type ILeaveRequestCancelledByApproverPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCancelledByApprover
}

export type ILeaveRequestCancelledByApproverPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestCancelledByApproverFr
}

export type ILeaveRequestAddedPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestAdded
}

export type ILeaveRequestAddedPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestAddedFr
}

export type ILeaveRequestApprovedPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestApproved
  autoApproved?: boolean
}

export type ILeaveRequestApprovedPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestApprovedFr
  autoApproved?: boolean
}

export type ILeaveRequestDeniedPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestDenied
  reason?: string
}

export type ILeaveRequestDeniedPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestDeniedFr
  reason?: string
}

export type ILeaveRequestEditedPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestEdited
  previousStatus: string
  reason?: string
}

export type ILeaveRequestEditedPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestEditedFr
  previousStatus: string
  reason?: string
}

export type ILeaveRequestErrorPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestError
  errorType?: LeaveRequestErrorNotificationTypeEnum
}

export type ILeaveRequestEditedByApproverPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestEditedByApprover
  previousStatus: string
  reason?: string
}

export type ILeaveRequestEditedByApproverPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestEditedByApproverFr
  previousStatus: string
  reason?: string
}

export type IToilRequestCreatedPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCreated
  reason?: string
}

export type IToilRequestCreatedPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCreatedFr
  reason?: string
}

export type IToilRequestCancelledPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCancelled
  previousStatus: string
}

export type IToilRequestCancelledPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCancelledFr
  previousStatus: string
}

export type IToilRequestCancelledByApproverPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCancelledByApprover
}

export type IToilRequestCancelledByApproverPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestCancelledByApproverFr
}

export type IToilRequestAddedPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestAdded
}

export type IToilRequestAddedPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestAddedFr
}

export type IToilRequestApprovedPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestApproved
}

export type IToilRequestApprovedPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestApprovedFr
}

export type IToilRequestDeniedPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestDenied
  reason?: string
}

export type IToilRequestDeniedPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestDeniedFr
  reason?: string
}

export type IToilRequestEditedPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestEdited
  previousStatus: string
  reason?: string
}

export type IToilRequestEditedPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestEditedFr
  previousStatus: string
  reason?: string
}

export type IToilRequestErrorPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestError
  errorType?: ToilRequestErrorTypeEnum
}

export type IToilRequestEditedByApproverPersonalNotification = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestEditedByApprover
  previousStatus: string
  reason?: string
}

export type IToilRequestEditedByApproverPersonalNotificationFr = BaseToilRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestEditedByApproverFr
  previousStatus: string
  reason?: string
}
interface IBaseReconnectCalendarNotification {
  notificationType: ReconnectNotificationType.reconnectOutlookCalendar
  | ReconnectNotificationType.reconnectCalendarForSlack
  | ReconnectNotificationType.reconnectSharedOutlookCalendar
  | ReconnectNotificationType.reconnectSharedCalendarForSlack
  | ReconnectGoogleNotificationType
  | NotificationType
  userId: string
  companyId: string
  leaveRequestId: string
  creator?: string
}

export type IReconnectGoogleCalendarNotification = IBaseReconnectCalendarNotification & {
  notificationType: NotificationType.reconnectGoogleCalendar
}

export type IReconnectGoogleCalendarNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: NotificationType.reconnectGoogleCalendarFr
}

export type IReconnectGoogleSharedCalendarNotification = IBaseReconnectCalendarNotification & {
  notificationType: NotificationType.reconnectSharedGoogleCalendar
}

export type IReconnectGoogleSharedCalendarNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: NotificationType.reconnectSharedGoogleCalendarFr
}

export type IReconnectOutlookCalendarNotification = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectOutlookCalendar
}

export type IReconnectOutlookCalendarNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectOutlookCalendar
}

export type IReconnectCalendarForSlackNotification = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectCalendarForSlack
}

export type IReconnectCalendarForSlackNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectCalendarForSlack
}

export type IReconnectOutlookSharedCalendarNotification = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectSharedOutlookCalendar
}

export type IReconnectOutlookSharedCalendarNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectSharedOutlookCalendar
}

export type IReconnectSharedCalendarForSlackNotification = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectSharedCalendarForSlack
}

export type IReconnectSharedCalendarForSlackNotificationFr = IBaseReconnectCalendarNotification & {
  notificationType: ReconnectNotificationType.reconnectSharedCalendarForSlack
}


interface IBaseOutOfOfficeReminderNotification {
  notificationType: OOONotificationType.oufOfOfficeReminderMicrosoft
  | OOONotificationType.oufOfOfficeReminderSlack
  | OufOfOfficeReminderEmail
  | NotificationType
  userId: string
  companyId: string
  timezone: string
  leaveRequestId: string
  creator?: string
}

export interface IOutOfOfficeReminderPersonalNotificationMicrosoft extends IBaseOutOfOfficeReminderNotification {
  notificationType: OOONotificationType.oufOfOfficeReminderMicrosoft
}

export interface  IOutOfOfficeReminderPersonalNotificationMicrosoftFr  extends IBaseOutOfOfficeReminderNotification {
  notificationType: OOONotificationType.oufOfOfficeReminderMicrosoft
}

export interface IOutOfOfficeReminderPersonalNotificationSlack extends IBaseOutOfOfficeReminderNotification {
  notificationType: OOONotificationType.oufOfOfficeReminderSlack
}

export interface IOutOfOfficeReminderPersonalNotificationSlackFr extends IBaseOutOfOfficeReminderNotification {
  notificationType: OOONotificationType.oufOfOfficeReminderSlack
}

export interface IOutOfOfficeReminderPersonalNotificationEmail extends IBaseOutOfOfficeReminderNotification {
  notificationType: OufOfOfficeReminderEmail
}

export interface IOutOfOfficeReminderPersonalNotificationEmailFr extends IBaseOutOfOfficeReminderNotification {
  notificationType: OufOfOfficeReminderEmail
}

export type ILeaveRequestBlackoutPeriodErrorPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalBlackoutPeriodError
  message: string
  startDate: string
  endDate: string
}

export type ILeaveRequestReminderPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestReminder
  reminderNo: number
  approverId: string
}

export type ILeaveRequestReminderPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestReminderFr
  reminderNo: number
}

export type ILeaveRequestExpirationPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestExpiration
}

export type ILeaveRequestExpirationPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalLeaveRequestExpirationFr
}

export type IToilRequestReminderPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestReminder
  reminderNo: number
  approverId: string
}

export type IToilRequestReminderPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestReminderFr
  reminderNo: number
}

export type IToilRequestExpirationPersonalNotification = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestExpiration
}

export type IToilRequestExpirationPersonalNotificationFr = BaseLeaveRequestPersonalNotification & {
  notificationType: NotificationType.personalToilRequestExpirationFr
}

export type IStripePriceChangePersonalNotification = {
  notificationType: NotificationType.stripePriceChange
  companyId: string
  name: string
  plan: SubscriptionPlan
  period: SubscriptionPeriod
  date: string
  price: string
  mails: string[]
}

export type IPersonalNotificationPayload =
  | ILeaveRequestCreatedPersonalNotification
  | ILeaveRequestCreatedPersonalNotificationFr
  | ILeaveRequestReminderPersonalNotification
  | ILeaveRequestReminderPersonalNotificationFr
  | ILeaveRequestExpirationPersonalNotification
  | ILeaveRequestExpirationPersonalNotificationFr
  | ILeaveRequestApprovedPersonalNotification
  | ILeaveRequestApprovedPersonalNotificationFr
  | ILeaveRequestDeniedPersonalNotification
  | ILeaveRequestDeniedPersonalNotificationFr
  | ILeaveRequestCancelledPersonalNotification
  | ILeaveRequestCancelledPersonalNotificationFr
  | ILeaveRequestCancelledByApproverPersonalNotification
  | ILeaveRequestCancelledByApproverPersonalNotificationFr
  | ILeaveRequestEditedByApproverPersonalNotification
  | ILeaveRequestEditedByApproverPersonalNotificationFr
  | ILeaveRequestOverlapErrorNotification
  | ILeaveRequestMoreThanFullDayErrorNotification
  | ILeaveRequestZeroDaysErrorNotification
  | ILeaveRequestNegativeBalanceErrorNotification
  | ILeaveRequestAddedPersonalNotification
  | ILeaveRequestAddedPersonalNotificationFr
  | ILeaveRequestEditedPersonalNotification
  | ILeaveRequestEditedPersonalNotificationFr
  | ILeaveRequestErrorPersonalNotification
  | IToilRequestCreatedPersonalNotification
  | IToilRequestCreatedPersonalNotificationFr
  | IToilRequestApprovedPersonalNotification
  | IToilRequestApprovedPersonalNotificationFr
  | IToilRequestDeniedPersonalNotification
  | IToilRequestDeniedPersonalNotificationFr
  | IToilRequestCancelledPersonalNotification
  | IToilRequestCancelledPersonalNotificationFr
  | IToilRequestCancelledByApproverPersonalNotification
  | IToilRequestCancelledByApproverPersonalNotificationFr
  | IToilRequestEditedByApproverPersonalNotification
  | IToilRequestEditedByApproverPersonalNotificationFr
  | IToilRequestAddedPersonalNotification
  | IToilRequestAddedPersonalNotificationFr
  | IToilRequestEditedPersonalNotification
  | IToilRequestEditedPersonalNotificationFr
  | IToilRequestErrorPersonalNotification
  | IToilRequestReminderPersonalNotification
  | IToilRequestReminderPersonalNotificationFr
  | IToilRequestExpirationPersonalNotification
  | IToilRequestExpirationPersonalNotificationFr
  | IOutOfOfficeReminderPersonalNotificationMicrosoft
  | IOutOfOfficeReminderPersonalNotificationMicrosoftFr
  | IOutOfOfficeReminderPersonalNotificationSlack
  | IOutOfOfficeReminderPersonalNotificationSlackFr
  | IOutOfOfficeReminderPersonalNotificationEmail
  | IOutOfOfficeReminderPersonalNotificationEmailFr
  | ILeaveRequestBlackoutPeriodErrorPersonalNotification
  | IReconnectGoogleCalendarNotification
  | IReconnectGoogleCalendarNotificationFr
  | IReconnectOutlookCalendarNotification
  | IReconnectCalendarForSlackNotification
  | IReconnectGoogleSharedCalendarNotification
  | IReconnectGoogleSharedCalendarNotificationFr
  | IReconnectOutlookSharedCalendarNotification
  | IReconnectOutlookSharedCalendarNotificationFr
  | IReconnectSharedCalendarForSlackNotification
  | IReconnectSharedCalendarForSlackNotificationFr
  // | IStripePriceChangePersonalNotification

export type INotificationPayload = IPersonalNotificationPayload

type INotificationMeta = {
  platform: 'microsoft' | 'slack'
}

export type INotificationEvent = IEventBridgeMessage<INotificationMeta, INotificationPayload> & {
  type: 'notification'
}

export interface ISubscriptionEventMessage extends ICoreEvent {
  PK: string
  SK: string
  LSI1: string
  LSI2: string
  [key: string]: string | number | boolean | undefined
}

export interface ISubscriptionEvent {
  code: string
  message: string
  errorCode: string
  data: string
  originalEvent: string
  result?: string
}
