import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, notification, Progress, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { WindowsFilled, CaretLeftOutlined } from '@ant-design/icons'
import find from 'lodash/find'
import filter from 'lodash/filter'
import differenceWith from 'lodash/differenceWith'

import { API, graphqlOperation } from 'aws-amplify'
import { getDataForManageLicences } from '../../../graphql/custom-queries'

import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import ManageAssignLicenses from '../../../components/manage-assign-licenses'

import { IMicrosoftUser } from '../../../types/microsoft'
import { IGetDataForManageLicences } from '../../../types/custom-queries'
import { IData } from '../../../types/data'
import { ParentComponentEnum } from '../../../components/manage-assign-licenses/types'


if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID is required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)

const { Paragraph } = Typography

const ManageMicrosoftLicences = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser: { id, name, email } } = useAppSelector(selectAuthUserSlice)
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { eventsNotifications, setEventsNotifications } = useContext(notificationStore)

  const [numberOflicences, setNumberOfLicences] = useState(authCompany?.billing?.quantity || 25)
  const [selectedUsers, setSelectedUsers] = useState<IMicrosoftUser[]>([])
  const [currentActiveUsers, setCurrentActiveUsers] = useState<IMicrosoftUser[]>([])
  const [importAllUsers, setImportAllUsers] = useState(false)
  const [totalUsers, setTotalUsers] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [loginInProgress, setLoginInProgress] = useState(false)
  const [showManageAssignLicenses, setShowManageAssignLicenses] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertErrorTitle, setAlertErrorTitle] = useState<React.ReactElement>()
  const [alertErrorDescription, setAlertErrorDescription] = useState<React.ReactElement>()
  const [alertErrorType, setAlertErrorType] = useState<'error' | 'success' | 'info' | 'warning' | undefined>('error')
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const [assignLicensesLoading, setAssignLicensesLoading] = useState(false)

  useEffect(() => {
    fetchActiveUsersInSystem()
    checkMicrosoftToken()
  }, [])

  useEffect(() => {
    if (eventsNotifications[correlationId]) {
      setCorrelationExist(true)
    }
    if (!eventsNotifications[correlationId] && correlationExist) {
      setCorrelationExist(false)
      setAssignLicensesLoading(false)
      setCorrelationId('')
      setTimeout(() => {
        fetchActiveUsersInSystem()
      }, 500)
    }
  }, [eventsNotifications])

  const checkMicrosoftToken = async () => {
    try {
      msAuth.getTokens()
      const msTokens = msAuth.getTokens()
      await handleTotalUsers(msTokens.accessToken),
      setShowManageAssignLicenses(true)
    } catch (error) {
      setIsLoading(false)
      setShowLoginForm(true)
    }
  }

  const handleTotalUsers = async (token: string) => {
    const totalUsers = await msAuth.getTotalUsers(token)
    setTotalUsers(totalUsers)
  }

  const fetchActiveUsersInSystem = async () => {
    const response = await API.graphql(graphqlOperation(getDataForManageLicences, { status: 'ACTIVE' })) as IData<IGetDataForManageLicences>
    setNumberOfLicences(response.data.getCompany.billing.quantity)
    const selectedUsers = response.data.getUsersByStatus.map(user => {
      if (user.id !== id) {
        return {
          id: user.id,
          displayName: user.name,
          mail: user.email,
          announce: 'none',
          isAdmin: user.isAdmin,
        }
      }
    }).filter(Boolean) as IMicrosoftUser[]
    setSelectedUsers(selectedUsers)
    setCurrentActiveUsers(selectedUsers)
    setIsLoading(false)
  }

  const wrongMicrosoftAccount = () => {
    msAuth.removeTokens()
    setAlertErrorTitle(<IntlMessages id="error.microsoft.wrongAccount" />)
    setAlertErrorDescription(() => {
      return (<>
        <Paragraph><IntlMessages id="errors.microsoft.wrongMicrosoftAccountDescription" values={{ email }} /></Paragraph>
        <Button onClick={() => connectWithMicrosoft}><IntlMessages id="error.microsoft.logInWithDifferentAccount" /></Button>
      </>)
    })
    setAlertErrorType('error')
    setShowLoginForm(false)
    setShowAlertError(true)
  }

  const connectWithMicrosoft = (interactionRequired = false) => {
    setLoginInProgress(true)
    setShowAlertError(false)
    // This requires promises, not async/await because window.open requires sync not async flow
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'], interactionRequired)
      .then(([tokens, msUser, tenantId]) => {
        if (tenantId !== authCompany?.msTeamId|| `microsoft-${msUser.id}` !== id) {
          wrongMicrosoftAccount()
          return
        }
        handleTotalUsers(tokens.accessToken)
        setIsLoading(false)
        setShowLoginForm(false)
        setShowManageAssignLicenses(true)
      })
      .catch((error) => {
        console.log('ERROR', error)
        setAlertErrorTitle(<IntlMessages id="error.microsoft.microsoftPermissionsNotGranted" />)
        setAlertErrorDescription(() => {
          return (<>
            <Paragraph><IntlMessages id="errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription" /></Paragraph>
            <Button onClick={() => connectWithMicrosoft}><IntlMessages id="app.logInWithMicrosoft" /></Button>
          </>)
        })
        setAlertErrorType('error')
        setShowLoginForm(false)
        setShowAlertError(true)
      })
  }

  const assignLicenses = async () => {
    try {
      const newUsers = differenceWith(selectedUsers, currentActiveUsers, (a,b) => a.id === b.id && a.isAdmin === b.isAdmin)
      const removedUsers = differenceWith(currentActiveUsers, selectedUsers, (a,b) => a.id === b.id && a.isAdmin === b.isAdmin)
      const changedUsers = [...newUsers, ...removedUsers]

      if (changedUsers.length === 0) {
        return
      }
      setAssignLicensesLoading(true)

      await msAuth.getUser()
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'ASSIGN_LICENSES',
          eventGroup: 'BULK_ACTIONS',
          activeUsers: selectedUsers.map(msUser => {
            return {
              platformUserId: msUser.id.replace('microsoft-', ''),
              isAdmin: msUser.isAdmin,
              announce: msUser.announce,
            }
          }),
          token: msAuth.getTokens().accessToken,
          totalUsers: totalUsers,
        },
      })
      setCorrelationId(response.correlationId)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'manageMicrosoftLicenses.weAreAssignLicenses' }),
        description: <Progress percent={0} size="small" />,
        duration: 0,
      })

      setEventsNotifications({
        ...eventsNotifications,
        [response.correlationId]: {
          eventType: 'USER_STATUS_CHANGED',
          payload: {
            users: changedUsers.map(user => user.id),
            totalUsers: changedUsers.length,
            type: 'assignLicenses',
          },
        },
      })
    } catch (error) {
      setAssignLicensesLoading(false)
      errorNotificationHandler(error)
    }
  }

  const errorNotificationHandler = (error) => {
    const errorDescription = error.response?.data ? error.response?.data : error.message ? error.message : JSON.stringify(error)
    const isTokenError = error?.response?.data?.error?.code === 'InvalidAuthenticationToken' || errorDescription === 'no_tokens'
    const errorMessageDescription = isTokenError ? formatMessage({ id: 'error.token.description' }, { platform: 'Microsoft' } ) : error.message
    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError &&
        <Button onClick={() => {
          connectWithMicrosoft()
          notification.close('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, { platform: 'Microsoft' } )}
        </Button>,
      duration: 0,
    })
  }

  const onSelectUser = (user) => {
    const selectedUser = find(selectedUsers, u => u.id === user.id)
    const newSelectedUsers = selectedUser ? filter(selectedUsers, u => u.id !== selectedUser.id) : [...selectedUsers, {...user, announce: 'chat'}]

    setImportAllUsers(newSelectedUsers.length === totalUsers ? true : false)
    setSelectedUsers(newSelectedUsers)
  }

  const handleChangeRole = (role: 'admin' | 'user', userId: string) => {
    const selectedUser = find(selectedUsers, user => user.id === userId)
    if (selectedUser) {
      const newSelectedUsers = selectedUsers.map(user => {
        if (user.id === userId) {
          return {...user, isAdmin: role === 'admin' ? true : false}
        }
        return user
      })
      setSelectedUsers(newSelectedUsers)
    }
  }

  const handleChangeAnnounce = (announce: 'none' | 'email' | 'chat', userId: string) => {
    const selectedUser = find(selectedUsers, user => user.id === userId)
    if (selectedUser) {
      const newSelectedUsers = selectedUsers.map(user => {
        if (user.id === userId) {
          user.announce = announce
          return {...user, announce}
        }
        return user
      })
      setSelectedUsers(newSelectedUsers)
    }
  }

  const handleBulkSelect = (importAllUsers: boolean, userList: IMicrosoftUser[]) => {
    const newSelectedUsers = importAllUsers ? userList : []
    setImportAllUsers(importAllUsers && userList.length === totalUsers ? true : false)
    setSelectedUsers(newSelectedUsers)
  }

  const handleBulkAnnounceAction = (announce: 'none' | 'email' | 'chat') => {
    setSelectedUsers(user => {
      return user.map(user => {
        return {
          ...user,
          announce,
        }
      })
    })
  }


  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span>
            <IntlMessages id="app.microsoft.manageLicenses" />
            <Button onClick={() => {history.push('/app/users')}} style={{ marginLeft: 10 }}>
              <CaretLeftOutlined style={{ marginRight: 5 }} />
              <IntlMessages id={'app.back'} />
            </Button>
          </span>
        </div>
        <div className="main-content-header-breadcrumb"></div>
      </div>
      <div className="main-content-body">
        {isLoading && <CircularProgress style={{ height: 'calc(100vh - 92px)' }} /> }

        {showLoginForm &&
          <>
            <Paragraph>
              <IntlMessages id="microsoft.manageLicenses.loginMicrosoft"  />
            </Paragraph>
            <Paragraph>
              <Button icon={<WindowsFilled />} size="large" type="primary" disabled={loginInProgress} loading={loginInProgress} onClick={() => connectWithMicrosoft()}>
                <IntlMessages id="app.logInWithMicrosoft" />
              </Button>
            </Paragraph>
            <Paragraph>
              <IntlMessages id="microsoft.manageLicenses.loginMicrosoftInfo"  />
            </Paragraph>
          </>
        }

        {showAlertError && <>
          <Alert
            message={alertErrorTitle}
            description={alertErrorDescription}
            type={alertErrorType}
            showIcon
          />
        </>}

        {showManageAssignLicenses &&
          <ManageAssignLicenses
            numberOflicenses={numberOflicences}
            msAuth={msAuth}
            onSelectUser={onSelectUser}
            totalUsersProp={totalUsers}
            curentUser={{
              id: id,
              mail: email,
              displayName: name,
            }}
            importAllUsers={importAllUsers}
            selectedUsers={selectedUsers}
            handleChangeRole={handleChangeRole}
            handleChangeAnnounce={handleChangeAnnounce}
            assignLicenses={assignLicenses}
            parentComponent={ParentComponentEnum.usersManageLicenses}
            handleBulkSelect={handleBulkSelect}
            handleBulkAnnounceAction={handleBulkAnnounceAction}
            assignLicensesLoading={assignLicensesLoading}
          />
        }
      </div>
    </div>
  )
}

export default ManageMicrosoftLicences
