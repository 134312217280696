import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Tabs, Row, Col } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { getTeamById } from '../../../../graphql/custom-queries'
import { IGetTeamByIdData, IGetTeamById, ITeamUsers } from '../../../../types/teams'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'

const { TabPane } = Tabs

interface ITeamsPage {
  match: {
    params: {
      id: string
    }
  }
}

const TeamsPage = ({ match }: ITeamsPage): React.ReactElement => {

  const [isLoading, setIsLoading] = useState(true)
  const [team, setTeam] = useState<IGetTeamById>({
    id: '',
    name: '',
    timestamp: '',
    users: [],
    approvers: [],
    isDefault: false,
  })

  useEffect(() => {
    fetchTeam(match.params.id)
  }, [match.params.id])

  const fetchTeam = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getTeamById, { id })) as IGetTeamByIdData
      setTeam(response.data.getTeam)
      setIsLoading(false)
    } catch (err) { console.log('error fetching team', err) }
  }

  const operations = <Link to={`/app/settings/departments/${team.id}/edit`}><IntlMessages id="teams.editTeam" /></Link>

  return (
    <div className='main-content'>
      {isLoading ? <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span><IntlMessages id="app.department" />: {team?.name}</span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/app/settings/departments"><IntlMessages id="app.departments" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{team?.name}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="main-content-body">
            <div className="team-body">
              <Tabs defaultActiveKey="general" tabBarExtraContent={operations}>
                <TabPane tab="General" key="general">
                  <div className="view-form">
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-col-6 ant-form-item-label">
                        <label><IntlMessages id="app.name" /></label>
                      </div>
                      <div className="ant-col ant-col-16 ant-form-item-control">
                        {team.name}
                      </div>
                    </div>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-col-6 ant-form-item-label">
                        <label><IntlMessages id="app.role.approvers" /></label>
                      </div>
                      <div className="ant-col ant-col-16 ant-form-item-control">
                        <Row gutter={10}>
                          {team.approvers.map((approvers: ITeamUsers) => {
                            return (
                              <Col key={approvers.id} xs={24} sm={12} md={12} lg={8} xl={8} className="user-avatar">
                                <UserAvatar id={approvers.id} avatar={approvers.imageUrl} name={approvers.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </div>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-col-6 ant-form-item-label">
                        <label><IntlMessages id="app.users" /></label>
                      </div>
                      <div className="ant-col ant-col-16 ant-form-item-control">
                        {team.users.length > 0 ?
                          <Row gutter={10}>
                            {team.users.map((user: ITeamUsers) => {
                              return (
                                <Col key={user.id} xs={24} sm={12} md={12} lg={8} xl={8} style={{ marginBottom: 14 }}>
                                  <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                                </Col>
                              )
                            })}
                          </Row> : <IntlMessages id="team.hasNoUsers" />
                        }
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default TeamsPage
