import React, { ReactElement, useState } from 'react'
import { Button, Card, Col, Modal, Row, Typography } from 'antd'
import IntlMessages from '../../util/IntlMessages'

import ImageRemoteWork from '../../assets/images/sammy-remote-work.png'
import ImageSleepingAtWork from '../../assets/images/sammy-the-man-fell-asleep-at-the-workplace.png'

const { Paragraph } = Typography
const { Meta } = Card

interface IDontHaveAnAccountModalProps {
  showCreateAccountModal: (visible: boolean) => void
  createAccountModalVisible: boolean
  goToSignup: () => void
}

const DontHaveAnAccountModal= ({
  createAccountModalVisible,
  showCreateAccountModal,
  goToSignup,
}: IDontHaveAnAccountModalProps): ReactElement => {
  const [signInAsEmployee, showSignInAsEmployee] = useState(false)

  const onContactSupport = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:open'])
    } else {
      window.open('https://vacationtracker.crisp.help/en/', '_blank')?.focus()
    }
  }

  return (
    <Modal
      title={ signInAsEmployee ? <IntlMessages id="modal.dontHaveAnAccount.employeeSignUp" /> : <IntlMessages id="modal.dontHaveAnAccount.accountNotFound" /> }
      visible={createAccountModalVisible}
      onCancel={() => showCreateAccountModal(false)}
      className="other-platform-exist-modal"
      footer="" // Need to remove default buttons
    >
      <Row gutter={[12,6]}>
        { signInAsEmployee ? (<>
          <Col span={24}>
            <Paragraph><IntlMessages id="modal.dontHaveAnAccount.employeeSignupDescription" /></Paragraph>
            <Paragraph>
              <IntlMessages id="modal.dontHaveAnAccount.employeeSignupSupport" />
            </Paragraph>
            <Button onClick={() => onContactSupport()}><IntlMessages id="app.contactSupport" /></Button>
            <Button onClick={() => showSignInAsEmployee(false)} type="link"><IntlMessages id="app.back" /></Button>
          </Col>
        </>) : (<>
          <Col span={24}>
            <Paragraph><IntlMessages id="modal.dontHaveAnAccount.introParagraph" /></Paragraph>
          </Col>
          <Col span={12}>
            {
              // Illustration by <a href="https://icons8.com/illustrations/author/7WmtYU90j36d">Fruzka</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
            }
            <Card cover={<img src={ImageRemoteWork} />} onClick={() => showSignInAsEmployee(true)} hoverable>
              <Meta title={<IntlMessages id="modal.dontHaveAnAccount.asEmployee" />} description={<IntlMessages id="modal.dontHaveAnAccount.asEmployeeDescription" />} />
            </Card>
          </Col>
          <Col span={12}>
            {
              // Illustration by <a href="https://icons8.com/illustrations/author/kP9rc8JiBCcz">Irene M. Ray</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
            }
            <Card cover={<img src={ImageSleepingAtWork} />} onClick={() => goToSignup()} hoverable>
              <Meta title={<IntlMessages id="modal.dontHaveAnAccount.asCompany" />} description={<IntlMessages id="modal.dontHaveAnAccount.asCompanyDescription" />} />
            </Card>
          </Col>
        </>) }
      </Row>
    </Modal>
  )
}

export default DontHaveAnAccountModal
