export const allPlatforms = ['microsoft', 'slack', 'google', 'email']

export const AUTOMATIC_USERS_IMPORT_LIMIT = 1000
export const dayOfWeekMap = [
  { value: 0, name: 'app.sunday', shortName: 'app.sunday.short' },
  { value: 1, name: 'app.monday', shortName: 'app.monday.short' },
  { value: 2, name: 'app.tuesday', shortName: 'app.tuesday.short' },
  { value: 3, name: 'app.wednesday', shortName: 'app.wednesday.short' },
  { value: 4, name: 'app.thursday', shortName: 'app.thursday.short' },
  { value: 5, name: 'app.friday', shortName: 'app.friday.short' },
  { value: 6, name: 'app.saturday', shortName: 'app.saturday.short' },
]
export const CALCULATION_START_YEAR_LIMIT = '2018'
export const GOOGLE_READ_USER_PERMISSION = 'https://www.googleapis.com/auth/admin.directory.user.readonly'

export const LEGACY_PLAN_IDS = process.env.REACT_APP_ENVIRONMENT === 'production' ?
  ['price_1HyHM8CZGLYlCm3DsDNc5Rgh', 'price_1HyIJYCZGLYlCm3DSBaYeqVA'] :
  ['price_1HlZ75CZGLYlCm3Dx01szoSj', 'price_1HlZSVCZGLYlCm3DeSZsWGlr']

const csvUrlString = 'https://cdn.vacationtracker.io/templates/vacation-tracker-import-template.csv?v=1'
const excelUrlString = 'https://cdn.vacationtracker.io/templates/vacation-tracker-import-template.xlsx?v=1'
export const CSV_TEMPLATE_URL = process.env.REACT_APP_CSV_TEMPLATE_URL ? process.env.REACT_APP_CSV_TEMPLATE_URL : csvUrlString
export const EXCEL_TEMPLATE_URL = process.env.REACT_APP_CSV_TEMPLATE_URL ? process.env.REACT_APP_CSV_TEMPLATE_URL : excelUrlString