import React, { useEffect, useState, useContext, ReactElement } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb, Table, Modal, Button, Spin, Tooltip, Switch, notification as antdNotification, message } from 'antd'
import { DeleteOutlined, SendOutlined, ExclamationCircleOutlined, LoadingOutlined, WarningTwoTone, InfoCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import isNull from 'lodash/isNull'
import { API, graphqlOperation } from 'aws-amplify'
import { getCompanyNotificationsV2, getLocationsApproverTeamsAndLabels, getLocationsTeamsAndLabelsShort } from '../../../graphql/custom-queries'

import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { getNumberToDay } from '../../../util/notifications'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'

import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import EmptyData from '../../../components/empty-data'

import { INotificationV2 } from '../../../types/notifications'
import { IGetCompanyNotificationsV2 } from '../../../types/company'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { IGetLabelsShort } from '../../../types/labels'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IGetLocationsApproverTeamsAndLabelsShort, IGetLocationsTeamsAndLabelsShort } from '../../../types/custom-queries'
import { IData } from '../../../types/data'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'


const { confirm } = Modal
const helpDeskLink = 'https://vacationtracker.crisp.help/en/article/how-do-i-set-notifications-within-vacation-tracker-uxum4r/?bust=1638802683377'

interface IUpdatedNotificationEvent extends INotificationV2 {
  eventGroup: 'NOTIFICATION'
  eventType: 'NOTIFICATION_UPDATED'
  notificationId: string
  version: number
}
interface IScheduledTimeProps {
  frequency: 'DAILY' | 'WEEKLY'
  notificationDay: number
  sendOnDays: number[]
  hour: number
  minute: number
  hourFormat?: HourFormatEnum
}

const ScheduledTime = ({
  frequency,
  notificationDay,
  sendOnDays,
  hour,
  minute,
  hourFormat,
}: IScheduledTimeProps): ReactElement | null => {
  const min = minute === 0 ? '00' : `${minute}`
  let time
  if (hourFormat === HourFormatEnum.twentyFour) {
    time = `${hour}:${min}`
  } else {
    const { value, amOrPm } = convertHourFormats(hourFormat as HourFormatEnum, hour)
    time = `${value}:${min} ${amOrPm}`
  }

  const { formatMessage } = useIntl()
  if (frequency === 'WEEKLY') {
    return <>{`${getNumberToDay(notificationDay, formatMessage).full}s at ${time}`}</>
  }

  if (frequency === 'DAILY') {
    return <>{`${sendOnDays.map(e => getNumberToDay(e, formatMessage).short).join('/')} at ${time}`}</>
  }

  return null
}

const NotificationsPage: React.FC = () => {
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [notifications, setNotifications] = useState<INotificationV2[] | []>([])
  const [filteredNotifications, setFilteredNotifications] = useState<INotificationV2[] | []>([])
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(true)
  const [sendingNotifications, setSendingNotifications] = useState<string[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [filter, setFilter] = useState({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })

  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const amIAdmin = authUser.role.toLowerCase() === 'admin'
  // TODO: if there are no user show meaningful message
  const amIApprover = authUser.role.toLowerCase() === 'approver'


  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!isFetchingNotifications) {
      fetchData()
    }
  }, [actionNotifications])

  const fetchData = async () => {
    try {
      let filterResponse
      const response = await API.graphql(graphqlOperation(getCompanyNotificationsV2)) as IData<IGetCompanyNotificationsV2>
      if (amIAdmin) {
        filterResponse = await API.graphql(graphqlOperation(getLocationsTeamsAndLabelsShort)) as IGetLocationsTeamsAndLabelsShort
        setTeams(filterResponse.data.getTeamListV2)
      }
      if (amIApprover) {
        filterResponse = await API.graphql(graphqlOperation(getLocationsApproverTeamsAndLabels, { id: authUser.id } )) as IGetLocationsApproverTeamsAndLabelsShort
        setTeams(filterResponse.data.getUser.approverToTeams)
      }

      setLocations(filterResponse.data.getLocationList)
      setLabels(filterResponse.data.getLabels)
      const notifications = sortBy(response?.data?.getNotificationsV2.filter(e => !e.error), ['name'])
      const notificationsWithError = sortBy(response?.data?.getNotificationsV2.filter(e => e.error), ['name'])
      setNotifications(notifications.concat(notificationsWithError))
      setFilteredNotifications(notifications.concat(notificationsWithError))

      setIsFetchingNotifications(false)
    } catch (err) {
      console.log('error fetching notifications', err)
    }
  }

  const deleteNotification = async (notificationId: string, name: string) => {
    let response
    try {
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'NOTIFICATION_DELETED',
          eventGroup: 'NOTIFICATION',
          notificationId,
          version: 2,
        },
      })

      antdNotification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'notificationsForm.deleteInProgress' }, { notificationName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      console.log('ERROR', error)
      if (error.response?.data?.error) {
        antdNotification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        antdNotification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const updateEnabledNotification = async (notification, value) => {
    const localeOption = notification?.locale ? notification.locale : locale.locale
    const body = {
      eventType: 'NOTIFICATION_UPDATED',
      eventGroup: 'NOTIFICATION',
      notificationId: notification.id,
      ...omit(notification, ['id', 'error', 'owner']),
      locale: localeOption,
      destinations: notification.destinations.map(d => {
        if (d.platform === 'microsoft') {
          return d
        } else {
          return {
            platform: d.platform,
            addresses: d.addresses,
          }
        }
      }),
      enabled: value,
      version: 2,
    } as IUpdatedNotificationEvent


    if (isNull(notification.firstDayOfWeek)) {
      delete body.firstDayOfWeek
    }

    if (notification.frequency === 'WEEKLY') {
      delete body.sendOnDays
    }

    if (notification.frequency === 'DAILY') {
      delete body.day
      delete body.currentWeek
    }

    let response
    try {
      response = await API.post('CoreEvent', '/core/event', {
        body,
      })

      antdNotification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'notificationsForm.updateInProgress'}, { notificationName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      console.log('ERROR HANDLE SUBMIT', error)
      if (error.response?.data?.error) {
        antdNotification.error({
          message: formatMessage({ id: 'error.saveFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        antdNotification.error({
          message: formatMessage({ id: 'error.saveFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const sendNow = async (row, event) => {
    event.stopPropagation()
    setSendingNotifications(oldValue => [...oldValue, row.id])
    try {
      await API.post('CoreEvent', '/core/send-notification', { body: { notificationId: row.id } })
      antdNotification.success({
        message: formatMessage({ id: 'notifications.notificationSendNow' }),
      })
    } catch (error) {
      console.log('ERROR HANDLE SEND NOTIFICATION NOW', error)
      antdNotification.error({
        message: formatMessage({ id: 'app.deliveryFailed' }),
        description: formatMessage({ id: 'error.somethingWentWrong' }),
      })
    }
    setSendingNotifications(oldValue => oldValue.filter(value => value !== row.id))
  }

  const showConfirmDelete = (row, event) => {
    event.stopPropagation()
    confirm({
      title: formatMessage({ id: 'notifications.deleteNotificationTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'notifications.deleteNotificationConfirm' },
        {
          name: row.name,
          strong: (...chunks) => <strong>{chunks}</strong>,
        }),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteNotification(row.id, row.name)
      },
    })
  }

  const showConfirmSendNow = (row, event) => {
    confirm({
      title: formatMessage({ id: 'notifications.sendNotificationNowCofirmModalTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'notifications.sendNotificationNowCofirmModalDesc' },
        {
          name: row.name,
          strong: (...chunks) => <strong>{chunks}</strong>,
        }),
      okText: formatMessage({ id: 'notifications.sendNotificationNowOk' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        sendNow(row, event)
      },
    })
  }

  const filterData = (filter, data) => {
    const { labelIds, locationIds, teamIds } = filter

    const dataFiltered = data
      .filter((notification) => {
        if (isEmpty(locationIds)) {
          return notification
        } else if (locationIds.find(id => notification.locations.includes(id))) {
          return notification
        }
      })
      .filter((notification) => {
        if (isEmpty(teamIds)) {
          return notification
        } else if (teamIds.find(id => notification.teams.includes(id))) {
          return notification
        }
      })
      .filter((notification) => {
        if (isEmpty(labelIds)) {
          return notification
        } else if (labelIds.find(id => notification.labels.includes(id))) {
          return notification
        }
      })

    return sortBy(dataFiltered, ['name'])
  }

  const handleFilter = (filterArg) => {
    if(!isEqual(filterArg, filter)) {
      setFilter(filterArg)
    }
    setFilteredNotifications(filterData(filterArg, notifications))
  }

  const notificationsColumns = [
    {
      title: <IntlMessages id="app.active" />,
      dataIndex: 'enabled',
      key: 'enabled',
      render: (id: string, row) => {
        if (row.error) {
          return (
            <Tooltip title={<IntlMessages id={row.error} />}>
              <div style={{ width: '100%', height: '100', textAlign: 'center'}}>
                <WarningTwoTone twoToneColor="red" style={{ fontSize: 20 }} />
              </div>
            </Tooltip>
          )
        }
        return (
          <Switch checked={row.enabled}
            onClick={(checked, event) => {
              event.stopPropagation()
              updateEnabledNotification(row, checked)
            }}
            disabled={!amIAdmin && authUser.id !== row.owner?.id}
          />
        )
      },
    },
    {
      title: <IntlMessages id="app.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <IntlMessages id="notifications.table.owner" />,
      dataIndex: 'owner',
      key: 'owner',
      // eslint-disable-next-line react/display-name
      render: (_, row) => <span>{row.owner?.name}</span>,
    },
    {
      title: <IntlMessages id="notifications.frequency" />,
      dataIndex: 'frequency',
      key: 'frequency',
      render: (key) => {
        if (key === 'DAILY') {
          return <IntlMessages id="app.daily" />
        }
        if (key === 'WEEKLY') {
          return <IntlMessages id="app.weekly" />
        }
        return <IntlMessages id={`notifications.table.${key}`} />
      },
    },
    {
      title: <IntlMessages id="notifications.table.scheduledTime" />,
      dataIndex: 'schduledTime',
      key: 'schduledTime',
      render: (id: string, row) => (
        <ScheduledTime
          sendOnDays={row.sendOnDays}
          frequency={row.frequency}
          notificationDay={row.day}
          hour={row.hour}
          minute={row.minute}
          hourFormat={authUser.hourFormat || HourFormatEnum.twentyFour}
        />
      ),
    },
    {
      title: '',
      className: 'action',
      width: 124,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row) => (
        <>
          <Tooltip
            title={(!amIAdmin && authUser.id !== row.owner?.id) || row.error
              ? ''
              :<IntlMessages id="app.sendNow" />
            }
          >
            <Button
              type="link"
              disabled={(!amIAdmin && authUser.id !== row.owner?.id) || row.error}
              onClick={(event) => {
                event.stopPropagation()
                if (!row.enabled) {
                  showConfirmSendNow(row, event)
                } else {
                  sendNow(row, event)
                }
              }}
            >
              {sendingNotifications.includes(id) ? <Spin size="small" /> : <SendOutlined />}
            </Button>
          </Tooltip>
          <Tooltip title=''>
            <Button
              type="link"
              disabled={(!amIAdmin && authUser.id !== row.owner?.id)  || row.error}
              onClick={(event) => showConfirmDelete(row, event)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <div className='main-content vt-content-notifications'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span>
            <IntlMessages id="app.notifications" />&nbsp;
            <Tooltip
              className="info-tooltip"
              title={<IntlMessages
                id="notifications.tooltipInfo"
                values={{
                  helpDesk: (...chunks) => (
                    <a
                      href={helpDeskLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />} >
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.notifications" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        {isFetchingNotifications ?
          <CircularProgress /> :
          <>
            <div className="btn-actions"
              style={{
                paddingBottom: 10,
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: '20px',
                justifyContent: 'space-between',
              }}
            >
              <Link to="/app/notifications/create" className="ant-btn ant-btn-default" data-qa='go-to-notification-form'>
                <IntlMessages id="notifications.add" />
              </Link>
              <FilterAdvanced
                data={{
                  Locations: locations,
                  Departments: teams,
                  Labels: labels,
                }}
                onChangeFilter={handleFilter}
                showLabels={shouldEnableLabels}
              />
            </div>
            {notifications.length > 0 ?
              <Table
                dataSource={filteredNotifications}
                columns={notificationsColumns}
                className="clickable-table"
                rowClassName={record => {
                  if(record.error) {
                    return 'clickable-table-error'
                  }
                  return ''
                }}
                onRow={(record) => {
                  if (amIAdmin || authUser.id === record.owner?.id) {
                    return {
                      onClick: () =>  history.push(`/app/notifications/${record.id}/edit`),
                    }
                  } else {
                    return {onClick: () => {
                      message.error(formatMessage({ id: 'notifications.cantOpenNoptification' }))
                    }}
                  }
                }}
                rowKey="id"
                pagination={false}
              /> :
              <EmptyData
                title={<IntlMessages id="notifications.emptyViewTitle" />}
                subTitle={<IntlMessages id="notifications.emptyViewMessage" />}
                buttonText={<IntlMessages id="notifications.emptyViewButton"/>}
                buttonLink="/app/notifications/create"
                videoId="gR8pI28xgGo"
                helpDesText={<IntlMessages id="app.learnMore" />}
                helpDeskLink={helpDeskLink}
              />
            }
          </>}
      </div>
    </div>
  )
}

export default NotificationsPage
