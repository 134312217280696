import { useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { IGetCalendarInfo } from '../../../types/custom-queries'
import { getCalendarInfo } from '../../../graphql/custom-queries'
import { IData } from '../../../types/data'
import { IUserInfo } from '../../../types/users'

const useGetCalendarInfo = () => {

  const [calendarInfo, setCalendarInfo] = useState<IGetCalendarInfo>()

  const getCalendarInfoData = async (user: IUserInfo) => {
    const response = await API.graphql(graphqlOperation(getCalendarInfo, {
      id: user.id,
    })) as IData<IGetCalendarInfo>
    //filter the response.data.getTeamListV2 property and remove team from the array if user is not approver to that team
    const teamList =
      user.role !== 'Admin'
        ? response.data.getTeamListV2.filter((team) =>
          user.approverToTeams?.some(
            (approverTeams) => approverTeams.id === team.id
          )
        )
        : response.data.getTeamListV2
    setCalendarInfo({...response.data, getTeamListV2: teamList})
  }

  return {calendarInfo, getCalendarInfoData}
}

export default useGetCalendarInfo