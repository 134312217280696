import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Select, DatePicker, Button, TimePicker, Skeleton, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../utils/IntlMessages'
import moment from 'moment'
import range from 'lodash/range'
import addHours from 'date-fns/addHours'
import isSameDay from 'date-fns/isSameDay'
import { useIntl } from 'react-intl'

import { TimezoneInfo } from '@vacationtracker/shared/components/timezone/timezone-info'
import { getCalendarStatusSelectOptions } from '../../functions/get-calendar-status-options'
import { getShortestLeaveInterval } from '../../functions/get-shortest-leave-interval'

import { IGetUserForLeaveReueqstUserData, IGetUserForLeaveReueqstUserDataLocationLeavePolicies, IUserIdAndName } from '../../../frontend/src/types/custom-queries'
import { ShortestLeaveIntervalEnum } from '../../types/leave-policy'
import { ILeaveForm, ILeaveFormSaveData } from './types'
import { LeaveTypeCalendarStatusesEnum } from '../../types/calendar'
import { HourFormatEnum } from '../../types/user'
import { WorkingHours } from '../../types/work-week'
import { WORKING_HOURS_IN_DAY } from '../../data/app-parameters'
import { getUserWorkingHoursOnDay, userHasDefaultWorkingHours } from '../../functions/work-week'

const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker


const LeaveForm = ({
  authUserId,
  authUserRole,
  onSave,
  onCancel,
  listOfUsers,
  formType,
  modalForm = false,
  loading,
  leaveRequest,
  onLeaveTypeSelected,
  onEndDateSelected,
  onHandleTimeSelected,
  onStartDateSelected,
  onSelectUserToAddLeaveRequest,
  holidays,
  connectedGoogleCalendar,
  connectedOutlookCalendar,
  hourFormat,
  selectedUser,
  shouldShowSubstituteApprovers,
  substituteApprovers,
  isSubstituteListLoading,
  setShowOverlappingInfoAndQuotas,
}: ILeaveForm): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const endTimeRef = useRef<HTMLInputElement>(null)
  const startTimeRef = useRef<HTMLInputElement>(null)

  const [leaveTypes, setLeaveTypes] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]>([])
  const [selectedLeaveType, setSelectedLeaveType] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies | undefined>()
  const [reasonRequired, setReasonRequired] = useState<boolean>(false)
  const [allowLeaveInPast, setAllowLeaveInPast] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState(false)
  const [userTimezone, setUserTimezone] = useState<string | undefined>()
  const [startTime, setStartTime] = useState<moment.Moment | null>(leaveRequest?.partDayStartHour ? moment().hour(leaveRequest.partDayStartHour) : null)
  const [endTime, setEndTime] = useState<moment.Moment | null>(leaveRequest?.partDayEndHour ? moment().hour(leaveRequest.partDayEndHour) : null)
  const [isStartTimePanelOpened, setIsStartTimePanelOpened] = useState(false)
  const [isEndTimePanelOpened, setIsEndTimePanelOpened] = useState(false)
  const [shortestLeaveInterval, setShortestLeaveInterval] = useState<ShortestLeaveIntervalEnum>(ShortestLeaveIntervalEnum.fullDay)
  const [substituteApprover, setSubstituteApprover] = useState<IUserIdAndName | undefined>()
  let defaultValue

  if (leaveRequest && !leaveRequest.isPartDay) {
    defaultValue = {
      reason: leaveRequest.reason,
      rangeDate: [moment(leaveRequest.startDate), moment(leaveRequest.endDate)],
      leaveTypeId: leaveRequest.leaveType.id,
    }
  } else if (leaveRequest && leaveRequest.isPartDay) {
    defaultValue =  {
      reason: leaveRequest.reason,
      startDate: moment(leaveRequest.startDate),
      range: [moment().hour(leaveRequest.partDayStartHour), moment().hour(leaveRequest.partDayEndHour)],
      leaveTypeId: `${leaveRequest.leaveType.id}|part-day`,
    }
  } else {
    defaultValue =  {
      reason: '',
    }
  }

  if (leaveRequest?.substituteApproverId) {
    defaultValue.substituteApprover = leaveRequest.substituteApproverId
  }

  useEffect(() => {
    if (connectedGoogleCalendar  || connectedOutlookCalendar) {
      // TODO: when we decide to allow users to edit calendar status we should uncomment this
      // if (formType === 'edit' && leaveRequest) {
      //   form.setFieldsValue({calendarStatus:leaveRequest?.calendarStatus})
      // } else {
      //   form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
      // }
      form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
    }
  }, [connectedGoogleCalendar, connectedOutlookCalendar])

  useEffect(() => {
    if (loading) {
      setFormLoading(true)
    } else {
      setFormLoading(false)
    }
  }, [loading])

  useEffect(() => {
    if (selectedUser) {
      setUserTimezone(selectedUser.location.timezone)
      setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies, selectedUser.workHours))
    }
  }, [selectedUser])

  useEffect(() => {
    if (startTime) {
      endTimeRef.current?.focus()
    }
  }, [startTime])

  useEffect(() => {if (formType !== 'edit') {
    form.setFieldsValue({rangeDate: []})
  }}, [selectedLeaveType])

  useEffect(() => {if (formType !== 'edit') {
    form.setFieldsValue({rangeDate: [], userId: form.getFieldValue('userId'), leaveTypeId: ''})
  }}, [form.getFieldValue('userId')])

  const leaveTypeLoop = (data: IGetUserForLeaveReueqstUserDataLocationLeavePolicies[], workHours?: WorkingHours) => {
    const userLeaveTypesData = [] as IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]
    data.forEach((lp: IGetUserForLeaveReueqstUserDataLocationLeavePolicies) => {
      if (lp.isActive && lp.leaveType.isActive && !lp.leaveType.deleted) {
        userLeaveTypesData.push({
          ...lp,
          id: lp.leaveType.id,
        })
        // This `type.shortestLeaveInterval && ...` need to be here because sometime can be null and without this, this is always true
        if ((!lp.shortestLeaveInterval && lp.allowHalfDays) || (lp.shortestLeaveInterval && lp.shortestLeaveInterval !== ShortestLeaveIntervalEnum.fullDay)) {
          const shortestLeaveInterval = getShortestLeaveInterval(lp.shortestLeaveInterval, lp.allowHalfDays)
          const shortestLeaveIntervalSuffix = shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour ? 'leaveRequest.hourly' : 'leaveRequest.halfDay'
          if (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay && !userHasDefaultWorkingHours(workHours)) {
            return
          }
          userLeaveTypesData.push({
            ...lp,
            id: `${lp.leaveType.id}|part-day`,
            leaveType: {
              ...lp.leaveType,
              name: `${lp.leaveType.name} (${formatMessage({ id: shortestLeaveIntervalSuffix })})`,
            },
          })
        }
      }
    })

    return userLeaveTypesData.sort((a, b) => a.leaveType.position < b.leaveType.position ? -1 : 1)
  }

  const setCalendarStatus = (v) => {
    form.setFieldsValue({ calendarStatus:  v})
  }

  const onFilterUsers = (input, option) => {
    if (!option?.children) return false
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }  
  
  const onFilterSubstituteApprovers = (input, option) => {
    if (!option?.children) return false
    return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onChangeUser = (id: string) => {
    const selectedUser = listOfUsers.find(u => u.id === id) as IGetUserForLeaveReueqstUserData | IUserIdAndName

    if (selectedUser) {
      if ('location' in selectedUser) {
        setUserTimezone(selectedUser.location.timezone)
        setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies, selectedUser.workHours))
      }
      onSelectUserToAddLeaveRequest && onSelectUserToAddLeaveRequest(selectedUser.id)
    }
  }

  const onChangeSubstituteApprover = (id: string) => {
    const selected = substituteApprovers?.find(u => u.id === id)

    if (selected) {
      setSubstituteApprover(selected)
    }
  }

  const onChangeType = (leaveTypeId: string) => {
    const selectedLeaveType = leaveTypes.find(leaveType => leaveType?.id === leaveTypeId)
    formType == 'request' ? form.setFieldsValue({ startDate: '', range: [], rangeDate: [] }) : null
    setSelectedLeaveType(selectedLeaveType)
    onLeaveTypeSelected && onLeaveTypeSelected(selectedLeaveType)
    if(leaveTypeId.includes('|part-day') && selectedLeaveType) {
      setShortestLeaveInterval(getShortestLeaveInterval(selectedLeaveType?.shortestLeaveInterval, selectedLeaveType?.allowHalfDays))
    } else {
      setShortestLeaveInterval(ShortestLeaveIntervalEnum.fullDay)
    }

    if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request') {
      form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
    }

    setCalendarStatus(selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy)
    setReasonRequired(Boolean(selectedLeaveType?.isReasonRequired))
    setAllowLeaveInPast(Boolean(selectedLeaveType?.allowLeaveInPast))
  }

  useEffect(() => {
    if (formType === 'request' || formType === 'add-to-user' || formType === 'edit') {
      const selectedUser = listOfUsers[0]
      if ('location' in selectedUser) {
        setUserTimezone(selectedUser.location.timezone)
        setLeaveTypes(leaveTypeLoop(selectedUser?.location.leavePolicies, selectedUser?.workHours))
      }
    }
    if (formType === 'edit' && leaveRequest) {
      const leavePolicies = leaveRequest.leaveType.leavePolicies.find(lp => lp.locationId === leaveRequest.user.locationId)
      setShortestLeaveInterval(!leaveRequest?.partDayStartHour
        ? ShortestLeaveIntervalEnum.fullDay
        : getShortestLeaveInterval(leavePolicies?.shortestLeaveInterval, leaveRequest.isPartDay))
      setReasonRequired(leavePolicies?.isReasonRequired || false)
    }

  }, [formType, listOfUsers])

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setFormLoading(true)

        const isPartDay = values.range ? true : false
        let partDayStartHour: number | undefined
        let partDayEndHour: number | undefined
        if (isPartDay) {
          partDayStartHour = values.range[0] ? values.range[0].hour() : 0
          partDayEndHour = values.range[1] ? values.range[1].hour() : 0
        }

        const saveObj: ILeaveFormSaveData = {
          userId: values.userId,
          isPartDay,
          leaveTypeId: values.leaveTypeId.split('|part-day')[0],
          partDayStartHour,
          partDayEndHour,
          startDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[0].format('YYYY-MM-DD'),
          endDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[1].format('YYYY-MM-DD'),
          reason: values.reason,
          substituteApproverId: values.substituteApprover,
        }

        if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request') {
          saveObj.calendarStatus = values.calendarStatus
        }

        if (formType === 'add' || formType === 'add-to-user') {
          saveObj.calendarStatus = selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy
        }

        if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'add-to-user') {
          saveObj.calendarStatus = selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy
        }

        if (formType === 'edit') {
          saveObj.calendarStatus = values.calendarStatus
          || leaveRequest?.calendarStatus
          || selectedLeaveType?.leaveType?.calendarStatus
          || LeaveTypeCalendarStatusesEnum.busy
        }

        onSave(saveObj)

        if (formType !== 'edit') {
          form.setFieldsValue({
            range: '',
            startDate: '',
            reason: '',
            rangeDate: '',
          })
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  const disableDatePicker = (current: moment.Moment): boolean => {
    if (formType === 'add' || formType === 'add-to-user' || (formType === 'edit' && ['Admin', 'Approver'].includes(authUserRole)) || allowLeaveInPast) {
      return !current || current > moment().add(1, 'year').endOf('year')
    } else {
      return !current || current < moment().subtract(1, 'day') || current > moment().add(1, 'year').endOf('year')
    }
  }

  const disabledDateRangePicker = (current: moment.Moment): boolean => {
    let tooLate: boolean
    let tooEarly: boolean
    if (formType === 'add' || formType === 'add-to-user' || (formType === 'edit' && ['Admin', 'Approver'].includes(authUserRole)) || allowLeaveInPast) {
      tooLate = current && current > moment().add(1, 'year').endOf('year')
      tooEarly = false
    } else {
      tooLate = current && current > moment().add(1, 'year').endOf('year')
      tooEarly = current && current < moment().subtract(1, 'day')
    }

    return tooEarly || tooLate
  }

  const handleResetForm = () => {
    form.resetFields()
    setShowOverlappingInfoAndQuotas && setShowOverlappingInfoAndQuotas(false)
  }

  const handleTimeSelection = (type: 'start' | 'end', date: moment.Moment | null) => {
    if (!date) {
      return
    }
    const hour = date.get('hour')

    if (type === 'start' && hour === 23) {
      setStartTime(date)
      setEndTime(null)
      form.setFieldsValue({range: [date, null]})
      setIsStartTimePanelOpened(true)
      setIsEndTimePanelOpened(false)
      onHandleTimeSelected && onHandleTimeSelected({ startTime: date.hour(), endTime: null })
      return
    }
    const timeToAdd = hour >= 19 ? 23 - hour : 4

    const endTime = addHours(date.toDate(), timeToAdd)
    if (type === 'start') {
      setStartTime(date)
      form.setFieldsValue({range: [date, moment(endTime)]})
      setEndTime(moment(endTime))
      setIsStartTimePanelOpened(false)
      setIsEndTimePanelOpened(true)
      onHandleTimeSelected && onHandleTimeSelected({ startTime: date.hour(), endTime: moment(endTime).hour() })
    } else {
      form.setFieldsValue({range: [startTime, date]})
      setEndTime(date)
      setIsEndTimePanelOpened(false)
      onHandleTimeSelected && onHandleTimeSelected({ startTime: startTime?.hour(), endTime: date.hour() })
    }
  }

  const handleDisableTime = (startTime: number | null) => {
    if (formLoading || !form.getFieldValue('startDate') || typeof startTime === null) {
      return {}
    }
    const disabledBefore = range(0, (startTime as number) + 1)
    const user = selectedUser ?? listOfUsers[0] as IGetUserForLeaveReueqstUserData
    let workingHours = user.workHours ? getUserWorkingHoursOnDay(user.workHours, form.getFieldValue('startDate').toDate() as Date) : WORKING_HOURS_IN_DAY
    if (isHalfDayHoliday()) {
      workingHours /= 2
    }
    const disabledAfter = range((startTime as number) + Math.floor(workingHours + 1), 24)

    return {
      disabledHours: () => [...disabledBefore, ...disabledAfter],
    }
  }

  const isHalfDayHoliday = () => {
    const day: Date = form.getFieldValue('startDate') && form.getFieldValue('startDate').toDate()

    const isHalfDay = day && holidays?.filter(
      (h) =>
        h.year === day.getFullYear() &&
        h.holidays.find((h) => isSameDay(day, new Date(h.date)) && h.isHalfDay)
    )

    return isHalfDay && isHalfDay?.length > 0
  }

  const timePickerValidation = () => {
    if (startTime && endTime) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(formatMessage({ id: 'components.leaveForm.pleaseSelectRangeTime' })))
  }

  const formLayout = {
    labelCol: { span: modalForm ? 8 : 6 },
    wrapperCol: { span: modalForm ? 16 : 12 },
  }

  const offsetItemLayout = {
    wrapperCol: {
      span: modalForm ? 12 : 12,
      xs: {offset: modalForm ? 10 : 0} ,md: {offset: modalForm ? 10 : 6},
    },
  }

  const disableReasonField = (numberOfLeaveTypes: number, type: string, userList: IGetUserForLeaveReueqstUserData[] | IUserIdAndName[]): boolean => {
    return numberOfLeaveTypes === 0 || type === 'edit' && userList.length === 1 && userList[0].id !== authUserId
  }

  const handleOnEndDateSelected = (dateRange) => {
    if (onEndDateSelected && dateRange) {
      const startDate = dateRange[0]?.format('YYYY-MM-DD')
      const endDate = dateRange[1]?.format('YYYY-MM-DD')
      onEndDateSelected({
        startDate,
        endDate,
      })
    }
  }

  const handleOnStartDateSelected = (startDate: moment.Moment | null) => {
    if (startDate) {
      onStartDateSelected && onStartDateSelected(startDate.format('YYYY-MM-DD'))
    }
    startTimeRef && startTimeRef.current?.focus()
  }
  
  return (
    <Form
      form={form}
      {...formLayout}
      scrollToFirstError
      onFinish={onFinish}
      initialValues={defaultValue}
      labelWrap
    >
      {['Admin', 'Approver'].includes(authUserRole) && (formType === 'add' || formType === 'add-to-user') && (
        <p><IntlMessages id="components.leaveForm.addLeaveDescription" /></p>
      )}
      {['Admin', 'Approver'].includes(authUserRole) && formType === 'request' && (
        <p><IntlMessages id="components.leaveForm.requestLeaveDescription" /></p>
      )}
      {['Admin', 'Approver'].includes(authUserRole) && formType === 'edit' && leaveRequest && leaveRequest.userId !== authUserId && (
        <p><IntlMessages id="components.leaveForm.editLeaveDescription.adminAndApprover" /></p>
      )}
      {leaveRequest && leaveRequest.userId === authUserId && formType === 'edit' && (
        <p><IntlMessages id="components.leaveForm.editLeaveDescription.user" /></p>
      )}

      {formType === 'add' &&
        <Form.Item name="userId"
          label={<IntlMessages id="components.leaveForm.selectUser" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.selectUser" /> }]}
        >
          <Select style={{ width: 250 }} onChange={onChangeUser} filterOption={onFilterUsers} showSearch>
            {listOfUsers.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      }   
      <Form.Item name="leaveTypeId"
        label={<IntlMessages id="app.leaveType" />}
        rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectLeaveType" /> }]}
      >
        <Select onChange={onChangeType} style={{ width: 250 }} disabled={leaveTypes.length === 0 || formType === 'edit'}>
          {leaveTypes.map(item => (<Option key={item.id} value={item.id}>{item.leaveType.name}</Option>))}
        </Select>
      </Form.Item>

      {(shortestLeaveInterval !== ShortestLeaveIntervalEnum.fullDay) &&
        <>
          <Form.Item name="startDate"
            label={<IntlMessages id="components.leaveForm.date" />}
            rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}
          >
            <DatePicker disabledDate={disableDatePicker} style={{ width: 250 }} onChange={handleOnStartDateSelected} />
          </Form.Item>
          <Form.Item name="range"
            label={<IntlMessages id="app.time" />}
            rules={[{ required: true, validator: timePickerValidation }]}
            extra={<TimezoneInfo userTimezone={userTimezone} />}
          >
            <TimePicker
              ref={startTimeRef}
              format={hourFormat === HourFormatEnum.twelve ? 'hh' : 'HH'}
              style={{ width: 150, marginRight: 10 }}
              popupClassName='timepicker-without-ok'
              placeholder={formatMessage({ id: 'components.leaveForm.startTime' })}
              allowClear={false}
              open={isStartTimePanelOpened}
              onOpenChange={() => setIsEndTimePanelOpened(false)}
              onClick={() => setIsStartTimePanelOpened(true)}
              onSelect={(v) => handleTimeSelection('start', v)}
              onBlur={() => setIsStartTimePanelOpened(false)}
              value={startTime}
              use12Hours={hourFormat === HourFormatEnum.twelve}
            />
            <TimePicker
              ref={endTimeRef}
              format={hourFormat === HourFormatEnum.twelve ? 'hh' : 'HH'}
              style={{ width: 150 }}
              popupClassName='timepicker-without-ok'
              showNow={false}
              placeholder={formatMessage({ id: 'components.leaveForm.endTime' })}
              allowClear={false}
              open={isEndTimePanelOpened}
              disabled={shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay}
              value={endTime}
              onSelect={(v) => handleTimeSelection('end', v)}
              onClick={() => setIsEndTimePanelOpened(true)}
              onBlur={() => setIsEndTimePanelOpened(false)}
              disabledTime={() => handleDisableTime(startTime && startTime.get('hour'))}
              use12Hours={hourFormat === HourFormatEnum.twelve}
            />
          </Form.Item>
        </>
      }

      {shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay &&
        <Form.Item name="rangeDate"
          label={<IntlMessages id="app.dateRange" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}>
          <RangePicker
            disabledDate={disabledDateRangePicker}
            disabled={leaveTypes.length === 0}
            onChange={handleOnEndDateSelected}
          />
        </Form.Item>
      }

      <Form.Item name="reason"
        label={<IntlMessages id="app.reason" />}
        rules={[{ required: reasonRequired, message: <IntlMessages id="components.leaveForm.pleaseInputReason" /> }]}
      >
        <TextArea
          rows={4}
          style={{ width: 2 * 250 }}
          disabled={disableReasonField(leaveTypes.length, formType, listOfUsers)}
        />
      </Form.Item>
      
      {isSubstituteListLoading ? <Skeleton.Input size="large" style={{ width: '90vw' }} active /> : (shouldShowSubstituteApprovers &&
        <Form.Item name="substituteApprover"
          label={
            <>
              <IntlMessages id="components.leaveForm.selectSubstituteApprover" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leaveForm.selectSubstituteApproverInfoMessage" />}><InfoCircleOutlined /></Tooltip>
            </>
          }
        >
          <Select style={{ width: 250 }} 
            onChange={onChangeSubstituteApprover} 
            filterOption={onFilterSubstituteApprovers} showSearch 
            defaultValue={formatMessage({ id: 'app.none' })}
          >
            {substituteApprovers && substituteApprovers.map(item => (
              <Option key={item.id} value={item.id}>{item.name} <strong>({item.role})</strong></Option>
            ))}
          </Select>
        </Form.Item>)}

      {/*
        TODO: when we decide to allow users to edit calendar status we should enable this
        {connectedCalendar && (formType === 'request' || formType === 'edit') && <Form.Item
      */}
      {(connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request' && 
        <Form.Item
          name='calendarStatus'
          label={<IntlMessages id="app.leaveRequestCalendarStatusLabel" />}
          extra={
            <>
              {connectedGoogleCalendar && <div>
                <IntlMessages
                  id="components.leaveForm.connectedCalendarTimezone"
                  values={{
                    calendarType: 'Google',
                    timezone: connectedGoogleCalendar?.timezone,
                  }} />
              </div>}
              {connectedOutlookCalendar && <div>
                <IntlMessages
                  id="components.leaveForm.connectedCalendarTimezone"
                  values={{
                    calendarType: 'Outlook',
                    timezone: connectedOutlookCalendar?.timezone,
                  }} />
              </div>}
            </>
          }
        >
          <Select options={getCalendarStatusSelectOptions(formatMessage)} style={{ width: 100 }} />
        </Form.Item>
      }

      <Form.Item {...offsetItemLayout} >
        {onCancel && 
          <Button type="default" danger style={{ marginRight: 10 }} onClick={onCancel}>
            <IntlMessages id="app.cancel" />
          </Button>
        }
        <Button className='vt-btn-reset'
          type="default"
          style={{ marginRight: 40, marginBottom: 10 }}
          onClick={handleResetForm}>
          <IntlMessages id="app.reset" />
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={formLoading}
          data-qa='submit-leave-request'
        >
          {formType === 'request' && <IntlMessages id="app.requestLeave" />}
          {formType === 'edit' && <IntlMessages id="app.editLeave" />}
          {(formType === 'add' || formType === 'add-to-user') && <IntlMessages id="app.addLeave" />}
        </Button>
      </Form.Item>
    </Form >
  )
}

export default LeaveForm
