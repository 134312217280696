import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { isBoolean } from 'lodash'

import { useAppSelector } from '../../../../store/hooks'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../../../store/auth-user-slice'

import ConfigureImportUsersOptionsForm, { getDontSendWelcomeOption } from '../../../../components/configure-import-users-options-form'
import { ImportUsersNavigation } from '../components'
import showConfirmImportUsers from '../../../../components/confirm-company-settings-and-import-users-update-modal'

import { IConfigureImportUsersOptionsProps, ImportUsersStepEnum, WelcomeMessageOptions } from '../types'
import { IGetTeamsShort } from '../../../../types/teams'
import { IGetLocationsShort } from '../../../../types/locations'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { ConfigureImportSourceEnum, ISelectedLeaveType } from '../../../../components/configure-import-users-options-form/types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { isAfter } from 'date-fns'

export const ConfigureImportUsersOptions = ({
  activeStep,
  noOfUsersToImport,
  importAllUsers,
  importUsersAutomatically,
  selectedUsers,
  welcomeMessageOpts,
  setSelectedWelcomeMessageOpt,
  setSteps,
  setWelcomeMessageOpts,
  onChangeGuestUsersImport,
  setShowMsLoginAdditionalScopesRequiredModal,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  totalNumberOfUsersAfterImport,
  onSubmit,
}: IConfigureImportUsersOptionsProps): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)

  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState<IGetTeamsShort | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<IGetLocationsShort | null>(null)
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<ISelectedLeaveType[]>([])

  useEffect(() => {
    form.setFieldsValue({
      sendWelcomeMessageTo: authCompany?.announceNewUsers
        ? welcomeMessageOpts.find(opt => opt.platform === authCompany?.platform) as WelcomeMessageOptions
        : getDontSendWelcomeOption(formatMessage),
    })
  }, [authCompany])

  const defaultValue = {
    sendWelcomeMessageTo: null,
    selectedDepartment: null,
    selectedLocation: null,
    shouldProrate: true,
    selectedLeaveTypes: [],
    shouldImportGuestUsers: true,
  }

  return <Form
    key="configureImportUsersForm"
    form={form}
    name="configureImportUsersForm"
    initialValues={defaultValue}
    colon={false}
    labelWrap
  >
    <ConfigureImportUsersOptionsForm
      form={form}
      source={ConfigureImportSourceEnum.importUsersPage}
      onChangeGuestUsersImport={onChangeGuestUsersImport}
      setSelectedDepartment={setSelectedDepartment}
      setSelectedLocation={setSelectedLocation}
      setSelectedLeaveTypes={setSelectedLeaveTypes}
      welcomeMessageOpts={welcomeMessageOpts}
      setWelcomeMessageOpts={setWelcomeMessageOpts}
      setSelectedWelcomeMessageOpt={setSelectedWelcomeMessageOpt}
      shouldShowProrate
      shouldShowImportGuestUsers={importAllUsers || importUsersAutomatically}
      formLayout={{
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
      }}
    />
    {authCompany && <ImportUsersNavigation
      platform={authCompany?.platform as Platform}
      importAllUsers={importAllUsers}
      noOfUsersToImport={noOfUsersToImport}
      submitInProgress={submitInProgress}
      activeStep={activeStep}
      upcomingInvoiceInfo={upcomingInvoiceInfo}
      isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
      isAnnualBilling={authCompany?.billing?.subscriptionPeriod === 'annual'}
      locale={authUser?.locale as LocaleEnum}
      showAdditionalBillingInfo={false}
      totalNumberOfUsersAfterImport={totalNumberOfUsersAfterImport}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handleNext={async () => {
        await form.validateFields()
        if (
          (!sessionStorage.getItem('msWebAppTokensExpiration') || isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string)))
          && (importUsersAutomatically || authCompany.importUsersAutomatically) && authCompany.platform === 'microsoft'
        ) {
          return setShowMsLoginAdditionalScopesRequiredModal(true)
        }
        setSteps(ImportUsersStepEnum.confirm, 2)
        showConfirmImportUsers({
          onCancel: () => setSteps(ImportUsersStepEnum.configure, 1),
          onConfirm: () => {
            setSubmitInProgress(true)
            const data = form.getFieldsValue()
            if (authCompany.platform === 'slack' && !isBoolean(data.shouldImportGuestUsers)) {
              data.shouldImportGuestUsers = true
            }

            onSubmit(importAllUsers, selectedUsers, data)
          },
          title: formatMessage(
            { id: 'components.importUsersForm.confirmImportUsersModalTitle' },
            { noOfUsers: noOfUsersToImport > 0 ? noOfUsersToImport : '' }
          ),
          okText: formatMessage(
            { id: 'components.importUsersForm.button.importSelectedUsers' },
            { noOfUsers:   noOfUsersToImport > 0 ? noOfUsersToImport : '' }
          ),
          formatMessage,
          noOfUsersToImport,
          welcomeMessageOpts,
          authCompany,
          selectedDepartment,
          selectedLocation,
          selectedLeaveTypes,
          upcomingInvoiceInfo,
          isLoadingUpcomingInvoiceInfo,
          locale: authUser?.locale as LocaleEnum,
          showAdditionalBillingInfo: true,
          totalNumberOfUsersAfterImport,
          form,
          showInfoFor: {
            showNewNameInfo: false,
            showImportUsersAutomaticallyInfo: false,
            showAiBotHelperInfo: false,
            showUserEndDateInfo: false,
            showHourlyLeaveAccountingInfo: false,
            showWelcomeMessageToInfo: true,
            showSlackMultichannelUsersImport: Boolean(authCompany?.platform === 'slack') && (importAllUsers || importUsersAutomatically),
            showCalculationStartYearInfo: false,
            showAlwaysDefaultDepartmentAndLocation: true,
            showMoreInfo: true,
            shownNOfUsersToImportInfo: true,
          },
        })
      }}
      handleBack={() => setSteps(ImportUsersStepEnum.selectUsers, 0)}
      nextButtonTitle={formatMessage(
        { id: 'components.importUsersForm.confirmImportUsersModalContentNoOfUsers' },
        { noOfUsers: noOfUsersToImport > 0 ? noOfUsersToImport : '' }
      )}
      isTrial={authCompany?.subscriptionStatus === 'trialing' && !authCompany?.billing?.paymentMethodAdded}
      stripeCurrentPeriodEnd={authCompany?.billing?.stripeCurrentPeriodEnd as string}
    />
    }

  </Form>
}