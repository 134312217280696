import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Form, Input, Select, Typography } from 'antd'
import { CardElement } from '@stripe/react-stripe-js'

import countries, { ICountry } from '@vacationtracker/shared/data/countries'
import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import { ThemeContext } from '../../context/themeContext'
import { DARK_THEME } from '../../constants/ThemeSetting'

const { Option } = Select
const { Title } = Typography

interface IBillingForm {
  form: {
    setFieldsValue: (e) => void
    getFieldValue: (e) => string
  }
  showPayment?: boolean
  disabled?: boolean
}

const BillingForm = ({form, showPayment = true, disabled = false}: IBillingForm): React.ReactElement => {
  const { theme } = useContext(ThemeContext)
  const [ selectedCountry, setSelectedCountry ] = useState<ICountry|undefined>()

  const onCountryChange = (countryIso) => {
    setSelectedCountry(countries.find(country => country.iso === countryIso))
    form.setFieldsValue({ state: '' })
  }

  useEffect(() => {
    setSelectedCountry(countries.find(country => country.iso === form.getFieldValue('country')))
  }, [])

  return (
    <Fragment>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="general.companyName" />}
        name="billingName"
        rules={[{ required: true, message: (<IntlMessages id="components.billingForm.companyNameValidationRequired" />) }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billing.email" />}
        name="billingEmails"
        rules={[
          { required: true,
            type: 'array',
            message: (<IntlMessages id="components.billing.emailsValidationRequired" />),
            defaultField: { type: 'email', message: <IntlMessages id="components.billing.emailsValidationRequired" /> }},
        ]}
      >
        <Select
          mode="tags"
          disabled={disabled}
          style={{ width: '100%' }}
          placeholder={<IntlMessages id="components.billing.emailPlaceholder" />}
        />
      </Form.Item>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billingForm.city" />}
        name="city"
        rules={[{ required: true, message: (<IntlMessages id="components.billingForm.cityValidationRequired" />) }]}
      >
        <Input disabled={disabled}/>
      </Form.Item>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="app.country" />}
        name="country"
        rules={[{ required: true, message: (<IntlMessages id="components.billingForm.countryValidationRequired" />) }]}
      >
        <Select
          onChange={onCountryChange}
          disabled={disabled}
          filterOption={filterOptions}
          showSearch
        >
          {countries.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
            <Option value={country.iso} key={country.iso}>
              {country.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!(selectedCountry && selectedCountry.states && selectedCountry.states.length) || (
        <Form.Item
          className='wrap-label'
          label={<IntlMessages id="app.state" />}
          name="state" 
          rules={[{ required: true, message: (<IntlMessages id="components.billingForm.stateValidationRequired" />) }]}
        >
          <Select filterOption={filterOptions} disabled={disabled} showSearch>
            {selectedCountry.states.map((state) => (
              <Option value={state.iso} key={state.iso}>
                {state.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billingForm.address1" />}
        name="address"
        rules={[{ required: true, message: (<IntlMessages id="components.billingForm.address1ValidationRequired" />) }]}
      >
        <Input disabled={disabled}/>
      </Form.Item>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billingForm.address2" />}
        name="addressLine2"
        rules={[{ required: false }]}
      >
        <Input disabled={disabled}/>
      </Form.Item>

      {showPayment && <Title level={4}>
        <IntlMessages id="billing.paymentInformation" />
      </Title>}

      {showPayment &&
        <Form.Item
          label={<IntlMessages id="app.creditCard" />}
          name="cc"
          rules={[{ required: true, message: (<IntlMessages id="components.billingForm.ccRequired" />)  }]}
          className="stripe-input wrap-label"
          extra={<IntlMessages id="components.billingForm.description" />}
        >
          <CardElement options={{
            style: {
              base: {
                fontSize: '16px',
                lineHeight: '24px',
                color: theme === DARK_THEME ? '#fff' : '#545454',
                iconColor: theme === DARK_THEME ? '#fff' : '#545454',
                '::placeholder': {
                  color: theme === DARK_THEME ? 'rgba(255, 255, 255, 0.5)' : 'rgba(84, 84, 84, 0.5)',
                },
              },
            },
          }} />
        </Form.Item>
      }

    </Fragment>
  )
}

export default BillingForm
