import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Alert, Breadcrumb, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { isWithinInterval, addYears, areIntervalsOverlapping } from 'date-fns'
import { API, graphqlOperation } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { getConnectedCalendars, getLeaveTypesForUser, getOverlappingUsersInfo, getTeamsForApprover, getUsersForAdminWithPagination } from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'

import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserIdSlice } from '../../../store/user-id-slice'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { displayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { shouldShowFutureAccruedDaysInfo } from '@vacationtracker/shared/functions/should-show-future-accrued-days-info'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import {
  LeaveRequestOverlappingInfo,
  LeaveRequestQuota,
  LeaveRequestSummary,
  getLeavePolicyFromQuota
} from '@vacationtracker/shared/components/add-request-leave-additional-info'

import {
  IGetConnectedCalendarsResponse,
  IUserLeaveReuqestLeavePolicy,
  IUserQuota
} from '../../../types/custom-queries'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { ILocationYearHolidays } from '@vacationtracker/shared/types/holidays'
import { IData } from '../../../types/data'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import { ILeaveTypeResult, IQuota } from '@vacationtracker/shared/types/calculations'
import { IApprover } from '@vacationtracker/shared/types/leave-request'
import {
  IGetLeaveTypesForUserLeaveRequestData,
  IGetUserOverlappingInfo,
  IGetUserOverlappingInfoData,
  IUserLeaveRequestExtended
} from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { AccrualType } from '@vacationtracker/shared/types/leave-policy'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

interface IRequestsLeaveProps {
  history: {
    push: Function
    goBack: Function
  }
}

const RequestLeavePage = ({ history }: IRequestsLeaveProps) => {
  const { userId } = useAppSelector(selectUserIdSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const dispatch = useAppDispatch()
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const shouldEnableSubstituteApprovers = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.substituteApprovers)

  const [createLoader, setCreateLoader] = useState(false)
  const [user, setUser] = useState<IUserLeaveRequestExtended | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [thisYearHolidays, setThisYearHolidays] = useState([] as ILocationYearHolidays[])
  const [connectedGoogleCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState< IConnectedOutlookCalendar | null>(null)
  const [isLoadingConnectedCalenadar, setIsLoadingConnectedCalenadar] = useState(true)
  const [isLoadingOverlappingUsersInfo, setIsLoadingOverlappingUsersInfo] = useState(false)
  const [overlappingUsersInfo, setOverlapingUsersInfo] = useState<IGetUserOverlappingInfo | null>(null)
  const [checkForOverlappingUsers, setCheckForOverlappingUsers] = useState(false)
  const [startDate, setStartDate] = useState()
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveReuqestLeavePolicy | null>(null)
  const [endDate, setEndDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [currentQuota, setCurrentQuota] = useState<IUserQuota | null>(null)
  const [nextYearQuota, setNextYearQuota] = useState<IUserQuota | null>(null)
  const [showSummary, setShowSummary] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  const [currentLeavePolicy, setCurrentLeavePolicy] = useState<ILeaveTypeResult | null>(null)
  const [nextYearLeavePolicy, setNextYearLeavePolicy] = useState<ILeaveTypeResult | null>(null)
  const { setCheckIfSubstituteNeeded, suggestedSubstituteApprovers, shouldShowSubstituteApprovers, isSubstituteListLoading } = useSubstituteApprover(userId, startDate, endDate)
  const [showOverlappingInfoAndQuotas, setShowOverlappingInfoAndQuotas] = useState(true)

  useEffect(() => {
    setCurrentLeavePolicy(getLeavePolicyFromQuota(selectedLeaveType?.id as string, currentQuota as IQuota) as ILeaveTypeResult)
    setNextYearLeavePolicy(getLeavePolicyFromQuota(selectedLeaveType?.id as string, nextYearQuota as IQuota) as ILeaveTypeResult)
  }, [currentQuota, nextYearQuota, selectedLeaveType])


  useEffect(() => {
    if (isLoading) {
      getLeaveTypes(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (checkForOverlappingUsers) {
      getOverlappingUsers()
    }
  }, [checkForOverlappingUsers])

  useEffect(() => {
    if (leaveRequestActionEvent && correlationId === leaveRequestActionEvent.correlationId) {
      setShowSummary(true)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  const getOverlappingUsers = async () => {
    setIsLoadingOverlappingUsersInfo(true)
    setShowOverlappingInfoAndQuotas(true)
    const overlappingResponse = await API.graphql(graphqlOperation(getOverlappingUsersInfo, {
      teamId: user?.team.id,
      leaveRequest: {
        startDate,
        endDate,
        userId: user?.id || userId,
        isPartDay: selectedLeaveType?.allowHalfDays || Boolean(selectedLeaveType?.shortestLeaveInterval !== 'FULL-DAY'),
        partDayStartHour: startTime,
        partDayEndHour: endTime,
        leaveTypeId: selectedLeaveType?.id,
      },
    })) as IGetUserOverlappingInfoData
    setOverlapingUsersInfo(overlappingResponse?.data?.getOverlappingUsersInfo)
    setCheckForOverlappingUsers(false)
    setIsLoadingOverlappingUsersInfo(false)
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const resetLeaveRequestForm = () => {
    setShowSummary(false)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
    setCurrentLeavePolicy(null)
    setNextYearLeavePolicy(null)
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
    setCheckForOverlappingUsers(true)
    if((authUser?.role === 'Approver' || authUser?.role === 'Admin') && shouldEnableSubstituteApprovers) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const handleOnStartDateSelected = (startDate) => {
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(startDate)
    setCheckForOverlappingUsers(true)
  }

  const handleOnTimeSelected = ({
    startTime,
    endTime,
  }) => {
    setStartTime(startTime)
    setEndTime(endTime)
    setCheckForOverlappingUsers(true)
  }

  const handleSendFeedback = async (body) => {
    await API.post('CoreEvent', '/core/event', {
      body,
    })
  }

  const getLeaveTypes = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getLeaveTypesForUser, { id })) as IGetLeaveTypesForUserLeaveRequestData
      const connectedCalendarsResponse = await API.graphql(graphqlOperation(getConnectedCalendars, { userId: authUser.id })) as IData<IGetConnectedCalendarsResponse>
      if (connectedCalendarsResponse.data?.getConnectedGooogleCalendar?.calendarId) {
        setConnectedGoogleCalendar(connectedCalendarsResponse.data.getConnectedGooogleCalendar)
      }
      if (connectedCalendarsResponse.data?.getConnectedOutlookCalendar?.calendarId) {
        setConnectedOutlookCalendar(connectedCalendarsResponse.data.getConnectedOutlookCalendar)
      }
      setIsLoadingConnectedCalenadar(false)
      setUser(response.data.getUser)
      setThisYearHolidays(response.data.getUser.location.holidays as ILocationYearHolidays[])

      setIsLoading(false)
    } catch (err) {
      console.log('error fetching leave types list', err)
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLoader(true)
    setCorrelationId(null)
    let response
    try {
      response = await API.post('CoreEvent', '/core/leave-request-validate', {
        body: {
          eventType: 'LEAVE_REQUEST_CREATED',
          eventGroup: 'USER_LEAVE_REQUEST',
          ...data,
          userId,
        },
      })

      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LEAVE_REQUEST_CREATED',
          eventGroup: 'USER_LEAVE_REQUEST',
          ...data,
          userId,
        },
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'requestLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setCorrelationId(response.correlationId)
      setCreateLoader(false)
    } catch (error) {
      setCorrelationId(null)
      console.log('error', error)
      setCreateLoader(false)
      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id='app.requestLeave' />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item><IntlMessages id='app.home' /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id='app.requestLeave' /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        {!isLoading && showSummary ? <LeaveRequestSummary
          userId={userId}
          isLeaveAddition={false}
          onSave={handleSendFeedback}
          leaveRequest={{
            requestedDays: overlappingUsersInfo?.year1Days as number,
            currentDays:currentLeavePolicy?.currentDays as number,
            requestedNextDays: overlappingUsersInfo?.year2Days as number,
            nextDays: nextYearLeavePolicy?.currentDays as number,
            leaveTypeName: selectedLeaveType?.leaveType?.name as string,
            hasUnlimitedDays: Boolean(currentLeavePolicy?.hasUnlimitedDays),
            hasUnlimitedDaysNextYear: Boolean(nextYearLeavePolicy?.hasUnlimitedDays),
            isApprovalRequired: selectedLeaveType?.isApprovalRequired,
            leavePeriod: getLeavePeriod({
              isPartDay: selectedLeaveType?.id.includes('|part-day'),
              startDate,
              endDate,
              partDayStartHour: startTime,
              partDayEndHour: endTime,
              formatMessage,
              locale: user?.locale,
              leaveRequestWorkingDays: overlappingUsersInfo?.calendarDays,
              hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
            }),
          }}
          approvers={user?.team.approvers as IApprover[]}
          resetLeaveRequestForm={resetLeaveRequestForm}
          futureAccruals={{
            allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
            endDate: endDate as unknown as string,
            daysOnLeaveEndDates: overlappingUsersInfo?.daysOnLeaveEndDates,
          }}
          locale={user?.locale as LocaleEnum}
        /> : null}
        {isLoading || isLoadingConnectedCalenadar ? <CircularProgress /> : showSummary
          ? null
          : <LeaveForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            hourFormat={authUser.hourFormat}
            loading={createLoader}
            listOfUsers={[user as IUserLeaveRequestExtended]}
            holidays={thisYearHolidays}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onLeaveTypeSelected={handleOnLeaveTypeSelected}
            onEndDateSelected={handleOnEndDateSelected}
            onStartDateSelected={handleOnStartDateSelected}
            onHandleTimeSelected={handleOnTimeSelected}
            onCancel={() => { history.goBack(); history.goBack() }}
            formType={'request'}
            connectedGoogleCalendar={connectedGoogleCalendar}
            connectedOutlookCalendar={connectedOutlookCalendar}
            shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
            substituteApprovers={suggestedSubstituteApprovers}
            isSubstituteListLoading={isSubstituteListLoading}
            setShowOverlappingInfoAndQuotas={setShowOverlappingInfoAndQuotas}
          />}
      </div>
      {!showSummary && showOverlappingInfoAndQuotas && <LeaveRequestOverlappingInfo
        isLoadingOverlappingUsersInfo={isLoadingOverlappingUsersInfo}
        overlappingUsersInfo={overlappingUsersInfo}
      />}
      {!showSummary && showOverlappingInfoAndQuotas &&
        <LeaveRequestQuota
          currentLeavePolicy={currentLeavePolicy}
          nextYearLeavePolicy={nextYearLeavePolicy}
          currentQuota={currentQuota}
          nextYearQuota={nextYearQuota}
          user={user}
          overlappingUsersInfo={overlappingUsersInfo}
        />
      }
      {!isLoadingOverlappingUsersInfo && showOverlappingInfoAndQuotas && !showSummary && selectedLeaveType?.allowAdvanceAccrualUsage && shouldShowFutureAccruedDaysInfo({
        accrualType: selectedLeaveType?.accrualType as AccrualType,
        allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
        currentYearDays: overlappingUsersInfo?.daysOnLeaveEndDates,
        leaveRequestEndDate: endDate,
        currentDays: currentLeavePolicy?.currentDays,
      }) && <Alert
        message={formatMessage(
          { id: 'email.notification.accruedDaysInfoForUser' },
          { amount: displayLeaveInDaysAndHours({ value: currentLeavePolicy?.currentDays as number, formatMessage }),
            accrued:  displayLeaveInDaysAndHours({ value: overlappingUsersInfo?.daysOnLeaveEndDates as number, formatMessage }),
            earningDate: formatDateToLocale(endDate as unknown as string, 'dddd, MMM D, YYYY', user?.locale),
          })}
        type='info' />}
    </div>
  )
}

export default RequestLeavePage
